/* eslint-disable no-underscore-dangle */

import { PERIODIC_SERVER_POLLING_EVENTS } from '../consts/eventTypes';

class PeriodicServerPolling {
  constructor (periodicity, eventBus, api) {
    if (!eventBus) {
      throw new Error('Invalid eventBus');
    }
    if (!api) {
      throw new Error('Invalid api');
    }

    // eslint-disable-next-line eqeqeq
    if (periodicity == 0) {
      throw new Error('OptimizationBoBoError: invalid value for periodicity');
    } else if (!periodicity) {
      throw new Error('Invalid periodicity value');
    }

    this._api = api;
    this._eventBus = eventBus;
    this.interval = setInterval(this.periodic.bind(this), periodicity);
    // console.debug('PeriodicServerPolling - initialized');
  }

  periodic () {
    this._api.$get('/v1/Session/Exists')
      .then((isAlive) => {
        // console.debug('isAlive', isAlive);
        if (!isAlive) {
          this.sessionIsOutdated();
        }
      })
      .catch((err) => {
        console.error('PeriodicServerPolling - err', err);
        this.sessionIsOutdated();
      });
  }

  sessionIsOutdated () {
    this._eventBus.notify(PERIODIC_SERVER_POLLING_EVENTS.sessionIsOutdated);
  }

  destroy () {
    if (this.interval === undefined) {
      return;
    }
    clearInterval(this.interval);
    this.interval = undefined;
  }
}

export default PeriodicServerPolling;
