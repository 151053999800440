export const PERIODIC_SERVER_POLLING_EVENTS = {
  sessionIsOutdated: 'sessionIsOutdated',
};

export const EVENT_BUS_EVENTS = {
  destroyEventBus: 'destroyEventBus',
};

export const REPORT_EVENTS = {
  reportIsCompleted: 'reportIsCompleted',
};
