import {
  errorHelper,
} from '~/plugins/handlers/utils';

export const state = () => ({
  locators: [],
  isReady: false,
});

export const mutations = {
  setLocators (state, value) {
    state.locators = value;
  },
  setIsReady (state, value) {
    state.isReady = value;
  },
};

export const getters = {
  locators: state => state.locators,
  isReady: state => state.isReady,
  allLocatorsObjectIds: state => [...new Set(state.locators.map(locator => locator.ObjectIds).flat())],
};

export const actions = {
  async getLocators ({ rootState, commit }) {
    commit('setIsReady', false);
    if (rootState.user.token) {
      const resp = await this.$api.$get('/v1/Locators').then((data) => {
        if (data) {
          commit('setLocators', data);
          return true;
        }
        return false;
      }).catch((error) => {
        commit('error', errorHelper(error));
        return false;
      }).finally(() => {
        commit('setIsReady', true);
      });
      commit('setIsReady', true);
      return resp;
    }
    commit('setIsReady', true);
    return false;
  },

  getLocator ({ commit }, id) {
    return this.$api.$get(`v1/Locators/${id}`).catch((e) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${e.name} : ${e.message} \n ${e.stack}`,
        type: 'danger',
      }, { root: true });
    });
  },

  editLocator ({ dispatch, commit }, { locator, id }) {
    commit('setIsReady', false);
    this.$api.put(`/v1/Locators/${id}`, locator).then((data) => {
      if (data) {
        dispatch('getLocators');
      }
    }).catch((e) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${e.name} : ${e.message} \n ${e.stack}`,
        type: 'danger',
      }, { root: true });
      commit('setIsReady', true);
    });
  },

  createLocator ({ dispatch, commit }, locator) {
    commit('setIsReady', false);
    this.$api.post('/v1/Locators', locator).then((data) => {
      if (data) {
        dispatch('getLocators');
      }
    }).catch((e) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${e.name} : ${e.message} \n ${e.stack}`,
        type: 'danger',
      }, { root: true });
      commit('setIsReady', true);
    });
  },

  blockLocator ({ dispatch, commit }, id) {
    commit('setIsReady', false);
    this.$api.post(`/v1/Locators/Block/${id}`).then((data) => {
      dispatch('getLocators');
    }).catch((e) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${e.name} : ${e.message} \n ${e.stack}`,
        type: 'danger',
      }, { root: true });
      commit('setIsReady', true);
    });
  },
  unblockLocator ({ dispatch, commit }, id) {
    commit('setIsReady', false);
    this.$api.post(`/v1/Locators/Unblock/${id}`).then((data) => {
      dispatch('getLocators');
    }).catch((e) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${e.name} : ${e.message} \n ${e.stack}`,
        type: 'danger',
      }, { root: true });
      commit('setIsReady', true);
    });
  },
};
