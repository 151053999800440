export const state = () => ({
  viewGeofence: 0,
  // sort: '',
  // listShowGroupsObjects: [],
  checkedGeofencesOutMonitoring: [],
  scrollPositionMonitoring: 0,
  scrollPositionOutMonitoring: 0,
});

export const mutations = {
  setViewGeofence (state, viewGeofence) {
    state.viewGeofence = viewGeofence;
  },
  // setSort (state, sort) {
  //   state.sort = sort;
  // },
  // setListShowGroupsObjects (state, arr) {
  //   state.listShowGroupsObjects = arr;
  // },
  setCheckedGeofencesOutMonitoring (state, arr) {
    state.checkedGeofencesOutMonitoring = arr;
  },
  setScrollPositionMonitoring (state, index) {
    state.scrollPositionMonitoring = index;
  },
  setScrollPositionOutMonitoring (state, index) {
    state.scrollPositionOutMonitoring = index;
  },
};

export const getters = {
  viewGeofenceStorage: state => state.viewGeofence || 0,
  // sort: state => state.sort || '',
  // listShowGroupsObjects: state => state.listShowGroupsObjects || [],
  checkedGeofencesOutMonitoring: state => state.checkedGeofencesOutMonitoring || [],
  scrollPositionMonitoring: state => state.scrollPositionMonitoring || 0,
  scrollPositionOutMonitoring: state => state.scrollPositionOutMonitoring || 0,
};
