export const state = () => ({
  private: [],
});

export const mutations = {
  setPrivateObjects (state, item) {
    state.private = item;
  },
};

export const getters = {
  private: state => state.private || [],
};

export const actions = {
  async getPrivateObjects ({ commit }) {
    await this.$api.$get('/v1/PrivateObjectsGroups?$expand=Objects($select=Id)&$select=Id,Name&$orderby=Name').then((data) => {
      if (data && data.value) {
        commit('setPrivateObjects', data.value);
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка получения Приватных групп и их объектов`,
        type: 'danger',
      }, { root: true });
    });
    return true;
  },
  updatedPrivateObjectsGroups ({ dispatch, commit }, params) {
    const { id, group } = params;
    this.$api.patch(`/v1/PrivateObjectsGroups(${id})`, group).then((data) => {
      if (data) {
        dispatch('getPrivateObjects');
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка получения изменения Приватной группы объектов`,
        type: 'danger',
      }, { root: true });
    });
  },
  createdPrivateObjectsGroups ({ dispatch, commit }, params) {
    const { group } = params;
    this.$api.post('/v1/PrivateObjectsGroups', group).then((data) => {
      if (data) {
        dispatch('getPrivateObjects');
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка создания Приватной группы объектов`,
        type: 'danger',
      }, { root: true });
    });
  },
  deletedPrivateObjectsGroups ({ dispatch, commit }, params) {
    const { id } = params;
    this.$api.delete(`/v1/PrivateObjectsGroups(${id})`).then((data) => {
      if (data) {
        dispatch('getPrivateObjects');
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка удаления Приватной группы объектов`,
        type: 'danger',
      }, { root: true });
    });
  },

  addObjectsInGroups ({ dispatch, commit }, params) {
    const { id, objectsIds } = params;
    this.$api.post('/v1/PrivateObjectsGroups/AddObjects', { Id: id, ObjectIds: objectsIds }).then((data) => {
      if (data) {
        dispatch('getPrivateObjects');
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка добавления объектов в Приватную группу`,
        type: 'danger',
      }, { root: true });
    });
  },

  deleteObjectsFromGroups ({ dispatch, commit }, params) {
    const { id, all, objectsIds } = params;
    this.$api.post('/v1/PrivateObjectsGroups/RemoveObjects', { Id: id, AllGroups: all, ObjectIds: objectsIds }).then((data) => {
      if (data) {
        dispatch('getPrivateObjects');
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка удаления объектов из Приватной группы`,
        type: 'danger',
      }, { root: true });
    });
  },
};
