<template lang="pug">
  .root(:class="dragged" v-if="$store.state.user.data")
    .header.container-fluid.bg-light
      .row.no-gutter.align-items-center.h-100.py-2
        .col.h-100.d-flex.align-items-center
          .h6.text-success.m-0
            img.img-fluid.logo-top(src="@/assets/img/logo.png", alt="LogExpert")
        .col.h-100.d-flex.align-items-center.justify-content-end
          .mqtt-status(v-b-tooltip.hover :title="mqttStatus ? 'Вы получаете информацию об изменении состояний объекта' : 'Вы не получаете информацию об изменении состояний объекта'" :class="{ 'mqtt-status_disconnect': !mqttStatus }")
          a.mr-2(href="https://logexpert.ru/wiki/", rel="nofollow noopener noreferrer", target="_blank")
            b-icon.h3(icon="question-circle-fill", variant="secondary", :style="{'margin-bottom': '0px', 'vertical-align': 'bottom'}")
    .main
      nuxt(keep-alive)
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'Web1c',
  data () {
    return {
      mqttStatus: false,
    };
  },
  computed: {
    ...mapState(['isDragged']),
    ...mapGetters('notify', { lastNotify: 'lastNotify', countNotify: 'countNotify' }),
    dragged () {
      return this.isDragged ? {
        dragged: true,
        [this.isDragged]: true,
      } : null;
    },
  },
  watch: {
    lastNotify: {
      handler (value) {
        if (value) {
          this.toast(value.massage, value.type);

          setTimeout(() => {
            const headersForToasts = document.querySelectorAll('.toast-header');
            const notifyLength = headersForToasts.length;

            if (this.countNotify > 10 && notifyLength > 10) {
              headersForToasts.forEach((item, index) => {
                if (index < notifyLength - 10) {
                  const currentButton = item.querySelector('button');
                  currentButton.dispatchEvent(new Event('click'));
                }
              });
            }
          }, 300);
        }
      },
      deep: true,
    },
  },
  created () {
    this.$nuxt.$on('client-connected-change', event => this.clientConnectedChange(event));
  },
  methods: {
    ...mapActions('preferences', ['setPreference']),
    clientConnectedChange (status) {
      this.mqttStatus = status;
    },
    toast (massage, type) {
      let title = this.$tc('menu.settings.notyfy', 0);
      switch (type) {
        case 'success':
          title = this.$tc('menu.settings.notyfy', 1);
          break;
        case 'danger':
          title = this.$tc('menu.settings.notyfy', 2);
          break;
        case 'warning':
          title = this.$tc('menu.settings.notyfy2', 0);
          break;
        default:
          break;
      }
      const h = this.$createElement;
      const vNodesTitle = h(
        'div',
        { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'], ref: 'titleForToast' },
        [
          h('strong', { class: 'mr-2' }, title),
          h('small', { class: 'ml-auto text-italics' }, new Date().toLocaleString('ru-RU')),
        ],
      );

      const noAutoHide = type !== 'success';

      this.$bvToast.toast(massage, {
        title: [vNodesTitle],
        variant: type,
        toaster: 'b-toaster-bottom-right',
        solid: true,
        appendToast: true,
        noAutoHide,
      });
    },
  },
};
</script>

<style lang="stylus">
body {
  overflow: hidden;
}
.root {
  &.dragged {
    //pointer-events: none !important;
    user-select: none !important;
    .grid-container .top {
      pointer-events: auto !important;
    }
    * {
      pointer-events: none !important;
      user-select: none !important;
    }
  }
  &.left {
    cursor: ew-resize !important;
  }
  &.right {
    cursor: ns-resize !important;
  }
}
</style>
<style lang="stylus" scoped>
.root {
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  display: flex;
}

.header {
  min-height: 54px;
  user-select: none;
}

.logo-top {
  pointer-events: none;
  user-select: none;
}

.main {
  flex: 1;
  position: relative;
}

.mqtt-status {
  width: 10px;
  height: 10px;
  margin-right: 15px;
  border-left: 2px solid black;
  border-right: 2px solid black;
  border-radius: 50%;
  background-color: #28a745;
  transition: 0.3s;
}
.mqtt-status_disconnect {
  background-color: #dc3545;
}
</style>
