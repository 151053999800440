export const state = () => ({
  notify: [],
  idForNotify: 0,
});

export const mutations = {
  clearNotify (state) {
    state.notify = [];
  },
  addNotify (state, _item) {
    const id = state.idForNotify;
    state.idForNotify += 1;
    const {
      alertId,
      massage,
      type,
    } = _item;
    const item = {
      id,
      alertId,
      massage,
      type,
    };
    state.notify.push(item);
  },
  readedNotify (state, id) {
    state.notify = state.notify.filter(el => el.id !== id);
  },
};

export const getters = {
  lastNotify: state => state.notify.filter(el => el).pop(),
  countNotify: state => state.notify.length,
};

export const actions = {
  readedLastNotify ({ commit }, id) {
    commit('readedNotify', id);
  },
  addNotify ({ commit }, item) {
    commit('addNotify', item);
  },
};
