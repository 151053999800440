import Vue from 'vue';
import * as qs from 'qs';
import moment from 'moment';
import { PERIODIC_SERVER_POLLING_EVENTS } from '../consts/eventTypes';
import BreakablePeriodic from '../services/BreakablePeriodic';

export const state = () => ({
  items: {},
  i: 0,
  v: 0,
  points: [],
  clientTracks: {},
  clientTrackPeriod: {},
  clientTrackPeriods: {},
  selectedPeriods: null,
  columnsGrid: '0,1,3,5,8',
  activePoint: null,
  reportTrack: false,
  activeTrackReport: null,
  hoverParking: null,
  requireTimeout: false,
  activeTimeout: null,
  deletedItems: {},
  activeTrackId: null,
});

export const mutations = {
  setSelectedPeriods (state, periods = null) {
    state.selectedPeriods = periods;
  },
  clearItems (state) {
    state.items = {};
  },
  inc (state) {
    state.i += 1;
  },
  addItem (state, item) {
    Vue.set(state.items, item.uid, item);
  },
  deleteItem (state, uid) {
    Vue.delete(state.items, uid);
  },
  updateItem (state, { uid, data }) {
    const item = state.items[uid];
    Object.assign(item, data);
  },
  deleteSensorsReceived (state, uid) {
    // eslint-disable-next-line
    if (state.items[uid].hasOwnProperty('sensorsReceived')) {
      delete state.items[uid].sensorsReceived;
    }
  },
  incVersion (state) {
    state.v += 1;
  },
  addPoints (state, item) {
    state.points = item;
  },
  updateClientTracks (state, { id, data }) {
    Vue.set(state.clientTracks, id, data);
  },
  deleteAllClientTracks (state) {
    state.clientTracks = {};
  },
  deleteClientTrack (state, id) {
    Vue.delete(state.clientTracks, id);
  },
  setClientTrackPeriod (state, clientTrackPeriod) {
    state.clientTrackPeriod = clientTrackPeriod;
  },
  setClientTrackPeriods (state, clientTrackPeriods) {
    state.clientTrackPeriods = clientTrackPeriods;
  },
  dataColumnsGrid (state, item) {
    state.columnsGrid = item;
  },
  setActivePoint (state, coords = null) {
    state.activePoint = coords;
  },
  setReportVisible (state, value) {
    state.reportTrack = value;
  },
  setIsActiveTrackReport (state, value = null) {
    state.activeTrackReport = value;
  },
  // Для декримента - передаем отрицательный count
  updateTrackPointsCount (state, { id, count = 0 }) {
    const found = findTrack(state.items, id);
    if (!found) {
      throw new Error(`Track with this id: ${id}, not found`);
    }
    found.pointsCount += count;
  },
  setHoverParking (state, value) {
    state.hoverParking = value;
  },
  setRequireTimeout (state, value) {
    state.requireTimeout = value;
  },
  addDeletedItem (state, item) {
    Vue.set(state.deletedItems, item.uid, item);
  },
  removeDeletedItem (state, uid) {
    Vue.delete(state.deletedItems, uid);
  },
  setActiveTrackId (state, id) {
    state.activeTrackId = id;
  },
};

export const getters = {
  arrItems: state => Object.values(state.items) || [],
  // eslint-disable-next-line no-shadow
  activeTrack: (state, getters) => getters.arrItems.find(el => el.id === state.activeTrackId),
  trackPoints: state => state.points || [],
  columnsTrackPoints: state => state.columnsGrid,
  countTracks: state => (Object.values(state.items) || []).length,
  visibleTracks: state => Object.values(state.items).length > 0 || state.reportTrack,
  hoverParking: state => state.hoverParking,
  selectedPeriods: state => state.selectedPeriods,
  visibleClientTracks: state => Object.fromEntries(Object.entries(state.clientTracks).filter(track => track[1].visible && track[0] !== state.clientTrackPeriod.period?.clientTrackId && track[0] !== state.clientTrackPeriods.id)),
  visibleClientTrackPeriod: state => Object.fromEntries(Object.entries(state.clientTrackPeriod).filter(period => period[1].visible)),
  visibleClientTrackPeriods: state => (state.clientTrackPeriods.visible ? state.clientTrackPeriods.periods : []),
};

export const actions = {
  init ({ dispatch }) {
    this.$eventBus.subscribe(PERIODIC_SERVER_POLLING_EVENTS.sessionIsOutdated, 'trackStore', () => {
      dispatch('moveTracksToOutdate');
    });
  },

  async getUid ({ state, commit }) {
    const uid = state.i;
    await commit('inc');
    return uid;
  },

  async getSensors ({ state, commit }, track) {
    const sensorsReceived = true;
    if (track?.objectId) {
      await this.$api.$get(`/v1/Objects(${track.objectId})/Sensors?$select=Id,Name,Color,ShowInChart`).then((sensors) => {
        const chartItems = track.nameChartItems.map(x => ({
          name: x,
          color: sensors.value.find(s => s.Name === x)?.Color,
          isShow: sensors.value.find(s => s.Name === x)?.ShowInChart,
        }));
        const sensor = chartItems.find(x => x.name === 'Скорость');
        if (sensor) {
          sensor.isShow = true;
        }
        commit('updateItem', {
          uid: track.uid,
          data: {
            chartItems,
            sensorsReceived,
          },
        });
      });
    }
  },

  async getParking ({ state, commit }, params) {
    const param = qs.stringify(params);
    await this.$api.$get(`/v1/Tracks/Parking/?${param}`).then((data) => {
      commit('setHoverParking', data);
    }).catch((e) => {
      commit('notify/addNotify', {
            massage: `Ошибка ${e.name} : ${e.message} \n ${e.stack}`,
            type: 'danger',
          },
          { root: true });
    });
  },
  setIncVersion ({ state, commit }) {
    clearTimeout(state.activeTimeout);
    if (state.requireTimeout) {
      state.activeTimeout = setTimeout(() => { commit('incVersion'); }, 3000);
    } else {
      commit('incVersion');
    }
  },

  async getTrack (all, { uidTrack = null, params = null, report = false, after = null }) {
    const { state, dispatch, commit } = all;

    let createParams;
    let uid;

    const requireNewSession = this.getters['user/requireNewSession'];
    if (requireNewSession && !(await this.dispatch('user/fetchData'))) {
      return false;
    }

    if (uidTrack !== null) {
      const track = state.items[uidTrack];
      createParams = {
        Id: track.id,
        ObjectId: track.objectId,
        Begin: track.from,
        End: track.to,
        UseRideDetector: track.useRideDetector,
        FillGaps: track.rebuildOnFailures,
        Color: track.color,
        ShowStopsInTracks: this.getters['preferences/settingsAUnit'].showStopsInTracks,
        ShowParkingsInTracks: this.getters['preferences/settingsAUnit'].showParkingsInTracks,
      };
      uid = uidTrack;
      commit('updateItem', {
        uid: uidTrack,
        data: {
          visible: true,
          status: 'loading',
        },
      });
    } else {
      createParams = {
        ObjectId: params.objectId,
        Begin: params.from,
        End: params.to,
        UseRideDetector: params.useRideDetector,
        FillGaps: params.rebuildOnFailures,
        Color: params.color,
        ShowStopsInTracks: this.getters['preferences/settingsAUnit'].showStopsInTracks,
        ShowParkingsInTracks: this.getters['preferences/settingsAUnit'].showParkingsInTracks,
      };
      if (!report) {
        uid = await dispatch('getUid');
        await commit('addItem', {
          uid,
          id: undefined,
          visible: true,
          status: 'loading',
          date: new Date(),
          mileage: 0,
          pointsCount: 0,
          chartItems: [],
          ...params,
        });
      } else {
        createParams.Id = params.id;
      }
    }

    let dataTrack;

    await this.$api.$post('/v1/Tracks/', createParams).then(async (data) => {
      if (!data.Success) {
        throw new Error(data.Error);
      }
      if (!report) {
          const nameChartItems = data.ChartItems;
          commit('updateItem', {
            uid,
            data: {
              id: data.Id,
              status: 'complete',
              pointsCount: data.PointsCount,
              rides: data.Rides,
              bounds: data.Bounds,
              mileage: data.Mileage,
              anomalies: data.Anomalies,
              nameChartItems,
              parkings: data.Parkings,
              stops: data.Stops,
            },
          });

        if (this.getters['preferences/settingsAUnit'].renderingMode === 0) {
          dispatch('setIncVersion');
        } else {
          await dispatch('getTrackPoints', { uid, report: null });
          await dispatch('clusteredPoints', {
            id: data.Id,
            width: Math.round(this.state.map.size.width),
            height: Math.round(this.state.map.size.height),
            // eslint-disable-next-line no-underscore-dangle
            bbox: [this.state.map.bounds._southWest.lng, this.state.map.bounds._southWest.lat, this.state.map.bounds._northEast.lng, this.state.map.bounds._northEast.lat],
          });
        }

          dataTrack = data;
          if (after) {
            after([data.Id, uid]);
          }
      } else {
        if (this.getters['preferences/settingsAUnit'].renderingMode === 0) {
          dispatch('setIncVersion');
        } else {
          await dispatch('getTrackPoints', { uid: null, report: { ...data, color: params.color } });
          await dispatch('clusteredPoints', {
            id: data.Id,
            width: Math.round(this.state.map.size.width),
            height: Math.round(this.state.map.size.height),
            // eslint-disable-next-line no-underscore-dangle
            bbox: [this.state.map.bounds._southWest.lng, this.state.map.bounds._southWest.lat, this.state.map.bounds._northEast.lng, this.state.map.bounds._northEast.lat],
          });
        }

        // if (data.Bounds.length > 3) {
        //   global.mainMap.fitBounds([
        //     [data.Bounds[1], data.Bounds[0]],
        //     [data.Bounds[3], data.Bounds[2]],
        //   ]);
        // }
        dataTrack = data;
        commit('setReportVisible', true);

        if (after) {
          after([data.Id, uid]);
        }
      }
    }).catch((e) => {
      if (!report) {
        commit('updateItem', {
          uid,
          data: {
            status: 'error',
          },
        });
      }
      commit('notify/addNotify', {
        massage: `Ошибка ${e.name} : ${e.message} \n ${e.stack}`,
        type: 'danger',
      },
      {
        root: true,
      });
    });
    return dataTrack;
  },

  moveTracksToOutdate ({ state, commit }) {
    const tracks = Object.values(state.items);
    tracks.forEach((track) => {
      commit('updateItem', { uid: track.uid, data: { visible: false, status: 'outdated' } });
    });
  },

  deleteAllTracks ({ state, commit }) {
    const arrItems = Object.values(state.items);
    arrItems.forEach((item) => {
      // eslint-disable-next-line
      const uid = item.uid;
      commit('updateItem', {
        uid,
        data: {
          status: 'deleting',
        },
      });
    });
    if (this.getters['preferences/settingsAUnit'].renderingMode === 0) {
      this.$api.delete('/v1/Tracks/All').then(() => {
        arrItems.forEach((item) => {
          // eslint-disable-next-line
          const uid = item.uid;
          commit('deleteItem', uid);
        });
          commit('incVersion');
      }).catch((e) => {
        arrItems.forEach((item) => {
          // eslint-disable-next-line
          const uid = item.uid;
          commit('updateItem', {
            uid,
            data: {
              status: 'complete',
            },
          });
        });
        commit('notify/addNotify', {
            massage: `Ошибка ${e.name} : ${e.message} \n ${e.stack}`,
            type: 'danger',
          },
          {
            root: true,
          });
      });
    } else {
      arrItems.forEach((item) => {
        // eslint-disable-next-line
        const uid = item.uid;
        commit('deleteItem', uid);
      });
      commit('deleteAllClientTracks');
    }
  },

  deleteTrack ({ state, commit }, uid) {
    const { id } = state.items[uid];
    if (id) {
      commit('updateItem', {
        uid,
        data: {
          status: 'deleting',
        },
      });
      commit('addDeletedItem', state.items[uid]);
      commit('deleteItem', uid);

      if (this.getters['preferences/settingsAUnit'].renderingMode === 0) {
        this.$api.delete(`/v1/Tracks/?id=${id}`).then(() => {
          commit('removeDeletedItem', uid);
          commit('incVersion');
        }).catch((e) => {
          commit('addItem', state.deletedItems[uid]);
          commit('removeDeletedItem', uid);
          commit('updateItem', {
            uid,
            data: {
              status: 'complete',
            },
          });
          commit('notify/addNotify', {
                massage: `Ошибка ${e.name} : ${e.message} \n ${e.stack}`,
                type: 'danger',
              },
              {
                root: true,
              });
        });
      } else {
        commit('removeDeletedItem', uid);
        commit('deleteClientTrack', id);
      }
    } else {
      commit('deleteItem', uid);
    }
  },

  async switchStateVisibilityAllTracks ({ state, commit }, visible) {
    if (this.getters['preferences/settingsAUnit'].renderingMode === 0) {
      const params = String(visible);
      this.$api.setHeader('content-type', 'application/json');
      await this.$api.$post('/v1/Tracks/SetVisibilityAll', params).then((data) => {
        Object.values(state.items).forEach((item) => {
          commit('updateItem', {
            uid: item.uid,
            data: {
              visible,
              color: item.color,
            },
          });
        });
        commit('incVersion');
      }).catch((e) => {
        commit('notify/addNotify', {
              massage: `Ошибка ${e.name} : ${e.message} \n ${e.stack}`,
              type: 'danger',
            },
            {
              root: true,
            });
      });
    } else {
      Object.values(state.items).forEach((item) => {
        commit('updateItem', {
          uid: item.uid,
          data: {
            visible,
            color: item.color,
          },
        });
      });
      Object.entries(state.clientTracks).forEach((item) => {
        commit('updateClientTracks', {
          id: item[0],
          data: {
            ...item[1],
            visible,
            color: item[1].color,
          },
        });
      });
    }
  },

  async updateParts ({ state, commit, dispatch }, newEvent) {
    commit('setSelectedPeriods', newEvent.periods);
    if (this.getters['preferences/settingsAUnit'].renderingMode === 0) {
      await this.$api.$post('/v1/Tracks/UpdateParts', newEvent).then((data) => {
        // if (newEvent.id === null) {
        //   commit('updateItem', {
        //     uid,
        //     data: {
        //       visible: newEvent.visible,
        //       color: newEvent.color,
        //     },
        //   });
        // }
        commit('incVersion');
        if (newEvent.id !== null) {
          commit('setReportVisible', newEvent.visible);
        }
        // return data.PeriodBounds;
      }).catch((e) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${e.name} : ${e.message} \n ${e.stack}`,
          type: 'danger',
        }, { root: true });
      });
    } else {
      const newEventCoords = { ...newEvent };
      newEventCoords.periods = newEvent.periods.map((period) => {
        const from = moment(period.begin).unix();
        const to = moment(period.end).unix();
        const latlngs = state.clientTracks[newEvent.id].data
            .filter(_item => _item.Timestamp >= from && _item.Timestamp <= to)
            .map(_item => [_item.Latitude, _item.Longitude]);
       return { ...period, latlngs, id: crypto.randomUUID() };
      });
      commit('setClientTrackPeriods', { ...newEventCoords, parkings: state.clientTracks[newEvent.id].parkings, stops: state.clientTracks[newEvent.id].stops });
    }
  },

  async updateTrack ({ state, commit }, { uid, color = null, visible = true, periods = null, secondColor = null, reportId = null }) {
    let item;
    let periodBounds = null;
    if (reportId === null) {
      item = state.items[uid];
    } else {
      item = {
        id: reportId,
      };
    }
    const params = { Id: item.id, Visible: visible };
    if (color !== null) {
      params.Color = color;
    }
    if (periods !== null) {
      params.Periods = periods;
    }
    if (secondColor !== null) {
      params.SecondColor = secondColor;
    }
    if (item.id) {
      periodBounds = await this.$api.$post('/v1/Tracks/Update', params).then((data) => {
        if (reportId === null) {
          commit('updateItem', {
            uid,
            data: {
              visible,
              color,
            },
          });
        }
        if (this.getters['preferences/settingsAUnit'].renderingMode === 0) {
          commit('incVersion');
        } else {
          if (periods !== null) {
            const generalProperties = () => ({
              id: crypto.randomUUID(),
              clientTrack: state.clientTracks[item.id],
              clientTrackId: item.id,
              visible,
              latlngs: [],
              parkings: state.clientTracks[item.id].parkings,
              stops: state.clientTracks[item.id].stops,
            });
            const clientTrackPeriod = {
              beforePeriod: { ...generalProperties(), color: color || state.clientTracks[item.id].color },
              afterPeriod: { ...generalProperties(), color: color || state.clientTracks[item.id].color },
              period: { ...generalProperties(), color: secondColor },
            };
            const from = moment(periods[0][0]).unix();
            const to = moment(periods[0][1]).unix();
            clientTrackPeriod.period.latlngs = state.clientTracks[item.id].data
                .filter((_item) => {
                  if (_item.Timestamp >= from && _item.Timestamp <= to) {
                    return true;
                  }
                  if (_item.Timestamp < from) {
                    clientTrackPeriod.beforePeriod.latlngs.push([_item.Latitude, _item.Longitude]);
                    return false;
                  }
                  if (_item.Timestamp > to) {
                    clientTrackPeriod.afterPeriod.latlngs.push([_item.Latitude, _item.Longitude]);
                    return false;
                  }
                  return false;
                })
                .map(_item => [_item.Latitude, _item.Longitude]);
            commit('setClientTrackPeriod', clientTrackPeriod);
          }
          commit('updateClientTracks', {
            id: item.id,
            data: {
              ...state.clientTracks[item.id],
              visible,
              color: color || state.clientTracks[item.id].color,
            },
          });
        }
        if (reportId !== null) {
          commit('setReportVisible', visible);
        }
        return data.PeriodBounds;
      }).catch((e) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${e.name} : ${e.message} \n ${e.stack}`,
          type: 'danger',
        },
        {
          root: true,
        });
        return null;
      });
    } else {
      commit('notify/addNotify', {
        massage: 'Ошибка получения трека, трек не был построен.',
        type: 'danger',
      }, { root: true });
    }
    return periodBounds;
  },

  getTrackPoints ({ state, commit }, { uid, report }) {
    let id;
    let pointsCount;
    let color;
    let parkings;
    let stops;
    if (!report) {
      id = state.items[uid].id;
      pointsCount = state.items[uid].pointsCount;
      color = state.items[uid].color;
      parkings = state.items[uid].parkings;
      stops = state.items[uid].stops;
    } else {
      id = report.Id;
      pointsCount = report.PointsCount;
      color = report.color;
      parkings = report.Parkings;
      stops = report.Stops;
    }
    if (id) {
      commit('addPoints', []);

      return this.$api.$get(`/v1/Tracks/Points/?id=${id}&size=${pointsCount}&page=0`).then((_data) => {
        const clientTrack = {
          latlngs: [],
          color,
          visible: true,
          parkings,
          stops,
        };
        const data = _data.map((_item, i) => {
          const item = _item;
          if (_item.Latitude && _item.Longitude) {
            clientTrack.latlngs.push([_item.Latitude, _item.Longitude]);
          }
          item.id = i;
          return item;
        });
        clientTrack.data = data;
        commit('updateClientTracks', { id, data: clientTrack });
        if (!report) {
          commit('updateItem', {
            uid,
            data: {
              id,
              status: 'complete',
            },
          });
        }
        commit('addPoints', data);
      }).catch((e) => {
        if (!report) {
          commit('updateItem', {
            uid,
            data: {
              status: 'error',
            },
          });
        }
        commit('notify/addNotify', {
          massage: `Ошибка ${e.name} : ${e.message} \n ${e.stack}`,
          type: 'danger',
        },
        { root: true });
      });
    }
      commit('notify/addNotify', {
        massage: 'Ошибка получения трека, трек не был построен.',
        type: 'danger',
      }, { root: true });
      return false;
  },

  getTrackPointsPage ({ commit }, { id, page = 0, take = 0 }) {
    if (id) {
      return this.$api.$get(`/v1/Tracks/Points/?id=${id}&size=${take}&page=${page}`).then(data => data.map((item, i) => ({
        ...item,
        id: i,
      }))).catch((e) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${e.name} : ${e.message} \n ${e.stack}`,
          type: 'danger',
        },
        { root: true });
      });
    }

    commit('notify/addNotify', {
      massage: 'Ошибка получения трека, трек не был построен.',
      type: 'danger',
    }, { root: true });
    return [];
  },

  removeRange ({ state, commit, dispatch }, { objectId, begin, end, useInsertTime, trackUid }) {
    if (Number.isNaN(objectId)) {
      console.error('Remove err: Invalid id!');
      return;
    }

    this.$api.$delete(`/v1/TrackPoints/?objectId=${objectId}&begin=${begin}&end=${end}&useInsertTime=${useInsertTime}`, {
      // eslint-disable-next-line no-underscore-dangle
      headers: { ...this.$api._defaults.headers, 'content-type': 'application/json' },
    })
      .then(() => {
        dispatch('getTrack', { uidTrack: trackUid });
      })
      .catch((e) => {
        commit('notify/addNotify', {
              massage: `Ошибка ${e.name} : ${e.message} \n ${e.stack}`,
              type: 'danger',
            },
            { root: true });
      });
  },
  removeTrackPoints ({ state, commit, dispatch }, { id, uid, indexes = [] }) {
    if (Number.isNaN(id)) {
      console.error('Remove err: Invalid id!');
      return;
    }
    if (!indexes.length) {
      console.error('Remove err: empty points to delete!');
      return;
    }

    this.$api.$delete(`/v1/Tracks/${id}/Points`, {
      // eslint-disable-next-line no-underscore-dangle
      headers: { ...this.$api._defaults.headers, 'content-type': 'application/json' },
      body: JSON.stringify(indexes),
    })
      .then((data) => {
        // console.debug('action_removeTrackPoints - delete data', data);
        const { Success, Rebuild } = data;
        if (Success || !Rebuild) {
          return;
        }

        const found = findTrack(state.items, id);
        const foundIndex = Object.values(state.items).indexOf(found);
        if (foundIndex === -1) {
          throw new Error(`Track with this id: ${id}, not found`);
        }
        dispatch('updateTrack', { uid: foundIndex });
      })
      .then(() => {
        dispatch('getTrack', { uidTrack: uid });
      })
      .catch((e) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${e.name} : ${e.message} \n ${e.stack}`,
          type: 'danger',
        },
        { root: true });
      });
  },

  importTrackPoints ({ commit }, { requestData, importMethod }) {
    const checkImportStatus = taskId => (doBreak) => {
      this.$api.$get(`v1/Tracks/ImportStatus/${taskId}`)
        .then((status) => {
          if (status.Complete) {
            doBreak({ success: true, msg: 'Импорт прошел успешно' });
          }
        })
        .catch(() => {
          doBreak({ success: false });
        });
    };
    const handleBreak = ({ success = false, msg = 'Ошибка импорта' }) => {
      commit('notify/addNotify', {
        massage: msg,
        type: success ? 'success' : 'warning',
      }, { root: true });
    };
    const notifyStartImport = () => {
      commit('notify/addNotify', {
        massage: 'Импорт выполняется',
        type: 'success',
      }, { root: true });
    };

    return this.$api.$post(`/v1/Tracks/import${importMethod}`, {
      ObjectId: requestData.id,
      Data: requestData.messages,
    }).then((data) => {
      const FIVE_MIN_IN_MILLISECONDS = 300000;
      const { taskId } = data;
      // eslint-disable-next-line no-new
      const periodic = new BreakablePeriodic(
        [{ doIt: checkImportStatus(taskId) }],
        {
          periodicity: 3000,
          timeLimit: FIVE_MIN_IN_MILLISECONDS,
        },
        handleBreak,
      );
      periodic.start();

      notifyStartImport();
    }).catch((err) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${err.name}: Ошибка загрузки ${importMethod} документа на сервер`,
        type: 'danger',
      }, { root: true });
    });
  },

  getColumnsGrid ({ rootState, commit }) {
    const currentSelected = rootState.preferences.tracksSelectedColumns;
    commit('dataColumnsGrid', currentSelected);
  },

  setColumnsGrid ({ rootState, commit, dispatch }, selected) {
    const currentSelected = rootState.preferences.tracksSelectedColumns;
    if (selected !== currentSelected) {
      dispatch('preferences/setPreference', {
        tracksSelectedColumns: selected,
      }, { root: true });
      commit('dataColumnsGrid', selected);
    }
  },

  infoPointClick ({ commit }, params) {
    const param = qs.stringify(params);
    return this.$api.$get(`/v1/Tracks/Info/?${param}`).then((data) => {
      if (data) {
        return data;
      }
      return null;
    }).catch((e) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${e.name} : ${e.message} \n ${e.stack}`,
        type: 'danger',
      },
      {
        root: true,
      });
      return null;
    });
  },

  clusteredPoints ({ state, commit }, params) {
    const param = qs.stringify(params);
    return this.$api.$get(`/v1/Tracks/ClusteredPoints/?${param}`).then((data) => {
      if (data) {
        commit('updateClientTracks', {
          id: params.id,
          data: {
            ...state.clientTracks[params.id],
            clusteredPoints: data,
          },
        });
        return data;
      }
      return null;
    }).catch((e) => {
      if (e?.statusCode !== 404) {
        commit('notify/addNotify', {
          massage: `Ошибка ${e.name} : ${e.message} \n ${e.stack}`,
          type: 'danger',
        }, { root: true });
      }
      return null;
    });
  },
};

function findTrack (tracks, trackId) {
  return Object.values(tracks).find(track => !track.id.localeCompare(trackId));
}
