/* eslint-disable import/prefer-default-export */

export function setToStorage (key, value, session = false) { // сохраняем в хранилище
  if (key) { (session ? sessionStorage : localStorage).setItem(key, value); }
}
export function removeItem (key, session = false) { // удаляем из хранилища
  if (key) { (session ? sessionStorage : localStorage).removeItem(key); }
}
function getFrom (key, session = false) { // забрать из определенного хранилища
  return (session ? sessionStorage : localStorage).getItem(key);
}
export function getFromStorage (key) { // забрать из хранилища (из сессионного или локального)
  return getFrom(key, true) || getFrom(key);
}

export async function errorHelper (error) { // хелпер для форматирования вывода для ошибок $http
  console.error(error);
  if (error?.response) {
    const res = await error.response?.json?.();
    return {
      error: true,
      error_description: res?.detail || 'Неизвестная ошибка',
    };
  }
  return {
    error: true,
    error_description: error?.message || 'Неизвестная ошибка',
  };
}

export const deepMerge = (target, source) => { // мерджит объект с вложенностью
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  Object.keys(source).forEach((key) => {
    if (source[key] instanceof Object) {
      Object.assign(source[key], deepMerge(target[key], source[key]));
    }
  });

  // Join `target` and modified `source`
  Object.assign(target || {}, source);
  return target;
};

export const splitArray = (items = [], count = null) => { // разбивает массив на массив массивов резмерностью не больше 'count'
  if (count) {
    return items.reduce((_previousValue, currentValue, index) => {
      const subIndex = Math.trunc(index / count);
      const previousValue = _previousValue;
      if (previousValue[subIndex]) {
        previousValue[subIndex].push(currentValue);
      } else {
        previousValue.push([currentValue]);
      }
      return previousValue;
    }, []);
  }

  return [items];
};

export class JobQueue { // последовательные списки выполнения. Инициализируется весь список единожды (не распологает к переиспользованию экземпляра)
  constructor () {
    this.jobs = [];
  }

  addJob (callback) {
    this.jobs.push(callback);
  }

  done = () => {
    if (this.jobs.length > 0) {
      this.jobs.shift()(this.done);
    }
  }

  start () {
    this.done();
  }
}

export function liveQueue (concurrency = 1) { // то же список, но "живой". Выполняется, пока есть задачи, иначе ждет добавления новых задач
  let running = 0;
  const taskQueue = [];

  const runTask = (task) => {
    running += 1;
    task((_) => {
      running -= 1;
      if (taskQueue.length > 0) {
        runTask(taskQueue.shift().task);
      }
    });
  };

  const enqueueTask = (task, id) => {
    if (id) {
      const i = taskQueue.findIndex(obj => obj.id === id);
      if (i !== -1) {
        taskQueue[i].task = task;
        return;
      }
    }
    taskQueue.push({ id, task });
  };

  return {
    push: (task, id) => (running < concurrency ? runTask(task) : enqueueTask(task, id)),
  };
}

export function asyncQueue (stacksize = 200) {
  const running = [];
  const queue = [];

  function resolveTask (task, resolve) {
    task().then((result) => {
      resolve(result);
      running.splice(running.findIndex(t => t === task), 1);
      if (queue.length) {
        const t = queue.shift();
        running.push(t.task);
        resolveTask(t.task, t.resolve);
      }
    });
  }

  function enqueueTask (task) {
    return new Promise((resolve) => {
      if (running.length < stacksize) {
        running.push(task);
        resolveTask(task, resolve);
      } else {
        queue.push({ task, resolve });
      }
    });
  }

  return {
    queue: task => enqueueTask(task),
  };
}
