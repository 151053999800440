<template>
  <div style="display: none;">
    <slot v-if="ready" />
  </div>
</template>

<script>
import L from 'leaflet';

import { findRealParent, propsBinder } from 'vue2-leaflet';

const props = {
  options: {
    type: Object,
    default () { return {}; },
  },
  apikey: {
    type: String,
    default () { return ''; },
  },
  lang: {
    type: String,
    default: null,
  },
  name: {
    type: String,
    default: '',
  },
  visible: {
    type: Boolean,
    default: true,
  },
};

export default {
  props,
  data () {
    return {
      ready: false,
    };
  },
  watch: {
    ready (n) {
      if (n) {
        this.$emit('ready', n);
      }
    },
  },
  mounted () {
    if ((typeof ymaps !== 'object') && (typeof L.yandex !== 'function') && !(document.head.querySelector('[yandex-map]')) && !(document.head.querySelector('[yandex-leaflet]'))) {
      const yandexapisscript = document.createElement('script');
      yandexapisscript.setAttribute('src', `https://api-maps.yandex.ru/2.1/?lang=${this.lang}&apikey=${this.apikey}`);
      yandexapisscript.setAttribute('yandex-map', 'ready');
      document.head.appendChild(yandexapisscript);

      const lyandexscript = document.createElement('script');
      lyandexscript.setAttribute('src', '/js/LYandex.js');
      lyandexscript.setAttribute('yandex-leaflet', 'ready');
      document.head.appendChild(lyandexscript);
    }

    let tryConnect = 0;
    (function complete (ctx) {
      if ((typeof ymaps === 'object') && (typeof L.yandex === 'function')) {
        ctx.mapObject = L.yandex(ctx.options.type);
        L.DomEvent.on(ctx.mapObject, ctx.$listeners);
        propsBinder(ctx, ctx.mapObject, props);
        ctx.ready = true;
        ctx.parentContainer = findRealParent(ctx.$parent);
        ctx.parentContainer.addLayer(ctx, !ctx.visible);
      } else if (tryConnect < 100) {
        tryConnect += 1;
        setTimeout(() => {
          complete(ctx);
        }, 100);
      }
    })(this);
  },
  beforeDestroy () {
    if (this.parentContainer) { this.parentContainer.removeLayer(this); }
  },
  methods: {
    addLayer (layer, alreadyAdded) {
      if (!alreadyAdded) {
        this.mapObject.addLayer(layer.mapObject);
      }
    },
    removeLayer (layer, alreadyRemoved) {
      if (!alreadyRemoved) {
        this.mapObject.removeLayer(layer.mapObject);
      }
    },
  },
};
</script>
