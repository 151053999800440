export const state = () => ({
  isDragged: null,
  tabHidden: false,
});

export const mutations = {
  isDragged (state, val = null) {
    state.isDragged = val;
  },
  tabHidden (state, val) {
    state.tabHidden = val;
  },
};

export const actions = {
  init ({ commit }) {
    document.addEventListener('visibilitychange', () => {
      commit('tabHidden', document.hidden);
    });
  },
  isDragged ({ commit }, val = null) {
    commit('isDragged', val);
  },
};
export const strict = false; // Дебажить без этого просто нереально. На проде он и так false
