<template lang="pug">
  el-date-picker(
    v-model="dateTime",
    :picker-options="pickerOptions",
    type="datetime",
    default-time="00:00:00",
    format="dd.MM.yyyy HH:mm",
    :placeholder="placeholder",
    ref="dtime",
    :popper-class="pickerId"
    @focus="attachWheelHandlers()"
    @input="$emit('input', dateTime)"
  )
</template>

<script>
const dateTimeChangers = [
  {
    text: '00.',
    newDate: (date, delta) => new Date(date.getFullYear(), date.getMonth(), date.getDate() + delta, date.getHours(), date.getMinutes()),
  }, {
    text: '00.00.',
    newDate: (date, delta) => new Date(date.getFullYear(), date.getMonth() + delta, date.getDate(), date.getHours(), date.getMinutes()),
  }, {
    text: '00.00.0000',
    newDate: (date, delta) => new Date(date.getFullYear() + delta, date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()),
  }, {
    text: '00.00.0000 00',
    newDate: (date, delta) => new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() + delta, date.getMinutes()),
  }, {
    text: '',
    newDate: (date, delta) => new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes() + delta),
  },
];

const dateChangers = [
  {
    text: '00.',
    newDate: (date, delta) => new Date(date.getFullYear(), date.getMonth(), date.getDate() + delta, date.getHours(), date.getMinutes()),
  }, {
    text: '00.00.',
    newDate: (date, delta) => new Date(date.getFullYear(), date.getMonth() + delta, date.getDate(), date.getHours(), date.getMinutes()),
  }, {
    text: '',
    newDate: (date, delta) => new Date(date.getFullYear() + delta, date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()),
  },
];

const timeChangers = [
  {
    text: '00:',
    newDate: (date, delta) => new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() + delta, date.getMinutes()),
  }, {
    text: '',
    newDate: (date, delta) => new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes() + delta),
  },
];

let c = 0;

export default {
  name: 'DatePicker',
  props: {
    value: {
      type: Date,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Выберите дату и время',
      required: false,
    },
  },
  data () {
    return {
      pickerId: '',
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      attached: false,
      dateTime: this.value,
    };
  },
  watch: {
    value (to) {
      this.dateTime = to;
    },
  },
  created () {
    this.pickerId = `c-el-datetime-${c = (c + 1) % 1000000}`;
  },
  mounted () {
    this.$refs.dtime.$el.children[0].addEventListener('focus', this.addMouseleaveListener);
    this.$refs.dtime.$el.children[0].addEventListener('wheel', e => this.onDateWheel(e));
  },
  methods: {
    addMouseleaveListener () {
      const elDatepickers = document.querySelectorAll('.el-picker-panel');
      elDatepickers.forEach((datepicker) => {
        if (typeof datepicker.onmouseleave !== 'function') {
          // eslint-disable-next-line
          datepicker.onmouseleave = () => {
            this.$refs.dtime.handleClose();
          };
        }
      });
    },
    onDateWheel (e, t) {
      if (!this.value) {
        return;
      }
      const ref = this.$refs.dtime;
      if (e.deltaY === 0 ||
         ((t === 'date') && ref?.picker?.userInputDate) ||
         ((t === 'time') && ref?.picker?.userInputTime) ||
         (!t && ref?.userInput)) {
        return;
      }

      e.preventDefault();

      // eslint-disable-next-line no-nested-ternary
      const changers = t === 'date' ? dateChangers : (t === 'time' ? timeChangers : dateTimeChangers);
      const div = document.createElement('div');
      const compStyle = getComputedStyle(e.target);
      div.style.position = 'absolute';
      div.style.font = compStyle.font;
      div.style.fontSize = compStyle.fontSize;
      const offsetPx = compStyle.paddingLeft;
      const offset = Number.parseFloat(offsetPx.substring(0, offsetPx.length - 2));

      function getTextWidth (text) {
        div.textContent = text;
        document.body.appendChild(div);
        const widthPx = getComputedStyle(div).width;
        document.body.removeChild(div);
        return Number.parseFloat(widthPx.substring(0, widthPx.length - 2));
      }

      for (let i = 0; i < changers.length - 1; i += 1) {
        if ((e.offsetX - offset) < getTextWidth(changers[i].text)) {
          this.$emit('input', this.dateTime = changers[i].newDate(this.value, -e.deltaY / Math.abs(e.deltaY)));
          return;
        }
      }

      this.$emit('input', this.dateTime = changers[changers.length - 1].newDate(this.value, -e.deltaY / Math.abs(e.deltaY)));
    },
    attachWheelHandlers () {
      if (!this.attached) {
        this.$nextTick(() => {
          const inputs = document.querySelectorAll(`.${this.pickerId} .el-date-picker__editor-wrap input`);
          if (inputs.length === 2) {
            inputs[0].addEventListener('wheel', e => this.onDateWheel(e, 'date'));
            inputs[1].addEventListener('wheel', e => this.onDateWheel(e, 'time'));
            this.attached = true;
          }
        });
      }
    },
  },
};
</script>

<style lang="stylus">
.el-picker-panel__icon-btn {
    font-size: 16px;
    font-weight: 500;
    color: #606266;
    margin-top: 0;
 }
.el-icon-d-arrow-left:before {
  content: "<<";
}
.el-icon-arrow-left:before {
  content: "<";
}
.el-icon-d-arrow-right:before {
  content: ">>";
}
.el-icon-arrow-right:before {
  content: ">";
}
</style>
