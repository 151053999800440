export const state = () => ({
  triggers: [],
  types: [],
});

export const mutations = {
  clearTriggers (state) {
    state.triggers = [];
  },
  setTriggers (state, items) {
    state.triggers = items;
  },
  deleteTrigger (state, id) {
    state.triggers = state.triggers.filter(item => item.Id !== id);
  },
  updateIsEnabled (state, id) {
    const trigger = state.triggers.find(item => item.Id === id);
    trigger.IsEnabled = !trigger.IsEnabled;
  },
  setTypes (state, items) {
    state.types = items;
  },
  deleteObject (state, item) {
    const trigger = state.triggers.find(x => x.Id === item.triggerId);
    if (trigger) {
      trigger.Objects = trigger.Objects.filter(x => x.Id !== item.objectId);
    }
  },
};

export const getters = {
  triggers: state => state.triggers,
};

export const actions = {
  getActions ({ commit }, id) {
    return this.$api.$get(`v1/Triggers/${id}/Actions`).then((data) => {
      if (data && data.value) {
        return data.value;
      }
      return [];
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка получения действий`,
        type: 'danger',
      }, { root: true });
      return false;
    });
  },
  createAction ({ dispatch, commit }, params) {
    const {
      id,
      action,
    } = params;
    return this.$api.$post(`v1/Triggers/${id}/Actions`, action).then(async () => {
      if (action.ActionType === 'SendUI') {
        await dispatch('alerts/getAlerts', null, { root: true });
        await dispatch('alerts/getAlertsCount', null, { root: true });
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка создания действия`,
        type: 'danger',
      }, { root: true });
      return false;
    });
  },
  editAction ({ commit }, params) {
    const {
      id,
      actionId,
      settings,
    } = params;
    return this.$api.$patch(`v1/Triggers/${id}/Actions/${actionId}`, settings).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка редактирования действия`,
        type: 'danger',
      }, { root: true });
      return false;
    });
  },
  deleteAction ({ dispatch, commit }, params) {
    const {
      id,
      actionId,
      action,
    } = params;
    return this.$api.delete(`v1/Triggers/${id}/Actions/${actionId}`).then(async () => {
      if (action.ActionType === 'SendUI') {
        await dispatch('alerts/getAlerts', null, { root: true });
        await dispatch('alerts/getAlertsCount', null, { root: true });
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка удаления действия`,
        type: 'danger',
      }, { root: true });
      return false;
    });
  },
  async getTriggers ({ commit }, accountingUnit = null) {
    await this.$api.$get(`/v1/Triggers?$expand=Objects($select=Id)&$orderby=Id${accountingUnit ? `&$filter=AccountingUnitId eq ${accountingUnit}` : ''}`).then((data) => {
      commit('setTriggers', data.value);
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка получения триггеров`,
        type: 'danger',
      }, { root: true });
    });
    return true;
  },
  getTriggerObjects ({ rootState, commit }, id) {
    if (rootState.user.token) {
      return this.$api.$get(`/v1/Triggers(${id})/Objects`).then((data) => {
        if (data && data.value) {
          return data.value;
        }
        return [];
      }).catch((error) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${error.name} : Ошибка получения объектов для триггера ${id}`,
          type: 'danger',
        }, { root: true });
        return false;
      });
    }
    return false;
  },

  async getTriggersAll ({ commit }, ids = null) {
    if (ids.length > 0) {
      const filter = '&$filter=Objects/any()';
      const filterObjects = `;$filter=Id in (${ids.join(',')})`;
      const { data } = await this.$api.$post('/v1/$batch', {
        requests: [
          {
            id: 'Triggers',
            method: 'GET',
            url: `/v1/Triggers?$expand=Objects($select=Id${filterObjects})&$select=Id,Name${filter}`,
          },
        ],
      }).then(batch => ({
        data: batch.responses.find(el => el.id === 'Triggers').body,
      })).catch((error) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${error.name} : Ошибка получения триггеров`,
          type: 'danger',
        }, { root: true });
      });

      if (data && data.value) {
        return data.value.filter(x => x.Objects.length > 0);
      }
    }
    return [];
  },

  triggerTypes ({ commit }) {
    return this.$api.$get('/v1/Triggers/TriggerTypes').then(({ value }) => value).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка получения типов триггеров`,
        type: 'danger',
      }, { root: true });
    });
  },
  createTrigger ({ dispatch, commit }, trigger) {
    return this.$api.$post('/v1/Triggers', trigger).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка создания триггера: ${error.response ? error.response.data.Error : error.name}`,
        type: 'danger',
      }, { root: true });
    });
  },

  updateTrigger ({ dispatch, commit }, params) {
    const {
      id,
      trigger,
      objectIds,
      accountingUnit,
    } = params;
    this.$api.patch(`/v1/Triggers(${id})`, trigger).then(() => {
      this.$api.post('/v1/Triggers/UpdateObjects', { TriggerId: id, ObjectIds: objectIds }).then(() => {
        dispatch('getTriggers', accountingUnit);
      }).catch((error) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${error.name} : Ошибка изменения объектов триггера`,
          type: 'danger',
        }, { root: true });
        return false;
      });
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка изменения триггера`,
        type: 'danger',
      }, { root: true });
      return false;
    });
    return true;
  },

  changeState ({ commit }, id) {
    this.$api.post('/v1/Triggers/ChangeState', { TriggerId: id }).then(() => {
      commit('updateIsEnabled', id);
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка изменения состояния триггера`,
        type: 'danger',
      }, { root: true });
      return false;
    });
    return true;
  },

  deletedTrigger ({ commit }, id) {
    this.$api.delete(`/v1/Triggers(${id})`).then(() => {
      commit('deleteTrigger', id);
    }).catch((e) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${e.name} : Ошибка удаления триггера`,
        type: 'danger',
      }, { root: true });
    });
  },

  RemoveObject ({ commit }, params) {
    this.$api.post('/v1/Triggers/RemoveObject', { TriggerId: params.triggerId, ObjectId: params.objectId }).then(() => {
      commit('deleteObject', params);
      commit('objects/updateTrigger', params, { root: true });
    }).catch((e) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${e.name} : Ошибка удаления объекта триггера`,
        type: 'danger',
      }, { root: true });
    });
  },
};
