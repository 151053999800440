import {
  errorHelper,
} from '~/plugins/handlers/utils';

export const state = () => ({
  items: [],
});

export const mutations = {
  setItems (state, value) {
    state.items = value;
  },
};

export const getters = {
  apiKeys: state => state.items,
};

export const actions = {
  async fetchItems ({ rootState, commit }) {
    if (rootState.user.token) {
      const resp = await this.$api.$get('/v1/ApiKeys').then((data) => {
        if (data && data.value) {
          // TODO
          commit('setItems', data.value);
          return true;
        }
        return false;
      }).catch((error) => {
        commit('error', errorHelper(error));
        return false;
      });

      return resp;
    }
    return false;
  },

  createdKey ({ dispatch, commit }, apiKey) {
    this.$api.post('/v1/ApiKeys', apiKey).then((data) => {
      if (data) {
        dispatch('fetchItems');
      }
    }).catch((e) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${e.name} : ${e.message} \n ${e.stack}`,
        type: 'danger',
      }, { root: true });
    });
  },

  deleteKey ({ dispatch, commit }, id) {
    this.$api.delete(`/v1/ApiKeys(${id})`).then(() => {
      dispatch('fetchItems');
    }).catch((e) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${e.name} : ${e.message} \n ${e.stack}`,
        type: 'danger',
      }, { root: true });
    });
  },
};
