export const state = () => ({
  alerts: [],
  count: 0,
  refresh: true,
});

export const mutations = {
  clearAlerts (state) {
    state.alerts = [];
    state.count = 0;
  },
  addAlert (state, _item) {
    const item = _item;
    if (!state.alerts.find(x => x.Id === item.Id)) {
      state.alerts.push(item);
      state.alerts.sort((a, b) => {
        if (a.CreateDate > b.CreateDate) {
          return -1;
        }
        if (a.CreateDate < b.CreateDate) {
          return 1;
        }
        return 0;
      });
    }
  },
  setAlerts (state, items) {
    state.alerts = items;
  },
  removeAlert (state, id) {
    state.alerts = state.alerts.filter(el => el.Id !== id);
  },
  getCount  (state, count) {
    state.count = count;
  },
  addCount  (state) {
    state.count += 1;
  },
  removeCount  (state) {
    state.count -= 1;
  },
  updateAlerts  (state, value) {
    state.refresh = value;
  },
};

export const getters = {
  alerts: state => state.alerts,
  countAlerts: state => state.count,
  refresh: state => state.refresh,
};

export const actions = {
  async getAlerts ({ rootState, commit }) {
    const ids = Object.values(rootState.objects.items).map(x => x.id) || [];
    if (ids.length > 0) {
      const filterObjects = `&$filter=Object/Id in (${ids.join(',')})`;

      const { data } = await this.$api.$post('/v1/$batch', {
        requests: [
          {
            id: 'Alerts',
            method: 'GET',
            url: `/v1/Alerts/Unreaded?$expand=Object($select=Id,Name),Trigger($select=AccountingUnitId)&$orderby=CreateDate desc${filterObjects}&$top=100`,
          },
        ],
      }).then(batch => ({
        data: batch.responses.find(el => el.id === 'Alerts').body,
      })).catch((error) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${error.name} : Ошибка получения списка уведомлений`,
          type: 'danger',
        }, { root: true });
      });

      if (data && data.value) {
        commit('setAlerts', data.value);
        commit('updateAlerts', false);
      }
    } else {
      commit('getCount', 0);
      commit('setAlerts', []);
    }
    return true;
  },

  async getAlertsCount ({ rootState, commit }) {
    const ids = Object.values(rootState.objects.items).map(x => x.id) || [];
    if (ids.length > 0) {
      const filterObjects = `&$filter=Object/Id in (${ids.join(',')})`;

      const { data } = await this.$api.$post('/v1/$batch', {
        requests: [
          {
            id: 'Alerts',
            method: 'GET',
            url: `/v1/Alerts/Unreaded?$expand=Object($select=Id)${filterObjects}&$count=true&$top=0 `,
          },
        ],
      }).then(batch => ({
        data: batch.responses.find(el => el.id === 'Alerts').body,
      })).catch((error) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${error.name} : Ошибка получения количества уведомлений`,
          type: 'danger',
        }, { root: true });
      });

      if (data) {
        commit('getCount', data['@odata.count']);
        commit('updateAlerts', true);
      }
    } else {
      commit('getCount', 0);
      commit('setAlerts', []);
    }
    return true;
  },

  async removeAlert ({ commit }, id) {
    await this.$api.post('/v1/Alerts/View', { Id: id }).then(() => {
      commit('removeAlert', id);
      commit('removeCount');
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка удаления уведомления`,
        type: 'danger',
      }, { root: true });
    });
  },

  async removeAlertsAll ({ commit }, ids) {
    await this.$api.post('/v1/Alerts/ViewAll', { ObjectIds: ids }).then(() => {
      commit('clearAlerts');
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка удаления уведомлений`,
        type: 'danger',
      }, { root: true });
    });
  },

  async addAlert ({ state, commit }, id) {
    if (!state.refresh) {
      await this.$api.$get(`/v1/Alerts(${id})?$expand=Object($select=Name),Trigger($select=AccountingUnitId)`).then((data) => {
        if (data) {
          commit('addAlert', data);
          commit('addCount');
        }
      }).catch((error) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${error.name} : Ошибка получения нового уведомления`,
          type: 'danger',
        }, { root: true });
      });
    } else {
      commit('addCount');
    }
  },

  getAlert ({ state, commit }, id) {
      return this.$api.$get(`/v1/Alerts(${id})`).catch((error) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${error.name} : Ошибка получения уведомления`,
          type: 'danger',
        }, { root: true });
      });
  },

  async getTriggerAlerts ({ rootState, commit }, params) {
    const { id, from, to } = params;
    let data = null;
    if (rootState.user.token) {
      try {
        data = await this.$api.$get(`/v1/Alerts?$expand=Object($select=Name)&$orderby=CreateDate desc&$filter=TriggerId eq ${id} and CreateDate ge ${from} and CreateDate le ${to}`);
        if (data && data.value) {
          return data.value;
        }
        return [];
      } catch (error) {
        commit('notify/addNotify', {
          massage: `Ошибка ${error.name} : Ошибка получения уведомлений для триггера ${id}`,
          type: 'danger',
        }, { root: true });
        return false;
      }
    }
    return false;
  },

  async getTriggerAlertsCount ({ rootState, commit }, id) {
    let data = null;
    if (rootState.user.token) {
      try {
        data = await this.$api.$get(`/v1/Alerts/$count?$filter=TriggerId eq ${id}`);
        return data;
      } catch (error) {
        commit('notify/addNotify', {
          massage: `Ошибка ${error.name} : Ошибка получения количества уведомлений для триггера ${id}`,
          type: 'danger',
        }, { root: true });
        return false;
      }
    }
    return false;
  },
};
