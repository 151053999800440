export const state = () => ({
  shared: [],
});

export const mutations = {
  setSharedGeofences (state, item) {
    state.shared = item;
  },
};

export const getters = {
  shared: state => state.shared || [],
};

export const actions = {
  async getSharedGeofences ({ commit }, accountingUnit = null) {
    await this.$api.$get(`/v1/SharedGeofencesGroups?$select=Id,Name${accountingUnit ? `&$filter=AccountingUnitId eq ${accountingUnit}` : ''}`).then((data) => {
      if (data && data.value) {
        commit('setSharedGeofences', data.value);
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка получения Общих групп и их геозон`,
        type: 'danger',
      }, { root: true });
    });
    return true;
  },

  getSharedGeofencesGroups ({ commit }, accountingUnit = null) {
    return this.$api.$get(`/v1/SharedGeofencesGroups?$select=Id,Name${accountingUnit ? `&$filter=AccountingUnitId eq ${accountingUnit}` : ''}`)
      .then(({ value }) => value).catch((error) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${error.name} : Ошибка получения Общих групп геозон`,
          type: 'danger',
        }, { root: true });
        return [];
      });
  },

  getSharedGeofencesGroupsIds ({ commit }, ids = []) {
    const filter = ids.length > 0 ? `&$filter=Id in (${ids.join(',')})` : '';
    return this.$api.$get(`/v1/SharedGeofencesGroups?$select=Id,Name${filter}`)
      .then(({ value }) => value).catch((error) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${error.name} : Ошибка получения Общих групп геозон`,
          type: 'danger',
        }, { root: true });
        return [];
      });
  },

  updatedSharedGeofencesGroups ({ dispatch, commit }, params) {
    const { id, group, accountingUnit } = params;
    this.$api.patch(`/v1/SharedGeofencesGroups(${id})`, group).then((data) => {
      if (data) {
        dispatch('geofences/getGeofences', accountingUnit, { root: true }).then((v) => {
          if (v) {
            dispatch('getSharedGeofences', accountingUnit);
          }
        });
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка изменения Общей группы геозон`,
        type: 'danger',
      }, { root: true });
    });
  },
  createdSharedGeofencesGroups ({ dispatch, commit }, params) {
    const { group, accountingUnit } = params;
    return this.$api.post('/v1/SharedGeofencesGroups', group).then((data) => {
      if (data) {
        return dispatch('geofences/getGeofences', accountingUnit, { root: true }).then((v) => {
          if (v) {
            return dispatch('getSharedGeofences', accountingUnit);
          }
          return Promise.reject(new Error());
        });
      }
      return Promise.reject(new Error());
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка создания Общей группы геозон`,
        type: 'danger',
      }, { root: true });
    });
  },
  deletedSharedGeofencesGroups ({ dispatch, commit }, params) {
    const { id, accountingUnit } = params;
    this.$api.delete(`/v1/SharedGeofencesGroups(${id})`).then((data) => {
      if (data) {
        dispatch('geofences/getGeofences', accountingUnit, { root: true }).then((v) => {
          if (v) {
            dispatch('getSharedGeofences', accountingUnit);
          }
        });
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка удаления Общей группы геозон`,
        type: 'danger',
      }, { root: true });
    });
  },

  addGeofencesInGroups ({ dispatch, commit }, params) {
    const { id, geofencesIds, accountingUnit } = params;
    this.$api.post('/v1/SharedGeofencesGroups/AddGeofences', { Id: id, GeofenceIds: geofencesIds }).then((data) => {
      if (data) {
        dispatch('geofences/getGeofences', accountingUnit, { root: true }).then((v) => {
          if (v) {
            dispatch('getSharedGeofences', accountingUnit);
          }
        });
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка добавления геозон в Общую группу`,
        type: 'danger',
      }, { root: true });
    });
  },

  deleteGeofencesFromGroups ({ dispatch, commit }, params) {
    const { id, all, geofencesIds, accountingUnit } = params;
    this.$api.post('/v1/SharedGeofencesGroups/RemoveGeofences', { Id: id, AllGroups: all, GeofenceIds: geofencesIds }).then((data) => {
      if (data) {
        dispatch('geofences/getGeofences', accountingUnit, { root: true }).then((v) => {
          if (v) {
            dispatch('getSharedGeofences', accountingUnit);
          }
        });
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка удаления геозон из Общей группы`,
        type: 'danger',
      }, { root: true });
    });
  },
};
