/* eslint-disable class-methods-use-this */

// import InvalidAddressError from './errors/InvalidAddress';
import AddressState from './AddressState';

class Address {
  /**
   * @param {String} address - сырая строка адреса
   * @param {AddressState} state
   * @param {String} stub - надпись вместо адреса
   */
  constructor (address, state, stub) {
    this.setAddress(address, state, stub);
  }

  static computeCacheId (lat, lon) {
    return `${lat}_${lon}`;
  }

  /**
   * Переворачивает сырую строку адреса
   *
   * @param {String} rawAddress - сырая строка адреса
   * @returns {String} перевёрнутая строка адреса
   */
  static reverse (rawAddress = '') {
    return rawAddress.split(',').map(z => z.trim()).reverse().join(', ');
  }

  /**
   * Задает/перезаписывает состояние адреса и сам адрес
   *
   * @param {String} address
   * @param {AddressState} state - параметры для изменения адреса, можно передавать не все параметры
   * @param {String} stub - заглушка адреса
   */
  setAddress (address, state, stub) {
    // if (!address && !stub) {
    //   throw new InvalidAddressError(`Set Address Error: ${address}`);
    // }
    if (!state) {
      // eslint-disable-next-line no-param-reassign
      state = {};
    }

    this.stub = stub ?? '';
    this.original = address;
    this.prepared = address;

    const preparedState = { ...state, isStub: !!(!address && stub) };
    this.initialState = new AddressState(preparedState);
    this.state = new AddressState(preparedState);
  }

  /**
   * Подготавливает адрес.
   * Изменения можно отметить с помощью revertChanges
   *
   * @param {Object} options - параметры для изменения адреса
   * @returns {Address}
   */
  prepare (options = {}) {
    // eslint-disable-next-line prefer-const
    let { prepared, stub, state } = this;
    if (state.isStub) {
      return stub;
    }

    // console.debug('Address.prepare - before prepare', { prev: prepared, state, options });
    const { doReverse } = options;

    if (doReverse) {
      prepared = Address.reverse(prepared);
      state.isReversed = !state.isReversed;
    }

    // console.debug('Address.prepare - after prepare', { next: prepared, hasChanges: prepared === this.prepared, state, options });
    this.prepared = prepared;
    return this;
  }

  /**
   * Сбрасывает все изменения сделанные с помощью prepare.
   * Должен работать в независимости от трансформаций в prepare
   */
  revertChanges () {
    this.prepared = this.original;
    this.state = new AddressState(this.initialState);
  }

  toString () {
    return this.prepared ?? this.original ?? this.stub;
  }
}

export default Address;
