import * as qs from 'qs';

export const state = () => ({
  heatmap: [],
  settings: {
    radius: 25,
    blur: 15,
  },
  visible: false,
});

export const mutations = {
  setHeatmap (state, items) {
    state.heatmap = items.map(point => [point.Latitude, point.Longitude, point.Weight]);
  },
  visibleHeatmap (state, visible) {
    state.visible = visible;
  },
  settings (state, value) {
    state.heatmap = [];
    state.visible = false;
    state.settings = value;
  },
};

export const getters = {
  latLngHeatmap: state => state.heatmap,
  visibleHeatmap: state => state.visible,
  settingsHeatmap: state => state.settings,
};

export const actions = {
  getGsm ({ commit }, params) {
    commit('settings', params.settings);
    const param = qs.stringify(params.gsm);

    this.$api.$get(`/v1/Heatmap/Gsm/?${param}`).then((retdata) => {
      commit('setHeatmap', retdata);
      commit('visibleHeatmap', true);
      commit('notify/addNotify', {
        massage: 'Тепловая карта Gsm проблем построена.',
        type: 'success',
      }, { root: true });
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка построения тепловой карты Gsm проблем`,
        type: 'danger',
      }, { root: true });
    });
    return true;
  },

  getGps ({ commit }, params) {
    commit('settings', params.settings);
    const param = qs.stringify(params.gps);

    this.$api.$get(`/v1/Heatmap/Gps/?${param}`).then((retdata) => {
      commit('setHeatmap', retdata);
      commit('visibleHeatmap', true);
      commit('notify/addNotify', {
        massage: 'Тепловая карта Gps проблем построена.',
        type: 'success',
      }, { root: true });
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка построения тепловой карты Gps проблем`,
        type: 'danger',
      }, { root: true });
    });
    return true;
  },

  setVisible ({ commit }) {
    commit('visibleHeatmap', false);
  },
};
