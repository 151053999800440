export const state = () => ({
  private: [],
});

export const mutations = {
  setPrivateGeofences (state, item) {
    state.private = item;
  },
};

export const getters = {
  private: state => state.private || [],
};

export const actions = {
  getPrivateGeofences ({ commit }) {
    return this.$api.$get('/v1/PrivateGeofencesGroups?$expand=Geofences($select=Id)&$select=Id,Name').then((data) => {
      if (data && data.value) {
        commit('setPrivateGeofences', data.value);
      }
      return true;
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка получения Приватных групп и их геозон`,
        type: 'danger',
      }, { root: true });
    });
  },

  getPrivateGeofencesGroups ({ commit }, ids = []) {
    const filter = ids.length > 0 ? `?$filter=Id in (${ids.join(',')})` : '';
    return this.$api.$get(`/v1/PrivateGeofencesGroups${filter}`)
      .then(({ value }) => value).catch((error) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${error.name} : Ошибка получения Приватных групп геозон`,
          type: 'danger',
        }, { root: true });
        return [];
      });
  },

  updatedPrivateGeofencesGroups ({ dispatch, commit }, params) {
    const { id, group, accountingUnit } = params;
    this.$api.patch(`/v1/PrivateGeofencesGroups(${id})`, group).then((data) => {
      if (data) {
        dispatch('geofences/getGeofences', accountingUnit, { root: true }).then((v) => {
          if (v) {
            dispatch('getPrivateGeofences', accountingUnit);
          }
        });
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка изменения Приватной группы геозон`,
        type: 'danger',
      }, { root: true });
    });
  },
  createdPrivateGeofencesGroups ({ dispatch, commit }, params) {
    const { group, accountingUnit } = params;
    this.$api.post('/v1/PrivateGeofencesGroups', group).then((data) => {
      if (data) {
        dispatch('geofences/getGeofences', accountingUnit, { root: true }).then((v) => {
          if (v) {
            dispatch('getPrivateGeofences', accountingUnit);
          }
        });
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка создания Приватной группы геозон`,
        type: 'danger',
      }, { root: true });
    });
  },
  deletedPrivateGeofencesGroups ({ dispatch, commit }, params) {
    const { id, accountingUnit } = params;
    this.$api.delete(`/v1/PrivateGeofencesGroups(${id})`).then((data) => {
      if (data) {
        dispatch('geofences/getGeofences', accountingUnit, { root: true }).then((v) => {
          if (v) {
            dispatch('getPrivateGeofences', accountingUnit);
          }
        });
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка удаления Приватной группы геозон`,
        type: 'danger',
      }, { root: true });
    });
  },

  addGeofencesInGroups ({ dispatch, commit }, params) {
    const { id, geofencesIds, accountingUnit } = params;
    this.$api.post('/v1/PrivateGeofencesGroups/AddGeofences', { Id: id, GeofenceIds: geofencesIds }).then((data) => {
      if (data) {
        dispatch('geofences/getGeofences', accountingUnit, { root: true }).then((v) => {
          if (v) {
            dispatch('getPrivateGeofences', accountingUnit);
          }
        });
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка добавления геозон в Приватную группу`,
        type: 'danger',
      }, { root: true });
    });
  },

  deleteGeofencesFromGroups ({ dispatch, commit }, params) {
    const { id, all, geofencesIds, accountingUnit } = params;
    this.$api.post('/v1/PrivateGeofencesGroups/RemoveGeofences', { Id: id, AllGroups: all, GeofenceIds: geofencesIds }).then((data) => {
      if (data) {
        dispatch('geofences/getGeofences', accountingUnit, { root: true }).then((v) => {
          if (v) {
            dispatch('getPrivateGeofences', accountingUnit);
          }
        });
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка удаления геозон из Приватной группы`,
        type: 'danger',
      }, { root: true });
    });
  },
};
