<template>
  <div style="display: none;">
    <slot v-if="ready" />
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet.gridlayer.googlemutant';

import { findRealParent, propsBinder } from 'vue2-leaflet';

const props = {
  options: {
    type: Object,
    default () { return {}; },
  },
  apikey: {
    type: String,
    default () { return ''; },
  },
  lang: {
    type: String,
    default: null,
  },
  region: {
    type: String,
    default: null,
  },
  name: {
    type: String,
    default: '',
  },
  layerType: {
    type: String,
    default: 'base',
  },
  visible: {
    type: Boolean,
    default: true,
  },
};

export default {
  props,
  data () {
    return {
      ready: false,
    };
  },
  watch: {
    ready (n) {
      if (n) {
        this.$emit('ready', n);
      }
    },
  },
  mounted () {
    this.mapObject = L.gridLayer.googleMutant(this.options);
    L.DomEvent.on(this.mapObject, this.$listeners);
    propsBinder(this, this.mapObject, props);

    // eslint-disable-next-line no-undef
    if (!(typeof google === 'object' && typeof google.maps === 'object')) {
      const googleapisscript = document.createElement('script');
      let scriptUrl = `https://maps.googleapis.com/maps/api/js?key=${this.apikey}`;

      scriptUrl += this.lang ? `&language=${this.lang}` : '';
      scriptUrl += this.region ? `&region=${this.region}` : '';

      googleapisscript.setAttribute('src', scriptUrl);
      googleapisscript.setAttribute('google-map', 'ready');

      if (!(document.head.querySelector('[google-map]'))) { document.head.appendChild(googleapisscript); }
    }

    let tryConnect = 0;
    (function complite (ctx) {
      // eslint-disable-next-line no-undef
      if (typeof google === 'object' && typeof google.maps === 'object') {
        ctx.ready = true;
        ctx.parentContainer = findRealParent(ctx.$parent);
        ctx.parentContainer.addLayer(ctx, !ctx.visible);
      } else if (tryConnect < 100) {
        tryConnect += 1;
        setTimeout(() => {
          complite(ctx);
        }, 100);
      }
    })(this);
  },
  beforeDestroy () {
    if (this.parentContainer) { this.parentContainer.removeLayer(this); }
  },
  methods: {
    addLayer (layer, alreadyAdded) {
      if (!alreadyAdded) {
        this.mapObject.addLayer(layer.mapObject);
      }
    },
    removeLayer (layer, alreadyRemoved) {
      if (!alreadyRemoved) {
        this.mapObject.removeLayer(layer.mapObject);
      }
    },
  },
};
</script>
