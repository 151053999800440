export default function apiInstanse ({ $http, env }, inject) {
  const api = $http.create({
    headers: {
      DateFormat: 'unix',
      Accept: 'application/json',
    },
    prefixUrl: env.API_ADDRESS,
    // mode: 'no-cors',
  });

  // api.setBaseURL(env.API_ADDRESS);

  inject('api', api);
}
