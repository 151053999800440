export const state = () => ({
  geofences: [],
  deleted: [],
  // private: [],
  geoJson: null,
  optionsGeo: {},
  editing: false,
  v: 0,
  geofencesVisibleIds: [],
  // currentAccountingUnit: 0,
  disableEditLayer: false,
  pointByCoordinates: null,
  countLayers: 0,
  openGeofencesSidebar: false,
});

export const mutations = {
  setDisableEditLayer (state, disable) {
    state.disableEditLayer = disable;
  },
  setPointByCoordinates (state, coords) {
    state.pointByCoordinates = coords;
  },
  setCountLayers (state, count) {
    state.countLayers = count;
  },
  setGeofences (state, items) {
    state.geofences = items;
  },
  setDeleted (state, items) {
    state.deleted = items;
  },
  // setPrivateGeofences (state, item) {
  //   state.private = item;
  // },
  setGeofencesJson (state, item) {
    state.geoJson = JSON.parse(JSON.stringify(item));
  },
  setGeofencesOptions (state, item) {
    state.optionsGeo = item;
  },
  setEditing (state, item) {
    state.editing = item;
  },
  addGeofence (state, item) {
    state.geofences.push(item);
  },
  delGeofence (state, id) {
    const g = state.geofences.find(geo => geo.Id === id);
    if (g) {
      state.deleted.push(g);
      state.geofences = state.geofences.filter(geo => geo.Id !== id);
    }
  },
  delDeleted (state, id) {
    const g = state.deleted.find(geo => geo.Id === id);
    if (g) {
      state.geofences.push(g);
      state.deleted = state.deleted.filter(geo => geo.Id !== id);
    }
  },
  getGeofencesIds (state, selected) {
    state.geofencesVisibleIds = state.geofences.map(g => g.Id).filter(id => selected.includes(id));
  },
  incVersion (state) {
    state.v += 1;
  },
  // setAccountingUnit (state, unit) {
  //   state.currentAccountingUnit = unit;
  // },
  setOpenGeofencesSidebar (state, flag) {
    state.openGeofencesSidebar = flag;
  },
};

export const getters = {
  geofences: state => state.geofences || [],
  count: state => state.geofences.length || 0,
  geofencesDel: state => state.deleted || [],
  countDel: state => state.deleted.length || 0,
  // private: state => state.private || [],
  countGeofence: state => state.geofencesVisibleIds.length,
  disableEditLayer: state => state.disableEditLayer,
  pointByCoordinates: state => state.pointByCoordinates,
  countLayers: state => state.countLayers,
};

export const actions = {
  async getGeofences ({ rootState, commit }, accountingUnit = null) {
    await this.$api.$get(`/v1/Geofences?$expand=SharedGroups&$orderby=Id${accountingUnit ? `&$filter=AccountingUnitId eq ${accountingUnit}` : ''}&$count=true`).then((data) => {
      if (data && data.value) {
        commit('setGeofences', data.value);
        commit('getGeofencesIds', rootState.preferences.geofencesSelectedIds);
      }
      return true;
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка получения геозон`,
        type: 'danger',
      }, { root: true });
      return false;
    });
    return true;
  },

  async getGeofenceId ({ rootState, commit }, id) {
    let data = null;
    if (rootState.user.token) {
      try {
        data = await this.$api.$get(`/v1/Geofences(${id})`);
        if (data) {
          return data;
        }
        return [];
      } catch (error) {
        commit('notify/addNotify', {
          massage: `Ошибка ${error.name} : Ошибка получения геозоны`,
          type: 'danger',
        }, { root: true });
        return false;
      }
    }
    return true;
  },

  async getDeletedGeofences ({ commit }, accountingUnit = null) {
    await this.$api.$get(`/v1/Geofences/Deleted?$orderby=Id${accountingUnit ? `&$filter=AccountingUnitId eq ${accountingUnit}` : ''}&$count=true`).then((data) => {
      if (data && data.value) {
        commit('setDeleted', data.value);
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка получения удаленных геозон`,
        type: 'danger',
      },
      {
        root: true,
      });
    });
    return true;
  },

  async selectItems ({ rootState, commit, dispatch }, select = []) {
    const currentSelected = rootState.preferences.geofencesSelectedIds.slice(0);
    select.forEach((id) => {
      if (!currentSelected.includes(id)) {
        currentSelected.push(id);
      }
    });
    await dispatch('preferences/setPreference', {
      geofencesSelectedIds: JSON.stringify(Array.from(currentSelected)),
    }, { root: true });
    commit('getGeofencesIds', currentSelected);
  },

  async unselectItems ({ rootState, commit, dispatch }, unselect = []) {
    let currentSelected = rootState.preferences.geofencesSelectedIds.slice(0);
    unselect.forEach((id) => {
      currentSelected = currentSelected.filter(x => x !== id);
    });
    await dispatch('preferences/setPreference', {
      geofencesSelectedIds: JSON.stringify(Array.from(currentSelected)),
    }, { root: true });
    commit('getGeofencesIds', currentSelected);
  },

  async updatedGeofence ({ dispatch, commit }, params) {
    const { id, geofence, accountingUnit } = params;
    await this.$api.patch(`/v1/Geofences(${id})`, geofence).then(async () => {
      await dispatch('getGeofences', accountingUnit);
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка изменения геозоны`,
        type: 'danger',
      }, { root: true });
    });
  },

  createdGeofence ({ rootState, dispatch, commit }, params) {
    const { geofence } = params;
    this.$api.$post('/v1/Geofences', geofence).then((data) => {
      if (data) {
        commit('addGeofence', data);
        dispatch('selectItems', [data.Id]);
        commit('getGeofencesIds', rootState.preferences.geofencesSelectedIds);
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка создания геозоны`,
        type: 'danger',
      }, { root: true });
    });
  },

  deletedGeofence ({ rootState, commit }, id) {
    return this.$api.delete(`/v1/Geofences(${id})`).then((data) => {
      if (data) {
        commit('delGeofence', id);
        commit('getGeofencesIds', rootState.preferences.geofencesSelectedIds);
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка удаления геозоны`,
        type: 'danger',
      }, { root: true });
    });
  },

  restoredGeofence ({ rootState, commit }, id) {
    return this.$api.post('/v1/Geofences/Restore', { id }).then((data) => {
      if (data) {
        commit('delDeleted', id);
        commit('getGeofencesIds', rootState.preferences.geofencesSelectedIds);
      }
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка восстановления геозоны`,
        type: 'danger',
      }, { root: true });
    });
  },

  async fetchAllItems ({ rootState, commit }, accountingUnit = null) {
    if (rootState.user.token) {
      const res = await this.$api.$get(`/v1/Geofences?$orderby=Id&$select=Id,Name${accountingUnit ? `&$filter=AccountingUnitId eq ${accountingUnit}` : ''}`).then((data) => {
        if (data && data.value) {
          return data.value;
        }
        return [];
      }).catch((error) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${error.name} : Ошибка получения списка геозон`,
          type: 'danger',
        }, { root: true });
        return false;
      });
      return res;
    }
    return false;
  },

  async fetchGeofencesIds ({ commit }, ids = []) {
    const filter = ids.length > 0 ? `&$filter=Id in (${ids.join(',')})` : '';
    const res = await this.$api.$get(`/v1/Geofences?$orderby=Id&$select=Id,Name${filter}`).then((data) => {
      if (data && data.value) {
        return data.value;
      }
      return [];
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка получения геозон`,
        type: 'danger',
      }, { root: true });
      return [];
    });
    return res;
  },

  async getLayerGeofences ({ commit }, ids = []) {
    await this.$api.post('/v1/Geofences/UpdateLayer', { ids }).then(() => {
      commit('incVersion');
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка изменения списка геозон отображаемых на карте`,
        type: 'danger',
      }, { root: true });
    });
  },

  async importGeofence ({ commit, dispatch }, params) {
    const { file, accountingUnit, existing } = params;
    await this.$api.post('/v1/Geofences/ImportKml', { Data: file, AccountingUnitId: accountingUnit, UpdateExisting: existing }).then(async () => {
      await dispatch('getGeofences', accountingUnit);
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка импорта геозоны`,
        type: 'danger',
      }, { root: true });
    });
  },

  async exportGeofences ({ commit }, ids = []) {
    if (ids.length > 0) {
      const exp = ids.join(',');
      await this.$api.$get(`/v1/Geofences/ExportKml(ids=[${exp}])`).then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'geos.kml');
        link.click();
      }).catch((error) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${error.name} : Ошибка экспорта геозон`,
          type: 'danger',
        }, { root: true });
      });
    }
  },

  getConvexGeofence ({ commit }, params) {
    return this.$api.$post('/v1/TrackPoints/ExtractConvexPolygon', params).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка получения выпуклой геозоны по построенному треку`,
        type: 'danger',
      }, { root: true });
    });
  },
};
