<template lang="pug">
  .root(v-if="$store.state.user.data")
    .header.container-fluid.bg-light
      .row.no-gutter.align-items-center.h-100.py-2
        .col.h-100.d-flex.align-items-center
          .h6.text-success.m-0
            img.img-fluid.logo-top(src="@/assets/img/logo.png", alt="LogExpert")
        .col.h-100.menu.d-flex.align-items-center.justify-content-end.font-italic.font-weight-bold(v-if="showTime")
          b-icon.mr-1(icon="alarm", font-scale="1.5")
          span {{ time.days }} {{ time.hms }}
    .main
      LeMainMap
      nuxt(keep-alive)
</template>

<script>

import { mapGetters } from 'vuex';
import moment from 'moment';
import LeMainMap from '~/components/Le/Main/';

export default {
  name: 'Locator',
  components: {
    LeMainMap,
  },
  data () {
    return {
      timerExistLocator: null,
      time: {
        days: '',
        hms: '',
      },
      showTime: true,
    };
  },
  computed: {
    ...mapGetters('user', ['expireDate']),
  },
  async beforeCreate () {
    if (await this.$store.dispatch('user/fetchData', true)) {
      await this.$store.dispatch('objects/initMQTT');
    } else {
      this.$router.push('/locator-error/');
    }
  },
  mounted () {
    this.timerExistLocator = setInterval(() => {
      this.checkTime();
    }, 1000);
  },
  beforeDestroy () {
    clearInterval(this.timerExistLocator);
  },
  methods: {
    checkTime () {
      const durationDay = 86400000;
      const differenceDuration = (this.expireDate * 1000) - new Date().getTime();
      const countDays = Math.round(differenceDuration / durationDay);
      if (countDays > 365) {
        this.showTime = false;
        return;
      }
      let strDay;
      switch (String(countDays)[String(countDays).length - 1]) {
        case '1':
          strDay = ' день';
          break;
        case '2':
        case '3':
        case '4':
          strDay = ' дня';
          break;
        default:
          strDay = ' дней';
      }
      const residue = differenceDuration - countDays;
      this.time.days = countDays ? countDays + strDay : '';
      this.time.hms = moment(residue).format('LTS');
    },
  },
};
</script>

<style lang="stylus">
  body {
    overflow: hidden;
  }
</style>
<style lang="stylus" scoped>
.root {
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  display: flex;
}

.header {
  min-height: 54px;
  user-select: none;
}

.logo-top {
  pointer-events: none;
  user-select: none;
}

.main {
  flex: 1;
  position: relative;
}
</style>
