/* eslint-disable no-underscore-dangle */

import { EVENT_BUS_EVENTS } from '../consts/eventTypes';

// subscribers {
//   actionName: { Map
//     subscriberName: callback
//   }
// }
class EventBus {
  constructor () {
    this.subscribers = {
      destroyEventBus: new Map(),
    };
  }

  simplify (subscriberName = '') {
    // console.debug(`simplified for subscriber, "${subscriberName}"`);
    if (!subscriberName) {
      throw new Error('EventBus: invalid subscriberName');
    }

    return {
      subscribe: (actionName, callback) => this.subscribe(actionName, subscriberName, callback),
      unsubscribe: actionName => this.unsubscribe(actionName, subscriberName),
      notify: (actionName, data) => this.notify(actionName, data),
      destroy: () => this.destroy(),
    };
  }

  subscribe (actionName = '', subscriberName = '', callback) {
    if (!actionName) {
      throw new Error('EventBus: invalid actionName');
    }
    if (!subscriberName) {
      throw new Error('EventBus: invalid subscriberName');
    }

    if (!this.subscribers[actionName]) {
      this.subscribers[actionName] = new Map();
    }
    this.subscribers[actionName].set(subscriberName, callback);
  }

  unsubscribe (actionName, subscriberName) {
    if (!actionName) {
      throw new Error('EventBus: invalid actionName');
    }
    if (!subscriberName) {
      throw new Error('EventBus: invalid subscriberName');
    }

    const actionCallbacks = this.subscribers[actionName];
    actionCallbacks.delete(subscriberName);
  }

  notify (actionName, data) {
    if (!this.subscribers[actionName]) {
      return;
    }

    const callbacks = this.subscribers[actionName];
    callbacks.forEach((call) => {
      call && call(data);
    });
  }

  destroy () {
    this.notify(EVENT_BUS_EVENTS.destroyEventBus);
    this.subscribers = {
      destroyEventBus: new Map(),
    };
  }
}

export default EventBus;
