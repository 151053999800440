import PeriodicServerPolling from '../services/PeriodicServerPolling';
import {
  EVENT_BUS_EVENTS,
  PERIODIC_SERVER_POLLING_EVENTS,
} from '../consts/eventTypes';

export default (context) => {
  context.store.dispatch('init', context);
  context.store.dispatch('user/init', context);
  context.store.dispatch('tracks/init', context);

  const FIVE_MIN_IN_MILLISECOND = 300000;
  const periodicServerPolling = new PeriodicServerPolling(FIVE_MIN_IN_MILLISECOND, context.$eventBus, context.$api);
  context.$eventBus.subscribe(EVENT_BUS_EVENTS.destroyEventBus, 'periodicServerPolling', periodicServerPolling.destroy);

  initOneHourEvents(context.$eventBus);
};

function initOneHourEvents (eventBus) {
  if (!eventBus) {
    throw new Error('init oneHoursEvents fail: eventBus is invalid');
  }

  let lastDate = null;
  window.addEventListener('blur', () => {
    lastDate = Date.now();
  });

  window.addEventListener('focus', () => {
    const ONE_HOUR_IN_MS = 3600000;
    const oneHourPassed = ((Date.now() - lastDate) / ONE_HOUR_IN_MS) >= 1;
    if (!oneHourPassed) {
      // console.debug('initOneHourEvents - LESS then one hour', { howManyInHours: (Date.now() - lastDate) / ONE_HOUR_IN_MS });
      return;
    }

    // console.debug('initOneHourEvents - MORE then one hour');
    eventBus.notify(PERIODIC_SERVER_POLLING_EVENTS.sessionIsOutdated);
  });
}
