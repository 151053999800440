<script>
import Vue from 'vue';
import { DateTime } from 'luxon';
import { mapGetters } from 'vuex';

const Clock = new Vue({
  created () {
    this.listeners = 0;
  },
  methods: {
    start () {
      this.interval = setInterval(this.emit, 10000);
    },
    emit () {
      this.$emit('tick');
    },
    register (cb) {
      if (!cb) {
        return;
      }
      if (this.listeners === 0) {
        this.start();
      }
      this.listeners += 1;
      this.$on('tick', cb);
    },
    unregister (cb) {
      if (!cb) {
        return;
      }
      this.listeners -= 1;
      this.$off('tick', cb);
      if (this.listeners === 0) {
        clearInterval(this.interval);
      }
    },
  },
});

export default {
  name: 'DynamicFromNow',
  props: {
    tag: { type: String, default: 'span' },
    state: { type: Boolean, default: false },
    value: { type: Date, default: null },
    dropFixes: {
      default: false,
      type: Boolean,
    },
    interval: { type: Number, default: 1000 },
  },
  data () {
    return { fromNow: this.timeLast(this.value) };
  },
  computed: {
    ...mapGetters('preferences', { settingsBase: 'settingsAUnit' }),
    locale () {
      return this.settingsBase.language;
    },
  },
  watch: {
    locale () {
      this.updateFromNow();
    },
  },
  mounted () {
    Clock.register(this.updateFromNow);
    this.$watch('value', this.updateFromNow);
    this.updateFromNow();
  },
  beforeDestroy () {
    Clock.unregister(this.intervalId);
  },
  methods: {
    updateFromNow () {
      const newFromNow = this.timeLast(this.value);
      if (newFromNow !== this.fromNow) {
        this.fromNow = newFromNow;
      }
    },
    timeLast (dateValue) {
      let res;
      try {
        if (dateValue && dateValue.valueOf()) {
          const selfTime = DateTime.fromISO(dateValue.toISOString()).setLocale(this.locale);
          if (dateValue >= new Date()) {
            res = this.$t('popoverobject.luxon.now');
          } else {
            res = selfTime.toRelative();
          }
        } else {
          res = this.$t('popoverobject.luxon.undefined');
        }
      } catch (e) {
        // TODO
      }
      if (this.state) {
        res = res.replace(this.$t('popoverobject.luxon.ago'), '');
      }
      return res === 'Invalid date' ? this.$t('popoverobject.luxon.undefined') : res;
    },
  },

  render (h) {
    return h(this.tag, { key: this.fromNow }, this.fromNow);
  },
};
</script>
