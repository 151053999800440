export const state = () => ({
  items: [],
});

export const mutations = {
  setItems (state, value) {
    state.items = value;
  },
};

export const getters = {
  accountingUnits: state => state.items,
};

export const actions = {
  fetchItems ({ rootState, commit }) {
    if (rootState.user.token) {
      const resp = this.$api.$get('/v1/AccountingUnits?$orderby=Name').then((data) => {
        if (data && data.value) {
          // TODO
          commit('setItems', data.value);
          return true;
        }
        return false;
      }).catch((error) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${error.name} : Ошибка получения учетных единиц`,
          type: 'danger',
        });
        return false;
      });
      return resp;
    }
    return false;
  },
};
