<template lang="pug">
  .d-flex.flex-column.flex-grow-1.overflow-hidden(v-if="(geofences.length > 0 && viewGeofence !== 3) || (geofencesDel.length > 0 && viewGeofence === 3)")
    .d-flex.w-100.align-items-center.justify-content-between.p-1.mb-1(v-if="variantList !== 'locator'")
      b-form-checkbox(v-if="viewGeofence === 3" v-model="allSelectedFoundGeofencesDel",  @change="toggleAllSelectedFoundGeofences" :indeterminate="indeterminate", style="font-size: 14px;") {{ $tc('geofence.geofence', 2) }}
      b-form-checkbox(v-else v-model="allSelectedFoundGeofences",  @change="toggleAllSelectedFoundGeofences" :indeterminate="indeterminate", style="font-size: 14px;") {{ $tc('geofence.geofence', 2) }}
      b-button.p-0(v-if="viewGeofence !== 3 && variantList === 'monitoring'", variant="link", @click="visibleAllGeofences")
        b-icon(:icon="allVisible ? 'eye' : 'eye-slash'", variant="dark")
      b-button(v-if="viewGeofence !== 0 && viewGeofence !== 3 && variantList === 'outMonitoring'", :pressed.sync="openStatusGroups", variant="primary", size="sm") {{openStatusGroups ? 'Скрыть все группы' : 'Раскрыть все группы'}}
    b-button.p-0(v-if="variantList === 'locator'", variant="link", :class="visibleSearch ? 'position-button__show-search' : 'position-button__hide-search'", @click="visibleAllGeofences")
      b-icon(:icon="allVisible ? 'eye' : 'eye-slash'", variant="dark")
    q-virtual-scroll(ref="virtualScroll", v-if="currentList.length > 0", :items="currentList", @virtual-scroll="scrolled($event)", separator, virtual-scroll-item-size="34")
      template(v-slot="{ item, index }")
        template(v-if="item.group !== undefined")
          b-button.w-100.border-bottom.group-header(squared, size="sm", :key="item.index", :variant="item.group.Open ? 'white' : 'light'" :active="item.group.Open", @click="item.group.Open = !item.group.Open")
            .d-flex.w-100.align-items-center
              .pr-2
                b-icon(:icon="item.group.Open ? 'chevron-down' : 'chevron-right'", :variant="item.group.Open ? 'success' : 'dark'")
              b-form-checkbox(:indeterminate="checkGroupSelectIndeterminate(item.group)", @change="onCheckedGeofenceGroup(item.group, $event)")
              .objects-list--name.w-100.d-flex.pr-2.align-items-center.font-weight-bolder {{ `${item.group.Name} (${filterGroup(item.group.Geofences).length})` }}
              b-button.p-0.ml-2(v-if="item.group.Edit && variantList === 'monitoring' && viewGeofence === 1 && isAdmin", variant="link", size="sm", @click.stop="$bvModal.show(`edit-shared-group-geo-${item.group.Id}`)")
                b-icon(icon="pencil", variant="dark")
              b-modal(:id="`edit-shared-group-geo-${item.group.Id}`", title="Редактирование общей группы", centered, body-class="objects-modal", @show="nameGroup = item.group.Name", @ok="modalEditGroup(item.group.Id, nameGroup, currentAccountingUnitGeofence)")
                b-row
                  b-col(md="12")
                    b-form-input(v-model='nameGroup', required, placeholder='введите имя группы', type="text")
                template(v-slot:modal-footer="{ ok, hide }")
                  .w-100.d-flex.modal-btn
                    b-button.mr-2.float-left(@click="ok()", variant="success", size="sm", :disabled="nameGroup === '' ? 'disabled' : null") Изменить
                    b-button.float-right(@click="hide()", variant="danger", size="sm") Отмена
              b-button.p-0.ml-2(v-if="item.group.Edit && variantList === 'monitoring' && viewGeofence === 1 && isAdmin", variant="link", size="sm", @click.stop="deleteGeofenceGroup(item.group.Id, currentAccountingUnitGeofence)")
                b-icon(icon="trash", variant="danger")
              b-button.p-0.ml-2(v-if="item.group.Edit && variantList === 'monitoring' && viewGeofence === 2", variant="link", size="sm", @click.stop="$bvModal.show(`edit-private-group-geo-${item.group.Id}`)")
                b-icon(icon="pencil", variant="dark")
              b-modal(:id="`edit-private-group-geo-${item.group.Id}`", :title="`Редактрирование приватной группы`", centered, body-class="objects-modal", @show="nameGroup = item.group.Name", @ok="modalEditGroupPrivate(item.group.Id, nameGroup)")
                b-row
                  b-col(md="12")
                    b-form-input(v-model="nameGroup", required, autofocus, :placeholder="$t('monitoring.enternamegroup')", type="text")
                template(v-slot:modal-footer="{ ok, hide }")
                  .w-100.d-flex.modal-btn
                    b-button.mr-2.float-left(@click="ok()", variant="success", size="sm", :disabled="nameGroup === '' ? 'disabled' : null") {{$t('actionsList.change')}}
                    b-button.float-right(@click="hide()", variant="danger", size="sm") {{$t('actionsList.cancel')}}
              b-button.p-0.ml-2(v-if="item.group.Edit && variantList === 'monitoring' && viewGeofence === 2", variant="link", size="sm", @click.stop="deleteGeofenceGroupPrivate(item.group.Id)")
                b-icon(icon="trash", variant="danger")
              b-button.p-0.ml-2(v-if="variantList === 'monitoring'" variant="link", size="sm", @click.stop="visibleGeofenceGroup(item.group)")
                b-icon(:icon="item.group.Visible ? 'eye' : 'eye-slash'", variant="dark")
        template(v-else)
          b-button.w-100.border-bottom(
            :key="item.index",
            squared,
            variant="light",
            size="sm",
            :title="item.value.Name",
            @mouseenter="variantList !== 'outMonitoring' ? setTooltype($event.target, item.value) : null",
            @click="variantList !== 'outMonitoring' ? setActiveGeofence(item.value) : null")
            .d-flex.w-100.align-items-center
              .pr-2(v-if="item.groupId")
                b-icon(icon="blank")
              .pr-2(@click.stop)
                b-form-checkbox.list-groups-checkbox(v-if="viewGeofence === 3" v-model="checkedGeofencesDel", :value="item.value.Id", @change="onCheckedGeofenceDel", @click.stop)
                b-form-checkbox.list-groups-checkbox(v-if="variantList !== 'locator' && viewGeofence !== 3", v-model="checkedGeofences", :value="item.value.Id", @change="onCheckedGeofence(item.value)", @click.stop)
                //b-form-checkbox(v-model="item.value.Selected",  @change="onCheckedGeofence(item.value.Id, item.value.Selected)")
              .pr-2
                b-icon(:icon="getTypeIcon(item.value.type)", size="sm", variant="dark")
              .geofences-list--name.w-100.pr-2.align-items-center.text-truncate.text-left {{ item.value.Name }}
              template(v-if="viewGeofence === 3")
                b-button.p-0(v-if="variantList === 'monitoring'", variant="link", size="sm", @click="restoreGeofence(item.value.Id)")
                  b-icon(icon="arrow-counterclockwise", variant="dark")
              template(v-else)
                b-button.mr-2.p-0(v-if="variantList === 'monitoring'", variant="link", size="sm", @click="updateGeofence(item.value.Id, index)")
                  b-icon(icon="pencil", variant="dark")
                b-button.mr-2.p-0(v-if="variantList === 'monitoring'" variant="link", size="sm", @click.stop="$bvModal.show(`delete-geofence-${item.index}-${item.value.Id}`)")
                  b-icon(icon="trash", variant="danger")
                b-modal(:id="`delete-geofence-${item.index}-${item.value.Id}`" centered title="Удаление геозоны")
                  p(class="my-4") Удалить геозону {{ item.value.Name }}?
                  template(#modal-footer="{ ok, cancel, hide }")
                    b-button(size="sm" variant="success" @click="deleteGeofence(item.value.Id), ok()") OK
                    b-button(size="sm" variant="danger" @click="cancel()") Отмена
                b-button.p-0(v-if="variantList !== 'outMonitoring'", variant="link", size="sm", @click.stop="visibleGeofence(item.value.Id)")
                  b-icon(:icon="item.value.Visible ? 'eye' : 'eye-slash'", variant="dark")
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ListGroupsGeofences',
  props: {
    hoverObject: {
      type: Object,
      default () {
        return {};
      },
    },
    activeGeofence: {
      type: Number,
      default: null,
    },
    foundGeofences: {
      type: Array,
      default () {
        return [];
      },
    },
    foundGeofencesDel: {
      type: Array,
      default () {
        return [];
      },
    },
    geofences: {
      type: Array,
      default () {
        return [];
      },
    },
    geofencesDel: {
      type: Array,
      default () {
        return [];
      },
    },
    sharedGroupsGeofences: {
      type: Array,
      default () {
        return [];
      },
    },
    privateGroupsGeofences: {
      type: Array,
      default () {
        return [];
      },
    },
    currentAccountingUnitGeofence: {
      type: Number,
      default: null,
    },
    variantList: {
      type: String,
      default: '',
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    geofenceToFind: {
      type: String,
      default: '',
    },
    rr: {
      type: Boolean,
      default: true,
    },
    viewGeofence: {
      type: Number,
      default: 0,
    },
    visibleSearch: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      virtualScrollSliceRange: 0,
      lastChecked: null,
      checkedGeofences: [],
      checkedGeofencesDel: [],
      openStatusGroups: true,
      foundGeofencesId: [],
      foundGeofencesDelId: [],
      nameGroup: '',
      allSelectedFoundGeofences: false,
      allSelectedFoundGeofencesDel: false,
      selectedFoundGeofences: [],
      selectedFoundGeofencesDel: [],
      allVisible: false,
      clickCheckboxFlag: false,
    };
  },
  computed: {
    ...mapGetters('listGroupsGeofences', ['checkedGeofencesOutMonitoring', 'scrollPositionMonitoring', 'scrollPositionOutMonitoring']),
    indeterminate () {
      if (this.viewGeofence !== 3) {
        return this.selectedFoundGeofences.length > 0 && this.selectedFoundGeofences.length < this.foundGeofencesId.length;
      }
      return this.selectedFoundGeofencesDel.length > 0 && this.selectedFoundGeofencesDel.length < this.foundGeofencesDelId.length;
    },
    currentList () {
      let group = '';
      if (this.viewGeofence === 1 && this.sharedGroupsGeofences.length > 0) {
        group = 'sharedGroupsGeofences';
      } else if (this.viewGeofence === 2 && this.privateGroupsGeofences.length > 0) {
        group = 'privateGroupsGeofences';
      }

      if (group) {
        const newArr = this[group].map((el) => {
          let list = [];
          const selected = this.foundGeofences.map(({ Id }) => Id);
          if (el.Open) {
            list = el.Geofences.filter(({ Id }) => selected.includes(Id));
            // console.debug(list);
          }
          return [{ group: el, index: `group${group}_id${el.Id}`, Open: el.Open }, ...list.map(item => ({
            value: item,
            groupId: `group${group}_id${el.Id}`,
            index: `group${group}_id${el.Id}_item${item.Id}`,
          }))];
        });

        return newArr.flat();
      }

      if (this.viewGeofence === 3) {
        return this.foundGeofencesDel.map(item => ({
          value: item,
          index: `deleted_${item.Id}`,
        }));
      }
      return this.foundGeofences.map(item => ({
        value: item,
        index: `_${item.Id}`,
      }));
    },
  },
  watch: {
    selectedFoundGeofences (n, o) {
      if (n.length === 0) {
        this.allSelectedFoundGeofences = false;
      } else if (n.length === this.foundGeofencesId.length) {
        this.allSelectedFoundGeofences = true;
      } else {
        this.allSelectedFoundGeofences = false;
      }
    },
    selectedFoundGeofencesDel (n, o) {
      if (n.length === 0) {
        this.allSelectedFoundGeofencesDel = false;
      } else if (n.length === this.foundGeofencesDelId.length) {
        this.allSelectedFoundGeofencesDel = true;
      } else {
        this.allSelectedFoundGeofencesDel = false;
      }
    },
    viewGeofence (n) {
      if (n === 0 && this.variantList === 'outMonitoring') {
        this.setSelectedStateGeofences();
      }
    },
    checkedGeofences (n, o) {
      this.$emit('change-checked-geofences', n);
    },
    checkedGeofencesDel (n, o) {
      // console.debug(n);
      this.$emit('change-checked-geofences-del', n);
    },
    foundGeofences (n) {
      this.foundGeofencesId = n.map(item => item.Id);
      this.selectedFoundGeofences = this.checkedGeofences.filter(item => this.foundGeofencesId.includes(item));
    },
    foundGeofencesDel (n) {
      this.foundGeofencesDelId = n.map(item => item.Id);
      this.selectedFoundGeofencesDel = this.checkedGeofencesDel.filter(item => this.foundGeofencesDelId.includes(item));
    },
    openStatusGroups (n) {
      this.setOpenStatusGroups(n);
    },
  },
  mounted () {
    // console.debug('mounted geofence list');
    this.foundGeofencesId = this.viewGeofence === 3 ? this.foundGeofencesDel.map(item => item.Id) : this.foundGeofences.map(item => item.Id);
    if (this.viewGeofence === 0 && this.variantList === 'outMonitoring') {
      // this.checkedGeofences = this.checkedGeofencesOutMonitoring.filter(id => this.selectedGeofences.find(item => item.id === id));
      this.checkedGeofences = this.checkedGeofencesOutMonitoring;
      this.setSelectedStateGeofences();
    }
    this.selectedFoundGeofences = this.viewGeofence === 3 ? this.checkedGeofencesDel.filter(id => this.foundGeofencesDelId.includes(id)) : this.checkedGeofences.filter(id => this.foundGeofencesId.includes(id));
  },
  updated () {
    // console.debug('ListGroupsGeofences updated');
    if (this.$refs.virtualScroll) {
      this.virtualScrollSliceRange = this.$refs.virtualScroll.virtualScrollSliceSizeComputed.total;
      this.setScrollPosition();
    }
  },
  activated () {
    // console.debug('ListGroupsGeofences activated');
    if (this.$refs.virtualScroll) {
      this.virtualScrollSliceRange = this.$refs.virtualScroll.virtualScrollSliceSizeComputed.total;
      this.setScrollPosition();
    }
  },
  // deactivated () {
    // console.debug('ListGroupsGeofences deactivated');
  // },
  beforeDestroy () {
    // console.debug('ListGroupsGeofences beforeDestroy');
    this.setScrollPositionOutMonitoring(0);
  },
  methods: {
    ...mapMutations('listGroupsGeofences', ['setScrollPositionMonitoring', 'setScrollPositionOutMonitoring']),
    setCheckedGeofences (arr) {
      if (this.viewGeofence !== 3) {
        this.checkedGeofences = arr;
        this.selectedFoundGeofences = arr;
      } else {
        this.checkedGeofencesDel = arr;
        this.selectedFoundGeofencesDel = arr;
      }
    },
    setScrollPosition () {
      if (this.variantList === 'monitoring' && this.foundGeofences.length + this.virtualScrollSliceRange >= this.scrollPositionMonitoring && !this.clickCheckboxFlag) {
        this.$refs.virtualScroll.scrollTo(this.scrollPositionMonitoring, 'start-force');
      } else if (this.variantList === 'outMonitoring' && this.foundGeofences.length + this.virtualScrollSliceRange >= this.scrollPositionOutMonitoring && !this.clickCheckboxFlag) {
        this.$refs.virtualScroll.scrollTo(this.scrollPositionOutMonitoring, 'start-force');
      }
      this.clickCheckboxFlag = false;
    },
    scrolled (e) {
      if (this.variantList === 'monitoring' && this.$refs.virtualScroll) {
        this.setScrollPositionMonitoring(e.index);
        this.shiftSelectCheckboxes();
      } else if (this.variantList === 'outMonitoring' && this.$refs.virtualScroll) {
        this.setScrollPositionOutMonitoring(e.index);
        this.shiftSelectCheckboxes();
      }
    },
    sortingCheckedGeofence (sortArr) {
      sortArr.sort((a, b) => {
        if (a.value.selected === b.value.selected) {
          return 0;
        }
        if (a.value.selected) {
          return -1;
        }
        return 1;
      });
    },
    setSelectedStateGeofences () {
      if (this.viewGeofence !== 3) {
        if (this.checkedGeofences.length !== 0) {
          this.currentList.forEach((item) => {
            // eslint-disable-next-line
            item.value.selected = this.checkedGeofences.includes(item.value.id);
          });
          this.sortingCheckedGeofence(this.currentList);
        }
      } else {
        // eslint-disable-next-line
        if (this.checkedGeofencesDel.length !== 0) {
          this.currentList.forEach((item) => {
            // eslint-disable-next-line
            item.value.selected = this.checkedGeofencesDel.includes(item.value.id);
          });
          this.sortingCheckedGeofence(this.currentList);
        }
      }
    },
    shiftSelectCheckboxes () {
      const tabContent = document.querySelector('.list-groups-tabs > .tab-content');
      const pointSearch = tabContent ?? document;
      const checkboxes = Array.from(pointSearch.querySelectorAll('.list-groups-checkbox > .custom-control-input'));
      checkboxes.forEach((item) => {
        item.addEventListener('click', (e) => {
          this.clickCheckboxFlag = true;
          const currentListWithoutGroups = this.currentList.filter(_item => !_item.group);
          // console.debug(currentListWithoutGroups);
          const index = currentListWithoutGroups.findIndex(_item => _item.value.Id === Number(e.target.value));
          if (!this.lastChecked) {
            this.lastChecked = {
              geofence: e.target,
              index,
            };
          }
          if (e.shiftKey) {
            const start = index;
            const end = this.lastChecked.index;
            if (start === end) {
              return;
            }

            const dedicatedCheckboxes = currentListWithoutGroups.map(checkbox => checkbox.value.Id).slice(Math.min(start, end), Math.max(start, end) + 1);
            if (e.target.checked) {
              if (this.viewGeofence !== 3) {
                dedicatedCheckboxes.forEach((checkbox) => {
                  this.checkedGeofences.push(checkbox);
                  this.selectedFoundGeofences.push(checkbox);
                });
              } else {
                dedicatedCheckboxes.forEach((checkbox) => {
                  this.checkedGeofencesDel.push(checkbox);
                  this.selectedFoundGeofencesDel.push(checkbox);
                });
              }
            } else {
              // eslint-disable-next-line
              if (this.viewGeofence !== 3) {
                this.checkedGeofences = this.checkedGeofences.filter(id => !dedicatedCheckboxes.find(checkbox => checkbox === id));
                this.selectedFoundGeofences = this.selectedFoundGeofences.filter(id => !dedicatedCheckboxes.find(checkbox => checkbox === id));
              } else {
                this.checkedGeofencesDel = this.checkedGeofencesDel.filter(id => !dedicatedCheckboxes.find(checkbox => checkbox === id));
                this.selectedFoundGeofencesDel = this.selectedFoundGeofencesDel.filter(id => !dedicatedCheckboxes.find(checkbox => checkbox === id));
              }
            }
          }
          this.lastChecked = {
            geofence: e.target,
            index,
          };
        });
      });
    },
    setOpenStatusGroups (openStatusGroups) {
      this.currentList.forEach((item) => {
        if (item.group !== undefined) {
          if (openStatusGroups) {
            // eslint-disable-next-line
            item.group.Open = true;
          } else {
            // eslint-disable-next-line
            item.group.Open = false;
          }
        }
      });
    },
    visibleGeofence (id) {
      const item = this.geofences.find(x => x.Id === id);
      item.Visible = !item.Visible;
      if (item.Visible) {
        this.$store.dispatch('geofences/selectItems', [id]);
      } else {
        this.$store.dispatch('geofences/unselectItems', [id]);
      }
      this.sharedGroupsGeofences.forEach((_group) => {
        const group = _group;
        const value = group.Geofences.find(x => x.Id === id);
        if (value) {
          if (!item.Visible && group.Geofences.filter(x => x.Visible).length === 0) {
            group.Visible = false;
          } else {
            group.Visible = true;
          }
        }
      });
      this.privateGroupsGeofences.forEach((_group) => {
        const group = _group;
        const value = group.Geofences.find(x => x.Id === id);
        if (value) {
          if (!item.Visible && group.Geofences.filter(x => x.Visible).length === 0) {
            group.Visible = false;
          } else {
            group.Visible = true;
          }
        }
      });
    },
    restoreGeofence (id) {
      this.$emit('restore-geofence', id);
    },
    deleteGeofence (id) {
      this.$emit('delete-geofence', id);
    },
    updateGeofence (id, index) {
      this.$emit('update-geofence', id, index);
    },
    // updateGeofence (id = null, position) {
    //   const item = this.items.find(x => x.Id === id);
    //   if (item) {
    //     this.geofence = {
    //       Id: item.Id,
    //       Name: item.Name,
    //       Description: item.Description,
    //       AddressSource: item.AddressSource,
    //       Radius: item.Radius,
    //       LineColor: {
    //         hex: item.LineColor,
    //       },
    //       LineWidth: item.LineWidth,
    //       FillColor: {
    //         hex: item.FillColor,
    //       },
    //       TextLineColor: {
    //         hex: item.TextLineColor,
    //       },
    //       TextFillColor: {
    //         hex: item.TextFillColor,
    //       },
    //       TextSize: item.TextSize,
    //       GeometryType: this.convertTypeGeofence(item.GeometryType),
    //       deleteDateVisible: item.DeleteDate != null,
    //       UpdateDate: item.UpdateDate ? new Date(item.UpdateDate * 1000).toLocaleString('ru-RU') : null,
    //       DeleteDate: item.DeleteDate ? new Date(item.DeleteDate * 1000) : null,
    //       Area: item.Area,
    //       Perimeter: item.Perimeter,
    //       OriginWKB: item.OriginWKB,
    //     };
    //
    //     const wkb = this.base64ToHex(item.OriginWKB);
    //     const intAry = [];
    //     wkb.match(/.{2}/g).forEach(i => intAry.push(parseInt(i, 16)));
    //     const geometry = wkx.Geometry.parse(buffer.Buffer.from(intAry));
    //     this.setUpdateGeofence(geometry.toGeoJSON());
    //   } else {
    //     this.clearFormGeofence();
    //   }
    //   this.setActiveGeofenseEdit(id, position);
    //
    //   this.editGeofence(true);
    // },
    getTypeIcon (type) {
      let icon;
      switch (type) {
        case 'POINT':
          icon = 'geo-alt';
          break;
        case 'POLYGON':
          icon = 'bounding-box-circles';
          break;
        case 'LINESTRING':
          icon = 'share';
          break;
        case 'MULTILINESTRING':
          icon = 'list';
          break;
        default:
          icon = 'x';
          break;
      }
      return icon;
    },
    // onCheckedGeofence (id, selected) {
    //   if (!selected) {
    //     if (!this.selectedGeofences.find(x => x === id)) {
    //       this.selectedGeofences.push(id);
    //     }
    //   } else {
    //     this.selectedGeofences = this.selectedGeofences.filter(x => x !== id);
    //   }
    //   this.sharedGroupsGeofences.forEach((_group) => {
    //     const group = _group;
    //     const item = group.Geofences.find(x => x.Id === id);
    //     if (item) {
    //       if (!selected && group.Geofences.length === group.Geofences.filter(x => x.Selected).length + 1) {
    //         group.Selected = true;
    //       } else {
    //         group.Selected = false;
    //       }
    //     }
    //   });
    //   this.privateGroupsGeofences.forEach((_group) => {
    //     const group = _group;
    //     const item = group.Geofences.find(x => x.Id === id);
    //     if (item) {
    //       if (!selected && group.Geofences.length === group.Geofences.filter(x => x.Selected).length + 1) {
    //         group.Selected = true;
    //       } else {
    //         group.Selected = false;
    //       }
    //     }
    //   });
    // },
    onCheckedGeofence (checked) {
      // console.debug('onCheckedGeofence');
      const uniqSet = new Set(this.checkedGeofences);
      this.checkedGeofences = [...uniqSet];
      this.selectedFoundGeofences = this.checkedGeofences.filter(item => this.foundGeofencesId.includes(item));
      this.$emit('on-checked-geofence', checked);
    },
    onCheckedGeofenceDel () {
      // console.debug('onCheckedGeofenceDel');
      const uniqSet = new Set(this.checkedGeofencesDel);
      this.checkedGeofencesDel = [...uniqSet];
      this.selectedFoundGeofencesDel = this.checkedGeofencesDel.filter(item => this.foundGeofencesDelId.includes(item));
      // this.$emit('on-checked-geofence-del', checked);
    },
    setActiveGeofence (item) {
      this.$emit('set-active-geofence', item);
    },
    async setTooltype (target, data) {
      this.hoverObject.show = false;
      this.hoverObject.target = target;
      this.hoverObject.data = data;
      await this.$nextTick();
      this.hoverObject.show = true;
    },
    visibleGeofenceGroup (_group) {
      const group = _group;
      group.Visible = !group.Visible;
      // const visible = group.Visible;
      group.Geofences.forEach((_item) => {
        const item = _item;
        item.Visible = group.Visible;
      });
      // if (visible) {
      //   this.$store.dispatch('geofences/selectItems', group.Geofences.map(x => x.Id));
      // } else {
      //   this.$store.dispatch('geofences/unselectItems', group.Geofences.map(x => x.Id));
      // }
      const type = group.Visible ? 'selectItems' : 'unselectItems';
      this.$store.dispatch(`geofences/${type}`, group.Geofences.map(x => x.Id));
    },
    // visibleObjectGroup (_group) {  //  из мониторинга!!!
    //   const group = _group;
    //   group.Visible = !group.Visible;
    //
    //   const type = group.Visible ? 'visibleItems' : 'unvisibleItems';
    //   this.$store.dispatch(`objects/${type}`, group.Objects.map(x => x.id));
    // },
    deleteGeofenceGroupPrivate (id, accountingUnit) {
      this.$store.dispatch('privategeo/deletedPrivateGeofencesGroups', { id, accountingUnit });
    },
    deleteGeofenceGroup (id, accountingUnit) {
      this.$store.dispatch('sharedgeo/deletedSharedGeofencesGroups', { id, accountingUnit });
    },
    modalEditGroup (id, name, accountingUnit) {
      this.$store.dispatch('sharedgeo/updatedSharedGeofencesGroups', { id, group: { Name: name }, accountingUnit });
    },
    modalEditGroupPrivate (id, name, accountingUnit) {
      this.$store.dispatch('privategeo/updatedPrivateGeofencesGroups', { id, group: { Name: name }, accountingUnit });
    },
    filterGroup (geofences) {
      const vm = this.geofenceToFind;
      return geofences.filter(item => item.Name.toLowerCase().includes(vm.toLowerCase()) || item.Id.toString().includes(vm.toLowerCase()));
    },
    // onCheckedGeofenceGroup (_group) {
    //   const group = _group;
    //   group.Selected = !group.Selected;
    //   const selected = group.Selected;
    //   group.Geofences.forEach((_item) => {
    //     const item = _item;
    //     item.Selected = selected;
    //     if (selected) {
    //       if (!this.selectedGeofences.find(id => id === item.Id)) {
    //         this.selectedGeofences.push(item.Id);
    //       }
    //     } else {
    //       this.selectedGeofences = this.selectedGeofences.filter(x => x !== item.Id);
    //     }
    //   });
    // },
    onCheckedGeofenceGroup (group, selected) {
      // console.debug('изменилось состояние группы');
      const geo = group.Geofences.map(v => v.Id).filter(item => this.foundGeofencesId.includes(item));
      if (selected) {
        this.checkedGeofences.push(...geo);
        this.checkedGeofences = [...new Set(this.checkedGeofences)];
        this.selectedFoundGeofences.push(...geo);
        this.selectedFoundGeofences = [...new Set(this.selectedFoundGeofences)];
      } else {
        this.checkedGeofences = this.checkedGeofences.filter(id => !geo.includes(id));
        this.selectedFoundGeofences = this.selectedFoundGeofences.filter(id => !geo.includes(id));
      }
      this.$emit('change-checked-geofences', this.checkedGeofences);
    },
    // checkGroupSelectIndeterminate (group) {
    //   const geo = group.Geofences.map(v => v.Selected);
    //   const selected = geo.filter(v => v);
    //   return selected.length > 0 && selected.length < geo.length;
    // },
    checkGroupSelectIndeterminate (group) {
      const geo = group.Geofences.map(v => v.Id);
      const selected = geo.filter(id => this.checkedGeofences.includes(id));
      return selected.length > 0 && selected.length < geo.length;
    },
    visibleAllGeofences () {
      this.allVisible = !this.allVisible;
      // const visible = this.allVisible;
      this.geofences.forEach((_item) => {
        const item = _item;
        item.Visible = this.allVisible;
      });
      // if (visible) {
      //   this.$store.dispatch('geofences/selectItems', this.geofences.map(x => x.Id));
      // } else {
      //   this.$store.dispatch('geofences/unselectItems', this.geofences.map(x => x.Id));
      // }
      const type = this.allVisible ? 'selectItems' : 'unselectItems';
      this.$store.dispatch(`geofences/${type}`, this.geofences.map(x => x.Id));
    },
    toggleAllSelectedFoundGeofences (checked) {
      if (this.viewGeofence !== 3) {
        this.selectedFoundGeofences = checked ? this.foundGeofencesId.slice() : [];
        if (checked) {
          this.selectedFoundGeofences.forEach((item) => {
            this.checkedGeofences.push(item);
          });
        } else {
          this.checkedGeofences = this.checkedGeofences.filter(item => !this.foundGeofencesId.find(_item => _item === item));
        }
        const uniqSet = new Set(this.checkedGeofences);
        this.checkedGeofences = [...uniqSet];
      } else {
        this.selectedFoundGeofencesDel = checked ? this.foundGeofencesDelId.slice() : [];
        if (checked) {
          this.selectedFoundGeofencesDel.forEach((item) => {
            this.checkedGeofencesDel.push(item);
          });
        } else {
          this.checkedGeofencesDel = this.checkedGeofencesDel.filter(item => !this.foundGeofencesDelId.find(_item => _item === item));
        }
        const uniqSet = new Set(this.checkedGeofencesDel);
        this.checkedGeofencesDel = [...uniqSet];
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
  .position-button__hide-search {
    position: absolute;
    top: 55px;
    left: 92%;
  }
  .position-button__show-search {
    position: absolute;
    top: 103px;
    left: 92%;
  }
  .geofences-list--name {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .btn {
    padding: 0.375rem 0.375rem;
  }
  .text {
    padding: 0px 4px;
  }
  .modal-btn .btn-sm {
    width: 50%;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    padding: 5px;
    border-radius: 3px;
  }
</style>
