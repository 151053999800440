/* eslint-disable class-methods-use-this,no-underscore-dangle */
class AddressState {
  constructor (rawState = {}) {
    this._isDefault = this.checkIsEmpty(rawState);
    this.setState(rawState);
  }

  checkIsEmpty (state) {
    return !!Object.keys(state).length;
  }

  setState (state, init) {
    this.isReversed = state.isReversed ?? false;
    this.isStub = state.isStub ?? false;
  }
}

export default AddressState;
