export const state = () => ({
  activityTypesByKey: {},
  activityTypesByValue: {},
});

export const mutations = {
  setActivityTypes (state, data = []) {
    state.activityTypesByKey = {};
    state.activityTypesByValue = {};
    data.forEach((x) => {
      state.activityTypesByKey[x.Key] = x.Value;
      state.activityTypesByValue[x.Value] = x.Key;
    });
  },
};

export const getters = {};

export const actions = {
  init ({ commit }) {
    this.$api.$get('/Frontend/UserActivityTypes').then(data => commit('setActivityTypes', data));
  },

  track ({ state }, { idOrType, reportText }) {
    const id = typeof idOrType === 'number' ? idOrType : state.activityTypesByValue[idOrType];
    if (id in state.activityTypesByKey) {
      this.$api.post('/Frontend/UserActivity', {
        activityType: id,
        description: reportText,
      });
    }
  },
};
