<template lang="pug">
  .map-layout(:style="{ '--width': size.width, '--height': size.height}")
    l-map(ref="rootMap",
      v-bind="mapConfig",
      :crs="crs",
      :center.sync="center",
      :zoom.sync="zoom",
      @ready="edgeLayerEnabled && addEdgeLayer()",
      @mousemove="$options.mousemove",
      @mouseover="mouseover()",
      @mouseout="mouseout()",
      @movestart="mapMove(true)",
      @moveend="mapMove(false)",
      @click="mapClick",
      @keydown="mapKeyDown")
      l-control-attribution(position="bottomright", :prefix="positionOnMap")
      le-layers(:gmapsConfig="gmapsConfig" :yandexConfig="yandexConfig")
      leaflet-heatmap(v-if="visibleHeatmap" :lat-lng="latLngHeatmap" :max="1" :radius="settingsHeatmap.radius" :blur="settingsHeatmap.blur")
      le-main-objects
      l-control-zoom(position="topright", :zoom-in-title="$t('map.zoomIn')", :zoom-out-title="$t('map.zoomOut')")
      l-tile-layer(v-if="(countGeofence !== 0 || geofencesLoad) && visibleGeofenceLayer1", :url="urlGeofence1", name="Geofence", layer-type="overlay", :z-index="220", @load="load('1')", :options="{ maxNativeZoom: 19, maxZoom: 21 }")
      l-tile-layer(v-if="(countGeofence !== 0 || geofencesLoad) && visibleGeofenceLayer2", :url="urlGeofence2", name="Geofence", layer-type="overlay", :z-index="220", @load="load('2')", :options="{ maxNativeZoom: 19, maxZoom: 21 }")
      l-tile-layer(
        v-if="c_showTracks && tracks && settingsAUnit.renderingMode === 0",
        :url="tracks",
        name="Track",
        layer-type="overlay",
        :z-index="250",
        :options="{ maxNativeZoom: 19, maxZoom: 21 }")
      client-tracks(@client-track-click="mapClick")
      l-feature-group(ref="features")
        l-popup#list-popup(:options="popup")
          .popover-wrapper.popover-body
            div(v-if="dataPoints.Tracks.length > 0")
              .popover-title.d-flex.align-items-center.justify-content-between.p-2
                span Треки ({{ dataPoints.Tracks.length }})
                a.close(href="#", @click="closePopup")
                  b-icon(icon="x")
              recycle-scroller.geofences-block.list-group.list-group-flush#tracks(:items="dataPoints.Tracks", v-slot="{ item, index }", scrollable, key-field="id",
              :buffer="200", :item-size="itemSize")
                b-list-group-item.btn-sm.border-bottom-0(button, @click="goToTrackRow(item)")
                  .d-flex.w-100.align-items-center
                    .track-list--name.w-100.d-flex.pr-2.align-items-center
                      b-icon.mr-2(icon="geo-alt-fill", :style="{ color: item.color }")
                      span.mr-2.track-list--name {{ new Date(item.Timestamp * 1000).toLocaleString() }}
                      span.mr-2.track-list--speed(v-if="item.speed !== null && item.speed !== undefined") {{  item.speed + ' км/ч' }}
                      b-badge.track-list--badge(v-if="isAdmin") {{item.name}}
            div(v-if="dataPoints.Geofences.length > 0")
              .popover-title.d-flex.align-items-center.justify-content-between.p-2
                span Геозоны ({{ dataPoints.Geofences.length }})
                a.close(href="#", @click="closePopup", v-if="dataPoints.Tracks.length === 0")
                  b-icon(icon="x")
              recycle-scroller.geofences-block.list-group.list-group-flush#geofences(:items="dataPoints.Geofences", v-slot="{ item, index }", scrollable, key-field="Id",
              :buffer="200", :item-size="itemSize")
                b-list-group-item.btn-sm.border-bottom-0(button, @click="")
                  .d-flex.w-100.align-items-center
                    .geofences-list--name.w-100.d-flex.pr-2.align-items-center
                      span.geofences-list--name {{ item.Name }}
                      b-badge.ml-1(v-if="isAdmin") {{item.AccountingUnitName}}
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import MiniMap from 'leaflet-minimap';
import moment from 'moment';
import 'moment/locale/ru';
import L from 'leaflet';
import ClientTracks from '@/components/ClientTracks';
import LeafletHeatmap from '../Vue2LeafletHeatmap';
import LeMainObjects from './Objects';
import LeLayers from '../Layers';
import '../../../plugins/edgeMarker/Leaflet.EdgeMarker';
import { PERIODIC_SERVER_POLLING_EVENTS } from '~/consts/eventTypes';

export default {
  name: 'LeMainMap',
  components: {
    LeLayers,
    LeafletHeatmap,
    'le-main-objects': LeMainObjects,
    'client-tracks': ClientTracks,
  },
  data () {
    return {
      // map options
      mapConfig: {
        minZoom: 3,
        options: {
          worldCopyJump: true,
          zoomControl: false,
          boxZoom: false,
          preferCanvas: true,
          attributionControl: false,
          zoomAnimation: false,
          markerZoomAnimation: false,
          fadeAnimation: false,
        },
      },
      itemSize: 30,
      cursorPosition: {
        lat: 0,
        lng: 0,
      },
      currentCursorPosition: {
        lat: 0,
        lng: 0,
      },
      mapMoved: false,
      autoView: false,

      gmapsConfig: {
        key: 'AIzaSyCmHXstzu1a3lEYWvUYKrnEI9zpqYc1cU8',
      },
      yandexConfig: {
        key: '8f967cef-251f-4cd8-be3f-668b76933b63',
      },
      editableLayers: null,
      drawControl: null,
      type: 'marker',
      layers: [],
      layerMarker: null,
      geofence: {
        geometry: {
          type: 'MultiLineString',
          coordinates: [],
        },
      },
      miniMap: null,
      paramsMiniMap: {
        width: 280,
        height: 180,
        toggleDisplay: false,
        zoomLevelOffset: -3,
      },
      popup: {
        className: 'mapobj-popup',
        autoPan: false,
        closeButton: false,
        keepInView: true,
        offset: this.$L.point(0, -15),
        minWidth: 420,
      },
      layerEditDraw: null,
      editableLayerMap: null,
      circle: null,
      polygon: null,
      dataPoints: {
        Geofences: [],
        Tracks: [],
      },
      visibleGeofenceLayer1: false,
      visibleGeofenceLayer2: false,
      geofencesLoad: true,
      urlGeofence1: '',
      urlGeofence2: '',
      blockBetweenChangeSessions: false,
      timerForMouseMoveEnd: null,
      stopTimerForMouseMoveEnd: false,
      editShape: null,
      previousEditShape: null,
      tooltipDelete: null,
      edgeMarkerLayer: null,
      pointsConvexGeofence: [],
      pointHoverParking: null,
    };
  },
  computed: {
    ...mapGetters('user', ['requireNewSession']),
    ...mapGetters('geofences', ['pointByCoordinates', 'countGeofence']),
    ...mapGetters('heatmap', ['latLngHeatmap', 'settingsHeatmap', 'visibleHeatmap']),
    ...mapGetters('tracks', ['arrItems', 'visibleTracks', 'hoverParking', 'activeTrack', 'selectedPeriods']),
    ...mapGetters('reporting', ['reports']),
    ...mapGetters('preferences', ['settingsMap', 'geofencesSelectedIds', 'accountingUnitGeofences', 'settingsAUnit']),
    ...mapGetters('map', ['crs', 'breakSetCursorPosition']),
    ...mapState('map', ['size', 'resolveAddMarkers', 'bounds']),
    ...mapState('user', ['ssid']),
    ...mapState('geofences', { geofenceVersion: 'v', geofencesIds: 'geofencesVisibleIds', openGeofencesSidebar: 'openGeofencesSidebar' }),
    ...mapState('tracks', { trackVersion: 'v', marker: 'activePoint', activeTrackReport: 'activeTrackReport', clientTrackPeriods: 'clientTrackPeriods' }),
    positionOnMap () {
      return `${this.cursorPosition.lat.toString().slice(0, 10)} : ${this.cursorPosition.lng.toString().slice(0, 10)}`;
    },
    c_showTracks () {
      return this.visibleTracks && (!this.requireNewSession && !this.blockBetweenChangeSessions) && this.trackVersion > 0;
    },
    simplifiedEventBus () {
      return this.$eventBus.simplify('Map');
    },
    geofences () {
      return `${process.env.TRACK_PATTERN}/tiles/g${this.ssid}/{z}/{x}/{y}.png?v=${this.geofenceVersion}`;
    },
    tracks () {
      return `${process.env.TRACK_PATTERN}/tiles/t${this.ssid}/{z}/{x}/{y}.png?v=${this.trackVersion}`;
    },
    center: {
      get () {
        return this.$store.state.map.center;
      },
      set (value) {
        this.$store.commit('map/setCenter', value);
      },
    },
    zoom: {
      get () {
        return this.$store.state.map.zoom;
      },
      set (value) {
        this.$store.commit('map/setZoom', value);
      },
    },
    map () {
      return this.$refs.rootMap ? this.$refs.rootMap.mapObject : null;
    },
    geojson () {
      return this.$store.state.geofences.geoJson;
    },
    editing () {
      return this.$store.state.geofences.editing;
    },
    geoOptions () {
      return this.$store.state.geofences.optionsGeo;
    },
    overViewMapState () {
      return this.settingsMap.overViewMapState;
    },
    area () {
      return this.settingsMap.showAreaDraw;
    },
    ruler () {
      return this.settingsMap.showRulerDraw;
    },
    edgeLayerEnabled () {
      return this.settingsMap.showMarkersOnMapEdge;
    },
    isAdmin () {
      return this.$store.state.user.data ? this.$store.state.user.data.isAdmin : false;
    },
    isPageWithTracks () {
      return this.$route.name === 'tracks' || this.$route.name === 'reporting' || this.$route.name === 'geofences';
    },
    getObjectNameHoverParking () {
      const isExistTrack = this.arrItems.find(item => item.id === this.hoverParking.Id);
      return isExistTrack ? isExistTrack.name
        : this.reports.map(_item => _item.Reports.map(report => report.Details).flat()).flat().find(detail => detail.track === this.hoverParking.Id).ObjectName;
    },
  },
  watch: {
    selectedPeriods () {
      this.closePopup();
    },
    hoverParking: {
      handler (n) {
        if (n) {
          if (this.pointHoverParking) {
            this.map.removeLayer(this.pointHoverParking);
          }
          // eslint-disable-next-line
          this.pointHoverParking = new this.$L.marker(this.$L.latLng(this.hoverParking.Latitude, this.hoverParking.Longitude), {
            opacity: 0,
            icon: L.icon({
              iconUrl: '/icon.png',
              iconSize: [30, 30],
            }),
          });
          this.pointHoverParking.bindTooltip(`<div style="text-transform: uppercase">${this.hoverParking.Type === 'Parking' ? 'Стоянка' : 'Остановка'}</div>
                                    <div>Имя объекта: ${this.getObjectNameHoverParking}</div>
                                    <div>Дата: ${moment(this.hoverParking.Timestamp * 1000).format('LLL')}</div>
                                    <div>Длительность: ${this.hoverParking.Duration}</div>`, { offset: this.$L.point(15, 0) }).openTooltip();
          this.map.addLayer(this.pointHoverParking);
        } else if (this.pointHoverParking) {
          this.map.removeLayer(this.pointHoverParking);
        }
      },
      deep: true,
    },
    size (n, o) {
      setImmediate(this.mapRefresh);
    },
    // eslint-disable-next-line func-names
    '$route.name': function () {
      this.closePopup();
    },
    tracks (next, prev) {
      const versionRegexp = /\?v=\d+&{0,1}/;
      const match = prev.match(versionRegexp) || [];

      const prevVersion = match[0] || '';
      const currentVersion = `?v=${this.trackVersion}`;
      const isSameVersion = prevVersion.includes(currentVersion);

      this.blockBetweenChangeSessions = isSameVersion;
    },
    zoom (value) {
      if (this.polygon) {
        this.polygon.setStyle({ weight: this.getPixelForMetres(this.geoOptions.radius * 2) });
      }
    },
    edgeLayerEnabled (to) {
      if (to) {
        this.addEdgeLayer();
      } else {
        this.removeEdgeLayer();
      }
    },
    geofencesIds (ids) {
      if (this.geofencesLoad) {
        this.geofencesLoad = false;
      }
      this.$store.dispatch('geofences/getLayerGeofences', ids);
    },
    countGeofence (count) {
      if (count === 0) {
        this.visibleGeofenceLayer1 = false;
        this.visibleGeofenceLayer2 = false;
      }
    },
    geofenceVersion (v) {
      if (this.countGeofence > 0 || this.geofencesLoad) {
        if (v % 2) {
          this.urlGeofence1 = this.geofences;
          this.visibleGeofenceLayer1 = true;
        } else {
          this.urlGeofence2 = this.geofences;
          this.visibleGeofenceLayer2 = true;
        }
      }
    },
    geojson: {
      handler (geoJson) {
        // console.debug(this.$L.geoJSON(geoJson));
        const map = this.$refs.rootMap.mapObject;
        if (this.circle) {
          this.circle.removeFrom(map);
          this.circle = null;
        }
        if (this.polygon) {
          this.polygon.removeFrom(map);
          this.polygon = null;
        }
        this.layers.forEach((layer) => {
          this.editableLayers.removeLayer(layer);
          layer.removeFrom(map);
        });
        this.layers = [];
        if (!geoJson) {
          if (this.editShape) {
            this.editShape.disable();
            this.editShape = null;
          }
          this.previousEditShape = null;
          this.removeTooltipDelete();
        } else {
          const layer = this.$L.geoJSON(geoJson);
          layer.eachLayer((_l) => {
            const l = _l;

            l.options.color = this.geoOptions.lineColor;
            l.options.fillColor = this.geoOptions.fillColor;
            l.options.weight = this.geoOptions.lineWidth;
            if (this.geoOptions.name) {
              l.bindPopup(`<div style="font-weight:bold;">${this.geoOptions.name}</div>`);
            }
            this.getToolTip(l, l.feature.geometry.type);
            this.editableLayers.addLayer(l);
            this.layers.push(l);

            if (l.feature.geometry.type === 'Point') {
              this.circle = this.$L.circle(l.getLatLng(), { radius: this.geoOptions.radius }).addTo(map);
              map.setView(l.getLatLng());
            }

            if (l.feature.geometry.type === 'LineString' || l.feature.geometry.type === 'MultiLineString') {
              this.polygon = this.$L.polyline(l.getLatLngs(), { weight: this.getPixelForMetres(this.geoOptions.radius * 2), opacity: 0.3 }).addTo(map);
            }

            if (l.feature.geometry.type === 'Polygon') {
              this.polygon = this.$L.polygon(l.getLatLngs(), { weight: this.getPixelForMetres(this.geoOptions.radius * 2), opacity: 0.3, fill: false, 'fill-rule': 'evenodd' }).addTo(map);
            }
            if (l.feature.geometry.type !== 'Point') {
              map.fitBounds(layer.getBounds());
            }
          });
        }
      },
      deep: true,
    },
    geoOptions: {
      handler (value) {
        // console.debug(value);
        const map = this.$refs.rootMap.mapObject;
        this.layers.forEach((layer) => {
          this.editableLayers.removeLayer(layer);
          layer.removeFrom(map);
        });
        this.layers.forEach((_l) => {
          const l = _l;
          l.options.color = value.lineColor;
          l.options.fillColor = value.fillColor;
          l.options.weight = value.lineWidth;
          if (value.name) {
            l.bindPopup(`<div style="font-weight:bold;">${value.name}</div>`);
          }
          this.getToolTip(l, l.feature.geometry.type);
          this.editableLayers.addLayer(l);
          if (l.feature.geometry.type === 'Point') {
            this.circle.setRadius(value.radius);
          }

          if (l.feature.geometry.type === 'LineString' || l.feature.geometry.type === 'MultiLineString') {
            this.polygon.setStyle({ weight: this.getPixelForMetres(value.radius * 2) });
          }

          if (l.feature.geometry.type === 'Polygon') {
            this.polygon.setStyle({ weight: this.getPixelForMetres(value.radius * 2) });
          }
        });
      },
      deep: true,
    },
    marker (value) {
      // console.debug(value);
      const map = this.$refs.rootMap.mapObject;
      if (value === null && this.layerMarker) {
        this.editableLayers.removeLayer(this.layerMarker);
        this.layerMarker.removeFrom(map);
        this.layerMarker = null;
      }
      if (value) {
        if (!this.layerMarker) {
          this.layerMarker = this.$L.marker(value, { zIndexOffset: 1000 }).bindPopup('Точка трека!');
          this.editableLayers.addLayer(this.layerMarker);
        } else {
          this.layerMarker.setLatLng(value);
        }
      }
    },
    overViewMapState (value) {
      if (value) {
        // this.miniMap._restore();
        if (this.miniMap) {
          this.miniMap.remove();
        }
        this.miniMap = new MiniMap(this.$L.tileLayer('https://osm.logexpert.ru/tile/{z}/{x}/{y}.png', {}), this.paramsMiniMap).addTo(this.map);
      } else if (this.miniMap) {
        // this.miniMap._minimize();
        this.miniMap.remove();
        this.miniMap = null;
      }
    },
    area (edit) {
      if (this.layerEditDraw &&
        (((this.layerEditDraw.type === 'polyline' || this.layerEditDraw.type === 'LineString') && !this.ruler) ||
        ((this.layerEditDraw.type === 'polygon' || this.layerEditDraw.type === 'Polygon') && !edit))) {
        this.clearAreaLuner();
      }
      if (edit) {
        const map = this.$refs.rootMap.mapObject;
        this.layerEditDraw = new this.$L.Draw.Polygon(map, {
          showArea: true,
          showLength: true,
          metric: ['km', 'm'],
        });
        this.layerEditDraw.enable();
      }
    },
    ruler (edit) {
      if (this.layerEditDraw &&
      (((this.layerEditDraw.type === 'polygon' || this.layerEditDraw.type === 'Polygon') && !this.area) ||
      ((this.layerEditDraw.type === 'polyline' || this.layerEditDraw.type === 'LineString') && !edit))) {
        this.clearAreaLuner();
      }
      if (edit) {
        const map = this.$refs.rootMap.mapObject;
        this.layerEditDraw = new this.$L.Draw.Polyline(map, {
          showArea: true,
          showLength: true,
          metric: ['km', 'm'],
        });
        this.layerEditDraw.enable();
      }
    },
    'layers.length': {
      handler (n) {
        this.setCountLayers(n);
      },
    },
    pointByCoordinates: {
      handler (coords) {
        // console.debug('change pointByCoordinates');
        if (this.circle) {
          this.circle.removeFrom(this.map);
          this.circle = null;
        }
        const marker = new this.$L.Marker(coords).addTo(this.editableLayers);
        this.circle = new this.$L.Circle(coords, { radius: this.geoOptions.radius }).bindPopup(this.geoOptions.name ? this.geoOptions.name : '').addTo(this.editableLayers);
        this.layers.push(marker, this.circle);
        this.geofence.geometry.type = 'Point';
        this.geofence.geometry.coordinates = coords;
        this.$store.commit('geofences/setGeofencesJson', this.geofence.geometry);
      },
      deep: true,
    },
    openGeofencesSidebar (n) {
      if (!n && this.pointsConvexGeofence) {
        this.geofence.geometry.type = 'Polygon';
        this.geofence.geometry.coordinates = [this.pointsConvexGeofence];
        this.$store.commit('geofences/setGeofencesJson', this.geofence.geometry);
      }
    },
  },
  async beforeCreate () {
    const accountingUnitId = this.$store.state.user.data.isAdmin && this.$store.state.preferences.AUMap !== null ? this.$store.state.preferences.AUMap : this.$store.state.user.data.AccountingUnitId;
    await this.$store.dispatch('map/getBaseLayers', accountingUnitId);
    await this.$store.dispatch('map/setSettingsLayerMap', this.settingsMap.layerVisible);
  },
  created () {
    this.$options.mousemove = this._.throttle((ev) => {
      this.mousemove(ev);
    }, 100);
    this.$nuxt.$on('closeTrackPopup', this.closePopup);

    this.simplifiedEventBus.subscribe(PERIODIC_SERVER_POLLING_EVENTS.sessionIsOutdated, () => {
      this.isTracksHided = true;
    });
  },
  beforeDestroy () {
    this.simplifiedEventBus.unsubscribe(PERIODIC_SERVER_POLLING_EVENTS.sessionIsOutdated);
    !this.timerForMouseMoveEnd || clearTimeout(this.timerForMouseMoveEnd);
    this.$root.$off('cancelDeleting');
    this.$root.$off('saveDeleting');
    this.$root.$off('deleteCurrentDrawLayer');
    this.$root.$off('deleteAllDrawLayers');
    this.$root.$off('editCurrentDrawLayer');
    this.$root.$off('saveEditing');
    this.$root.$off('cancelEditing');
    this.removeEdgeLayer();
  },
  async mounted () {
    const language = this.$i18n.localeProperties.code;
    global.mainMap = this.map;
    let idsGeofences = await this.$store.dispatch('geofences/fetchAllItems', this.accountingUnitGeofences);
    idsGeofences = idsGeofences.map(geo => geo.Id);
    if (idsGeofences && idsGeofences.length > 0) {
      const ids = this.geofencesSelectedIds.filter(id => idsGeofences.includes(id));
      if (ids && ids.length > 0) {
        this.$store.dispatch('geofences/getLayerGeofences', ids);
      } else {
        this.geofencesLoad = false;
      }
    }

    // this.paramsMiniMap.minimized = !this.settingsMap.overViewMapState;
    if (this.settingsMap.overViewMapState) {
      this.miniMap = new MiniMap(this.$L.tileLayer('https://osm.logexpert.ru/tile/{z}/{x}/{y}.png', {}), this.paramsMiniMap).addTo(this.map);
    }
    this.$root.$on('cancelDeleting', () => {
      this.cancelDeleting();
    });
    this.$root.$on('saveDeleting', () => {
      this.saveDeleting();
    });
    this.$root.$on('deleteCurrentDrawLayer', () => {
      this.deleteLayer();
    });
    this.$root.$on('deleteAllDrawLayers', () => {
      this.deleteLayers();
    });
    this.$root.$on('editCurrentDrawLayer', () => {
      this.editLayer();
    });
    this.$root.$on('saveEditing', () => {
      this.saveEditing();
    });
    this.$root.$on('cancelEditing', () => {
      this.cancelEditing();
    });
    this.$nextTick(() => {
      const map = this.$refs.rootMap.mapObject;
      this.editableLayers = new this.$L.FeatureGroup().addTo(map);
      // global.editableLayers = this.editableLayers;
      this.editableLayerMap = new this.$L.FeatureGroup().addTo(map);
      // this.drawControl = new this.$L.Control.Draw({
      //   edit: {
      //     featureGroup: this.editableLayers,
      //   },
      //   position: 'bottomleft',
      //   draw: {
      //     polyline: true,
      //     polygon: true,
      //     marker: true,
      //   },
      // });
      // global.drawControl = this.drawControl;
      // const L = this.$L;
      // L.DrawToolbar.include({
      //   getModeHandlers (_map) {
      //     return [
      //       {
      //         enabled: true,
      //         handler: new L.Draw.Marker(_map),
      //         title: language === 'ru' ? 'Добавить точку' : 'Draw a marker',
      //       },
      //       {
      //         enabled: true,
      //         handler: new L.Draw.Polyline(_map, {
      //           showArea: true,
      //           showLength: true,
      //           metric: ['km', 'm'],
      //         }),
      //         title: language === 'ru' ? 'Добавить линию' : 'Draw a polyline',
      //       },
      //       {
      //         enabled: true,
      //         handler: new L.Draw.Polygon(_map, {
      //           showArea: true,
      //           showLength: true,
      //           metric: ['km', 'm'],
      //         }),
      //         title: language === 'ru' ? 'Добавить геозону' : 'Draw a polygon',
      //       },
      //     ];
      //   },
      // });

      // console.debug(L.drawLocal);
      // console.debug(this.$i18n.localeProperties.code);
      if (language === 'ru') {
        // L.drawLocal.draw.toolbar.buttons.marker = 'Завершить';
        // L.drawLocal.draw.toolbar.buttons.polygon = 'Завершить отрисовку';
        // L.drawLocal.draw.toolbar.buttons.polyline = 'Удалить последнюю точку';
        this.$L.drawLocal.draw.toolbar.finish.text = 'Завершить';
        this.$L.drawLocal.draw.toolbar.finish.title = 'Завершить отрисовку';
        this.$L.drawLocal.draw.toolbar.undo.text = 'Удалить последнюю точку';
        this.$L.drawLocal.draw.toolbar.undo.title = 'Удалить последнюю точку фигуры';
        this.$L.drawLocal.draw.toolbar.actions.text = 'Отмена';
        this.$L.drawLocal.draw.toolbar.actions.title = 'Отменить отрисовку';

        this.$L.drawLocal.draw.handlers.marker.tooltip.start = 'Нажмите на карту, чтобы разместить маркер';
        this.$L.drawLocal.draw.handlers.polygon.tooltip.cont = 'Нажмите, чтобы продолжить рисование фигуры.';
        this.$L.drawLocal.draw.handlers.polygon.tooltip.end = 'Щелкните первую точку, чтобы закрыть эту фигуру.';
        this.$L.drawLocal.draw.handlers.polygon.tooltip.start = 'Нажмите, чтобы начать рисовать фигуру.';
        this.$L.drawLocal.draw.handlers.polyline.error = '<strong>Ошибка:</strong> края формы не могут пересекаться!';
        this.$L.drawLocal.draw.handlers.polyline.tooltip.cont = 'Нажмите, чтобы продолжить рисование линии.';
        this.$L.drawLocal.draw.handlers.polyline.tooltip.end = 'Нажмите последнюю точку, чтобы закончить линию.';
        this.$L.drawLocal.draw.handlers.polyline.tooltip.start = 'Нажмите, чтобы начать рисовать линию.';

        this.$L.drawLocal.edit.handlers.edit.tooltip.subtext = 'Нажмите «Отмена», чтобы отменить изменения.';
        this.$L.drawLocal.edit.handlers.edit.tooltip.text = 'Перетащите маркеры для редактирования объектов.';
        this.$L.drawLocal.edit.handlers.remove.tooltip.text = 'Нажмите на фигуру, которую нужно удалить.';

        this.$L.drawLocal.edit.toolbar.actions.cancel.title = 'Отменить редактирование, отменить все изменения';
        this.$L.drawLocal.edit.toolbar.actions.cancel.text = 'Отмена';
        this.$L.drawLocal.edit.toolbar.actions.clearAll.title = 'Очистить всю геометрию';
        this.$L.drawLocal.edit.toolbar.actions.clearAll.text = 'Очистить всё';
        this.$L.drawLocal.edit.toolbar.actions.save.title = 'Сохранить изменения';
        this.$L.drawLocal.edit.toolbar.actions.save.text = 'Сохранить';
        this.$L.drawLocal.edit.toolbar.buttons.edit = 'Редактировать геометрию';
        this.$L.drawLocal.edit.toolbar.buttons.editDisabled = 'Нет геометрии для редактирования';
        this.$L.drawLocal.edit.toolbar.buttons.remove = 'Удалить геометрию';
        this.$L.drawLocal.edit.toolbar.buttons.removeDisabled = 'Нет геометрии для удаления';
      }
      //
      // this.drawControl.setDrawingOptions({
      //   polyline: {
      //     shapeOptions: {
      //       color: '#ff0a1e',
      //     },
      //   },
      // });
      // this.drawControl.setDrawingOptions({
      //   polygon: {
      //     shapeOptions: {
      //       color: '#fff',
      //     },
      //   },
      // });#356AA0

      map.on(this.$L.Draw.Event.EDITMOVE, (e) => {
        // console.debug('EDITMOVE');
        if (this.circle) {
          this.setCursorPosition(JSON.parse(JSON.stringify(this.editShape._marker.getLatLng())));
        }
      });
      map.on(this.$L.Draw.Event.DRAWSTART, (e) => {
        // console.debug('DRAWSTART');
        this.map.fire(this.$L.Draw.Event.EDITSTART);
        this.cancelDeleting();
      });
      map.on(this.$L.Draw.Event.CREATED, (e) => {
        // console.debug('CREATED');
        const { layer } = e;
        let coords;

        if (!this.area && !this.ruler) {
          switch (e.layerType) {
            case 'polygon':
              coords = layer._latlngs.map(objCoordinates => objCoordinates.map(LatLng => [LatLng.lng, LatLng.lat]));
              this.geofence.geometry.type = 'Polygon';
              break;
            case 'marker':
              coords = [layer._latlng.lng, layer._latlng.lat];
              this.setPointByCoordinates(coords);
              this.geofence.geometry.type = 'Point';
              break;
            default:
              coords = layer._latlngs.map(objCoordinates => [objCoordinates.lng, objCoordinates.lat]);
              this.geofence.geometry.type = 'LineString';
              break;
          }
          this.geofence.geometry.coordinates = coords;
          this.$store.commit('geofences/setGeofencesJson', this.geofence.geometry);
        } else if ((e.layerType === 'polygon' && this.area) || (e.layerType === 'polyline' && this.ruler)) {
          if (this.layerEditDraw) {
            this.clearAreaLuner();
          }
          this.layerEditDraw = layer;
          if (e.layerType === 'polygon') {
            this.getToolTip(this.layerEditDraw, 'Polygon', true);
            this.layerEditDraw.type = 'Polygon';
            let firstPoint;
            coords = layer._latlngs.map(objCoordinates => objCoordinates.map((LatLng, index) => {
              if (index === 0) {
                firstPoint = [LatLng.lng, LatLng.lat];
              }
              return [LatLng.lng, LatLng.lat];
            })).flat(1);
            coords.push(firstPoint);
          } else {
            this.getToolTip(this.layerEditDraw, 'LineString', true);
            this.layerEditDraw.type = 'LineString';
          }
          this.layerEditDraw.editing.enable();
          this.editableLayerMap.addLayer(this.layerEditDraw);
          layer._path.addEventListener('click', async () => {
            if (this.activeTrack) {
              const activeTrackData = {
                ObjectId: this.activeTrack.objectId,
                Begin: this.activeTrack.from.toISOString(),
                End: this.activeTrack.to.toISOString(),
                GeofencePoints: coords,
              };
              this.setNoActivePreference({ showAreaDraw: false, showRulerDraw: false });
              this.pointsConvexGeofence = await this.getConvexGeofence(activeTrackData);
              if (this.$route.name !== 'geofences') {
                await this.$router.push('/geofences/');
                setTimeout(() => this.setOpenGeofencesSidebar(true), 300);
              } else {
                this.setOpenGeofencesSidebar(true);
              }
            }
          });
        }
        this.previousEditShape = null;
      });
      map.on(this.$L.Draw.Event.EDITVERTEX, (e) => {
        // console.debug('EDITVERTEX');
        if (this.area || this.ruler) {
          this.getToolTip(this.layerEditDraw, this.layerEditDraw.type, true);
        }
        this.layers.forEach((layer) => {
          this.getToolTip(layer, layer.feature.geometry.type);
        });
      });
      map.on(this.$L.Draw.Event.EDITSTOP, (e) => {
        // console.debug('EDITSTOP');
        this.layers.forEach((layer) => {
          this.getToolTip(layer, layer.feature.geometry.type);
          if (layer.feature.geometry.type === 'Point') {
            this.circle.setLatLng(layer.getLatLng());
            this.circle.setRadius(this.geoOptions.radius);
            this.setBreakSetCursorPosition(false);
          } else {
            this.polygon.setLatLngs(layer.getLatLngs());
            this.polygon.setStyle({ weight: this.getPixelForMetres(this.geoOptions.radius * 2) });
          }

          let coords;

          if (!this.area && !this.ruler) {
            switch (layer.feature.geometry.type) {
              case 'Polygon':
                coords = layer._latlngs.map(objCoordinates => objCoordinates.map(LatLng => [LatLng.lng, LatLng.lat]));
                break;
              case 'Point':
                coords = [layer._latlng.lng, layer._latlng.lat];
                break;
              default:
                coords = layer._latlngs.map(objCoordinates => [objCoordinates.lng, objCoordinates.lat]);
                break;
            }
            this.geofence.geometry.type = layer.feature.geometry.type;
            this.geofence.geometry.coordinates = coords;
            this.$store.commit('geofences/setGeofencesJson', this.geofence.geometry);
          }
        });
      });
      map.on(this.$L.Draw.Event.EDITSTART, (e) => {
        // console.debug('EDITSTART');
        this.layers.forEach((layer) => {
          this.getToolTip(layer, layer.feature.geometry.type);
          if (layer.feature.geometry.type === 'Point') {
            this.circle.setRadius(0);
            this.circle.setStyle({ weight: 0 });
          } else {
            this.polygon.setStyle({ weight: 0 });
          }
        });
      });
      map.on(this.$L.Draw.Event.DELETED, (e) => {
        // console.debug('DELETED');
        this.$store.commit('geofences/setGeofencesJson', null);
      });
    });
  },
  methods: {
    ...mapActions('tracks', ['getParking']),
    ...mapActions('geofences', ['getConvexGeofence']),
    ...mapActions('preferences', ['setNoActivePreference']),
    ...mapMutations('geofences', ['setCountLayers', 'setDisableEditLayer', 'setPointByCoordinates', 'setOpenGeofencesSidebar']),
    ...mapMutations('map', ['setCursorPosition', 'setBreakSetCursorPosition', 'setBounds']),
    removeEdgeLayer () {
      this.edgeMarkerLayer?.destroy();
    },
    addEdgeLayer () {
      this.edgeMarkerLayer = this.$L.edgeMarker().addTo(this.map);
    },
    editLayer () {
      // console.debug('editLayer');
      switch (this.geojson.type) {
        case 'Point':
          this.previousEditShape = JSON.parse(JSON.stringify(this.layers[0].getLatLng()));
          this.editShape = new this.$L.Edit.Marker(this.layers[0]);
          this.editShape.enable();
          this.circle.setStyle({ opacity: 0, fillOpacity: 0 });
          this.setBreakSetCursorPosition(true);
          break;
        case 'LineString':
        case 'MultiLineString':
        case 'Polygon':
          this.previousEditShape = JSON.parse(JSON.stringify(this.layers[0].getLatLngs()));
          this.editShape = new this.$L.Edit.Poly(this.layers[0]);
          this.editShape.enable();
          this.polygon.setStyle({ opacity: 0 });
          break;
        default:
      }
      this.map.fire(this.$L.Draw.Event.EDITSTART);
      this.removeTooltipDelete();
      this.layers.forEach((layer) => {
        layer.off('click');
      });
    },
    saveEditing () {
      if (this.editShape) {
        this.editShape.disable();
      }
      if (this.geojson.type === 'Point') {
        this.circle.setLatLng(this.layers[0].getLatLng());
        this.circle.setRadius(this.geoOptions.radius);
        this.circle.setStyle({ opacity: 1, fillOpacity: 0.2 });
      }

      if (this.geojson.type === 'LineString' || this.geojson.type === 'MultiLineString' || this.geojson.type === 'Polygon') {
        this.polygon.setLatLngs(this.layers[0].getLatLngs());
        this.polygon.setStyle({ weight: this.getPixelForMetres(this.geoOptions.radius * 2), opacity: 0.3 });
      }
      this.previousEditShape = null;
      this.map.fire(this.$L.Draw.Event.EDITSTOP);
    },
    cancelEditing () {
      if (this.editShape) {
        this.editShape.disable();
        this.editShape = null;
      }
      this.layers.forEach((layer) => {
        this.editableLayers.removeLayer(layer);
        layer.removeFrom(this.map);
      });
      if (this.previousEditShape) {
        if (this.geojson.type === 'Point') {
          this.layers[0].setLatLng(this.previousEditShape);
        } else {
          this.layers[0].setLatLngs(this.previousEditShape);
        }
        this.previousEditShape = null;
      }
      this.$nextTick(() => {
        this.layers.forEach((_l) => {
          const l = _l;
          if (l.feature.geometry.type === 'Point') {
            this.circle = this.$L.circle(l.getLatLng(), { radius: this.geoOptions.radius }).addTo(this.map);
            this.map.setView(l.getLatLng());
          }
          if (l.feature.geometry.type === 'LineString' || l.feature.geometry.type === 'MultiLineString') {
            this.polygon = this.$L.polyline(l.getLatLngs(), { weight: this.getPixelForMetres(this.geoOptions.radius * 2), opacity: 0.3 }).addTo(this.map);
          }
          if (l.feature.geometry.type === 'Polygon') {
            this.polygon = this.$L.polygon(l.getLatLngs(), { weight: this.getPixelForMetres(this.geoOptions.radius * 2), opacity: 0.3, fill: false, 'fill-rule': 'evenodd' }).addTo(this.map);
          }
          if (l.feature.geometry.type !== 'Point') {
            this.map.fitBounds(this.layers[0].getBounds());
          }
          this.editableLayers.addLayer(l);
        });
        this.map.fire(this.$L.Draw.Event.EDITSTOP);
      });
    },
    deleteLayer () {
      this.map.fire(this.$L.Draw.Event.EDITSTART);
      this.cancelEditing();
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.layers.forEach((layer) => {
            layer.on('click', (e) => {
              if (this.circle) {
                this.circle.removeFrom(this.map);
                this.circle = null;
              }
              if (this.polygon) {
                this.polygon.removeFrom(this.map);
                this.polygon = null;
              }
              const currentLayer = e.layer || e.target || e;
              if (this.geojson.type === 'Point') {
                this.previousEditShape = JSON.parse(JSON.stringify(currentLayer.getLatLng()));
              } else {
                this.previousEditShape = JSON.parse(JSON.stringify(currentLayer.getLatLngs()));
              }
              this.editableLayers.removeLayer(currentLayer);
              layer.removeFrom(this.map);
              this.setDisableEditLayer(true);
            });
          });
        });
      });
      this.tooltipDelete = new this.$L.Draw.Tooltip(this.map);
      this.tooltipDelete.updateContent({ text: this.$L.drawLocal.edit.handlers.remove.tooltip.text });
      this.map.on('mousemove', this.changePositionTooltipDelete);
    },
    changePositionTooltipDelete (e) {
      this.tooltipDelete.updatePosition(e.latlng);
    },
    saveDeleting () {
      // console.debug('saveDeleting');
      this.map.fire(this.$L.Draw.Event.DELETED);
      this.setDisableEditLayer(false);
    },
    cancelDeleting () {
      // console.debug('cancelDeleting');
      this.cancelEditing();
      this.removeTooltipDelete();
      this.setDisableEditLayer(false);
    },
    removeTooltipDelete () {
      this.map.off('mousemove', this.changePositionTooltipDelete);
      if (this.tooltipDelete && this.tooltipDelete._container) {
        this.tooltipDelete._container = null;
      }
      this.tooltipDelete = null;
    },
    deleteLayers () {
      // console.debug('deleteLayers');
      this.map.fire(this.$L.Draw.Event.DELETED);
      this.setDisableEditLayer(false);
    },
    mouseover () {
      this.stopTimerForMouseMoveEnd = false;
    },
    mouseout () {
      this.stopTimerForMouseMoveEnd = true;
    },
    mousemove (ev) {
      // console.debug(ev);
      if (!this.mapMoved) {
        requestAnimationFrame(() => {
          Object.assign(this.cursorPosition, ev.latlng.wrap() || {});
        });
      }
      if (this.visibleTracks) {
        !this.timerForMouseMoveEnd || clearTimeout(this.timerForMouseMoveEnd);
        this.timerForMouseMoveEnd = setTimeout(() => {
          this.setBounds(this.map.getBounds());
          const params = {
            point: [this.cursorPosition.lng, this.cursorPosition.lat],
            bounds: [this.bounds._southWest.lng, this.bounds._southWest.lat, this.bounds._northEast.lng, this.bounds._northEast.lat],
            width: this.size.width,
            height: this.size.height,
            accountingUnitId: this.accountingUnitGeofences,
          };
          this.getParking(params);
        }, 500);
        !this.stopTimerForMouseMoveEnd || clearTimeout(this.timerForMouseMoveEnd);
      } else {
        !this.timerForMouseMoveEnd || clearTimeout(this.timerForMouseMoveEnd);
        if (!this.breakSetCursorPosition) {
          this.timerForMouseMoveEnd = setTimeout(() => {
            this.setCursorPosition(JSON.parse(JSON.stringify(this.cursorPosition)));
          }, 500);
        } else {
          clearTimeout(this.timerForMouseMoveEnd);
        }
      }
    },
    goToTrackRow ({ uid, index }) {
      this.$nuxt.$emit('focusTrackRow', uid, index);
    },
    // Note: использование HotkeyHandler для одной команды - избыточно
    // При добавлении новых обработчиков можно будет перейти
    mapKeyDown ({ originalEvent: e }) {
      if (e.repeat) {
        return;
      }

      const { code } = e;
      const handlerMap = {
        Escape: this.clearDrawn,
      };
      handlerMap[code]?.();

      e.stopPropagation();
    },
    mapMove (status = true) {
      this.setBounds(this.map.getBounds());
      this.mapMoved = status;
    },
    async mapClick (clientTrack = null, clientTrackId = null) {
      if (this.isPageWithTracks && this.currentCursorPosition.lat !== this.cursorPosition.lat && this.currentCursorPosition.lng !== this.cursorPosition.lng) {
        this.currentCursorPosition = {
          lat: this.cursorPosition.lat,
          lng: this.cursorPosition.lng,
        };
        this.dataPoints = {
          Geofences: [],
          Tracks: [],
        };
        let data;
        if (this.settingsAUnit.renderingMode === 0) {
          this.setBounds(this.map.getBounds());
          data = await this.$store.dispatch('tracks/infoPointClick', {
            point: [this.cursorPosition.lng, this.cursorPosition.lat],
            bounds: [this.bounds._southWest.lng, this.bounds._southWest.lat, this.bounds._northEast.lng, this.bounds._northEast.lat],
            width: this.size.width,
            height: this.size.height,
            accountingUnitId: this.accountingUnitGeofences,
          });
        } else {
          const xRatio = (this.bounds._northEast.lat - this.bounds._southWest.lat) / this.size.width;
          const yRatio = (this.bounds._northEast.lng - this.bounds._southWest.lng) / this.size.height;
          const searchRadius = (xRatio + yRatio) / 2 * 4.3; // 4.3px радиус поиска
          data = clientTrack.data?.filter(p => Math.sqrt((this.cursorPosition.lat - p.Latitude) ** 2 + (this.cursorPosition.lng - p.Longitude) ** 2) < searchRadius);
        }
        if (data) {
          const points = this.settingsAUnit.renderingMode === 0 ? data.Tracks : data;
          const tracks = this.$route.name !== 'geofences' ? points.map((point, i) => {
            let track;
            track = this.arrItems.find(x => x.id === (this.settingsAUnit.renderingMode === 0 ? point.Id : clientTrackId));
            if (!track && this.activeTrackReport) {
              track = this.activeTrackReport;
            }
            let value = null;
            if (track) {
              value = {
                id: i,
                uid: this.settingsAUnit.renderingMode === 0 ? point.Id : clientTrackId,
                index: point.Index,
                Timestamp: point.Timestamp,
                color: track.color,
                name: track.name,
                // eslint-disable-next-line no-prototype-builtins
                speed: point.hasOwnProperty('Speed') ? point.Speed : null,
              };
            }
            return value;
          }).filter(x => x !== null) : [];
          this.dataPoints = {
            Geofences: this.settingsAUnit.renderingMode === 0 ? data.Geofences : [],
            Tracks: this.$route.name === 'reporting' && this.selectedPeriods?.length ? tracks.filter((track) => {
              // eslint-disable-next-line no-restricted-syntax
              for (const period of this.selectedPeriods) {
                if (period.begin.getTime() / 1000 <= track.Timestamp && period.end.getTime() / 1000 >= track.Timestamp) {
                  return true;
                }
              }
              return false;
            }) : tracks,
          };
        }
      }
      if (!this.area && !this.ruler) {
        this.$nextTick(() => {
          if (this.isPageWithTracks && (this.dataPoints.Geofences.length > 0 || this.dataPoints.Tracks.length > 0)) {
            this.$refs.features.mapObject.openPopup(this.currentCursorPosition);
          }
        });
      }
    },
    closePopup () {
      if (this.$refs?.features?.mapObject) {
        this.$refs.features.mapObject.closePopup();
      }
    },
    mapRefresh () {
      if (this.map) {
        this.map.invalidateSize({ debounceMoveend: true });
      }
    },
    getDistance (coordsArray, poligon = false) {
      let distance = 0;

      for (let i = 0; i < coordsArray.length - 1; i += 1) {
        const coords = coordsArray[i];
        distance += coords.distanceTo(coordsArray[i + 1]);
      }
      if (poligon) {
        distance += coordsArray[coordsArray.length - 1].distanceTo(coordsArray[0]);
      }
      return distance;
    },
    getToolTip (_layer, type, permanent = false) {
      const layer = _layer;
      let distance = 0;
      let seeArea = 0;
      let strToolTip = '';

      switch (type) {
        case 'Polygon':
          distance = this.formatterDistance(this.getDistance(layer.getLatLngs()[0], true));
          seeArea = this.formatterArea(this.$L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]));
          strToolTip = `<p style="font-weight:bold;">Периметр: ${distance}</p><p style="font-weight:bold;">Площадь: ${seeArea}</p>`;
          break;
        case 'Point':
          break;
        case 'MultiLineString':
          layer.getLatLngs().forEach((line) => {
            distance += this.getDistance(line);
          });
          strToolTip = `<div style="font-weight:bold;">Периметр: ${this.formatterDistance(distance)}</div>`;
          break;
        default:
          distance = this.formatterDistance(this.getDistance(layer.getLatLngs()));
          strToolTip = `<div style="font-weight:bold;">Периметр: ${distance}</div>`;
          break;
      }
      if (strToolTip !== '') {
        if (layer.getTooltip()) {
          layer.setTooltipContent(strToolTip);
          if (permanent) {
            layer.getTooltip().setLatLng(layer.getCenter());
          }
        } else {
          layer.bindTooltip(strToolTip, { permanent, sticky: false });
        }
      }
    },
    clearDrawn () {
      const updates = {
        showAreaDraw: false,
        showRulerDraw: false,
      };
      this.$store.commit('preferences/editPreferences', updates);
    },
    formatterArea (area) {
      let areaStr = 0;
      // if (area >= 1000000) {
      //   areaStr = `${this.$L.GeometryUtil.formattedNumber(area * 0.000001, 2)} км²`;
      // } else if (area >= 10000) {
        areaStr = `${this.$L.GeometryUtil.formattedNumber(area * 0.0001, 2)} га`;
      // } else {
      //   areaStr = `${this.$L.GeometryUtil.formattedNumber(area, 0)} м²`;
      // }
      return areaStr;
    },
    formatterDistance (distance) {
      let distanceStr = 0;
      if (distance > 1000) {
        distanceStr = `${this.$L.GeometryUtil.formattedNumber(distance / 1000, 2)} км`;
      } else {
        distanceStr = `${this.$L.GeometryUtil.formattedNumber(distance, 0)} м`;
      }
      return distanceStr;
    },
    clearAreaLuner () {
      if (this.layerEditDraw.editing) {
        this.layerEditDraw.editing.disable();
      } else {
        this.layerEditDraw.disable();
      }
      this.editableLayerMap.removeLayer(this.layerEditDraw);
      this.layerEditDraw = null;
    },
    getPixelForMetres (radius) {
      const map = this.$refs.rootMap.mapObject;
      const mapHeightInMetres = map.getBounds().getSouthEast().distanceTo(map.getBounds().getNorthEast());
      const mapHeightInPixels = map.getSize().y;
      const pixel = mapHeightInMetres / mapHeightInPixels;

      return radius / pixel;
    },

    load (v) {
      const version = this.geofenceVersion;
      if (version % 2 === 1 && this.visibleGeofenceLayer2) {
        setTimeout(() => {
          this.visibleGeofenceLayer2 = false;
        }, 200);
      }
      if (version % 2 === 0 && this.visibleGeofenceLayer1) {
        setTimeout(() => {
          this.visibleGeofenceLayer1 = false;
        }, 200);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.map-layout {
  --width: 0;
  --height: 0;

  position: absolute;
  pointer-events: auto;
  z-index: 1;
  top: 0;
  right: 0;
  width: calc(1px * var(--width));
  height: calc(1px * var(--height));
}

.leaflet-top.leaflet-right {
  display: flex;
  align-items: flex-start;
}
.leaflet-control-layers-base {
  label {
    margin: 0;
  }
}
.noClick {
  cursor: default;
  pointer-events: none;
}
.leaflet-control-attribution {
  font-family: "Lucida Console", monospace;
}
.leaflet-popup-content{
  #leafroot-id{
    position: relative;

    .popup-img{
      height: 150px;
      width: 300px;
    }

    .popup-text{
      font-size: $fontSize;
      &.length{
        margin-bottom: 20px;
      }
    }

    .notice-btn{
      width: 155px;
    }
  }
}

.leaflet-draw-draw-OK_ICON{
  background-image: url("/static/tunnels/images/ok-btn.png") !important;
  background-size: 25px auto !important;
}

.leaflet-draw-draw-NOK_ICON{
  background-image: url("/static/tunnels/images/nok-btn.png") !important;
  background-size: 25px auto !important;
}

.popover-wrapper {
  .popover-title {
    display: flex;
    justify-content: flex-start;
    background-color: #f2f2f2;
    font-size: 14px;
    width: 100%;
  }
}
.geofences-list--name {
  overflow: hidden;
  text-overflow: ellipsis;
}
.track-list {
  &--name {

  }
  &--badge {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.list {
    flex: 1;
    overflow-y: auto;
    //max-height: 100%;
    .list-group {
      //height: 100%;
    }

    &-objects {
      overflow-y: auto;
      max-height: 100%;
    }
  }
  .list-group-item {
    padding: 4px 7px;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
  }

.geofences-block {
  max-height: 150px;
  overflow-y: auto;
  width: 100%;
}
</style>

<style lang="stylus">
  .mapobj-popup {
    margin-bottom: 0px;
    &--body {
      display: flex;
      height: 100%;
    }
    .leaflet-popup {
      &-tip {
        display: none;
        &-container {
          left: calc(50% - 12px);
          bottom: calc(-.4rem - 1px);
          position:absolute;
          display:block;
          width:1rem;
          height:.5rem;
          margin:0 .3rem
          &:after,
          &:before {
            position:absolute;
            display:block;
            content:"";
            border-color:transparent;
            border-style:solid
          }
          &:before {
            bottom: 0;
            border-width: .5rem .5rem 0;
            border-top-color:
            rgba(0,0,0,.25);
          }
          &:after {
            bottom: 1px;
            border-width: .5rem .5rem 0;
            border-top-color: #fff;
          }
        }
      }
      &-content {
        margin: 0;
        color: #212529;
        max-width: 400px;
        max-height: 400px;
        width: 100%;
        .popover-body {
          padding: 0 0;
        }
        &-wrapper {
          display: flex;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5;
          text-align: left;
          text-decoration: none;
          text-shadow: none;
          text-transform: none;
          letter-spacing: normal;
          word-break: normal;
          word-spacing: normal;
          white-space: normal;
          line-break: auto;
          font-size: .875rem;
          word-wrap: break-word;
          background-clip: padding-box;
          border: 1px solid rgba(0,0,0,.2);
          padding: 0 0;
          position: relative;
          max-width: 400px;
          max-height: 400px;
          min-width: 300px;
          z-index: 2;
          margin-left: 15px;
          border-radius: 3px;
          box-shadow: 0 3px 4px 2px rgba(0, 0, 0, 0.2);
          background-color: #fff;
          overflow: hidden;
        }
      }
    }
  }
</style>

<style src="leaflet-draw/dist/leaflet.draw.css"></style>
