import Vue from 'vue';
import ElementUI from 'element-ui';
import '../assets/styles/lib/element-ui.styl';

export default (context) => {
  const { i18n: contextI18N } = context;
  const {
    // Other
    install,
    i18n,
    locale,
    version,
    Message,
    MessageBox,
    Notification,

    // Components
    DatePicker,
  } = ElementUI;

  const customElementUI = {
    install,
    i18n,
    locale,
    version,
    Message,
    MessageBox,
    Notification,

    DatePicker,
  };

  Vue.use(customElementUI, {
    i18n: (key, value) => contextI18N.t(key, value),
  });
};
