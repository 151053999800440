/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */

const DEFAULT_OPTIONS = {
  periodicity: 1,
};

// action: {
//   doIt - periodic function
//   options - action work options
//     + once - execute the function once
//     + after - run a function after a certain amount of time
// }
class BreakablePeriodic {
  constructor (actions = [], options = {}, breaker) {
    if (!actions.length) {
      throw new Error('Periodic - Invalid action list');
    }

    this.options = { ...DEFAULT_OPTIONS, ...options };
    this.breaker = breaker;
    this._validateOptions(this.options);

    this.actions = actions;
    if (options.timeLimit) {
      this.breakCounter = 0;
    }
  }

  _validateOptions ({ periodicity, timeLimit }) {
    // eslint-disable-next-line eqeqeq
    if (periodicity == 0) {
      throw new Error('Periodic Optimization - Invalid value for periodicity');
    } else if (!periodicity) {
      throw new Error('Periodic - Invalid periodicity value');
    }

    if (this.breaker && timeLimit && !Number.isInteger(timeLimit)) {
      throw new TypeError('Periodic - Invalid timeLimit value');
    }
  }

  start () {
    this.intervalId = setInterval(this._doPeriodicity.bind(this), this.options.periodicity);
  }

  stop () {
    this.intervalId && clearInterval(this.intervalId);
    this.breakCounter = 0;
  }

  destroy () {
    this.stop();

    this.actions = [];
    this.options = {};
  }

  _doPeriodicity () {
    if (this.breakCounter > this.options.timeLimit) {
      this._breakAll();
    }

    const boundBreak = this._breakAll.bind(this);
    this.actions.forEach(({ doIt, options = {} }) => {
      if (options.once && options.hasCalled) {
        return;
      }
      if (options.after > this.breakCounter) {
        return;
      }

      doIt(boundBreak);
      options.hasCalled = true;
    });
    this.breakCounter += this.options.periodicity;
  }

  _breakAll (data) {
    this.breaker(data);
    this.destroy();
  }
}

export default BreakablePeriodic;
