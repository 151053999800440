// import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import ListGroupsGeofences from '~/components/ListGroupsGeofences';
// import wkx from "wkx";
// import buffer from "buffer";

export default {
  components: {
    ListGroupsGeofences,
  },
  data () {
    return {
      checkedGeofences: [],
      checkedGeofencesDel: [],
      outMonitoringViewGeofence: 0,
      activeListGeofencesSubTab: 0,
      // countTabs: 0,
      // selectedGeofences: [],
      geofenceToFind: '',
      geofences: [],
      // geofencesDel: [],
      sharedGroupsGeofences: [],
      privateGroupsGeofences: [],
      variantList: 'outMonitoring',
      rr: true,
      scrollSettings: {
        minScrollbarLength: 60,
      },
    };
  },
  computed: {
    ...mapGetters('listGroupsGeofences', ['viewGeofenceStorage']),
    // ...mapGetters('listGroupsGeofences', ['checkedGeofencesOutMonitoring']),
    ...mapGetters('sharedgeo', { sharedGeofences: 'shared' }),
    ...mapGetters('privategeo', { privateGeofences: 'private' }),
    ...mapGetters('preferences', { visibleItems: 'geofencesSelectedIds' }),
    ...mapGetters('preferences', ['accountingUnitGeofences']),
    ...mapGetters('geofences', {
      items: 'geofences',
      countGeofences: 'count',
      itemsDel: 'geofencesDel',
      countGeofencesDel: 'countDel',
      // privateGeofences: 'private',
    }),
    geofencesSelectedCount () {
      return this.checkedGeofences.length;
    },
    foundGeofences () {
      const vm = this.geofenceToFind;
      return this.geofences.filter(item => item.Name.toLowerCase().includes(vm.toLowerCase()) || item.Id.toString().includes(vm.toLowerCase()));
    },
    isAdmin () {
      return this.$store.state.user.data ? this.$store.state.user.data.isAdmin : false;
    },
  },
  watch: {
    activeListGeofencesSubTab (n, o) {
      // console.debug('activeListObjectsTab');
      // console.debug(n);
      if (n === 0) {
        this.outMonitoringViewGeofence = 0;
      } else if (this.viewGeofenceStorage === 1 && n === 1) {
        this.outMonitoringViewGeofence = 1;
      } else if (this.viewGeofenceStorage === 2 && n === 1) {
        this.outMonitoringViewGeofence = 2;
      }
      // else if (this.viewGeofence === 3 && n === 2) {
      //   this.outMonitoringViewGeofence = 3;
      // }
      // if (this.view === 0 && n === 1) {
      //   this.showListObjects = false;
      // } else if (this.view !== 0 && n === 2) {
      //   this.showListObjects = false;
      // } else {
      //   this.showListObjects = true;
      // }
    },
  },
  // async mounted () {
  //   await this.$store.dispatch('geofences/getGeofences', this.accountingUnitGeofences);
  //   await this.$store.dispatch('geofences/getDeletedGeofences', this.accountingUnitGeofences);
  //   await this.$store.dispatch('sharedgeo/getSharedGeofences', this.accountingUnitGeofences);
  //   await this.$store.dispatch('privategeo/getPrivateGeofences');
  //
  //   this.geofences = this.convertItemsGeofences(this.items);
  //   this.geofencesDel = this.convertItemsGeofences(this.itemsDel);
  //   this.sharedGroupsGeofences = this.getGroupsGeofences(this.geofences, this.sharedGeofences);
  //   this.privateGroupsGeofences = this.getGroupsGeofences(this.geofences, this.privateGeofences, false);
  // },
  methods: {
    ...mapMutations('listGroupsGeofences', ['setCheckedGeofencesOutMonitoring']),
    clearCheckedGeofences (listGeofences) {
      this.checkedGeofences = [];
      this.resolveResetCheckedGeofences(listGeofences);
    },
    resolveResetCheckedGeofences (listGeofences, arr = []) {
      if (this.$refs[listGeofences]) {
        this.$refs[listGeofences].setCheckedGeofences(arr);
      }
    },
    onCheckedGeofence (checked) {
      const select = !checked.selected;
      this.sharedGroupsGeofences.forEach(group => updateSelected(group, select));
      this.privateGroupsGeofences.forEach(group => updateSelected(group, select));

      function updateSelected (_group, next) {
        const obj = _group.Geofences.find(x => x.Id === checked.Id);
        if (!obj) {
          return;
        }
        const selectedObj = _group.Geofences.find(x => x.selected && x.Id !== checked.Id);
        const group = _group;
        group.Selected = selectedObj ? true : next;
      }
    },
    getGroupsGeofences (geofences, groups, shared = true) {
      const groupping = groups.map((x) => {
        const item = x;
        return {
          Id: item.Id,
          Name: item.Name,
          Visible: false,
          Geofences: [],
          Selected: false,
          Open: true,
          Edit: true,
        };
      });
      groupping.push({
        Id: 0,
        Name: 'не сгруппированы',
        Visible: false,
        Geofences: [],
        Selected: false,
        Open: true,
        Edit: false,
      });
      geofences.forEach((item) => {
        let flag = true;
        if (shared) {
          item.SharedGroups.forEach((x) => {
            const g = groupping.find(gr => gr.Name === x.Name);
            if (g) {
              g.Geofences.push(item);
              flag = false;
            }
          });
        } else {
          groups.forEach((g) => {
            const geo = g.Geofences.find(x => x.Id === item.Id);
            if (geo) {
              flag = false;
              const group = groupping.find(gr => gr.Id === g.Id);
              group.Geofences.push(item);
            }
          });
        }
        if (flag) {
          const g = groupping.find(gr => gr.Id === 0);
          g.Geofences.push(item);
        }
      });
      return groupping;
    },
    getTypeIcon (type) {
      let icon;
      switch (type) {
        case 'POINT':
          icon = 'geo-alt';
          break;
        case 'POLYGON':
          icon = 'bounding-box-circles';
          break;
        case 'LINESTRING':
          icon = 'share';
          break;
        case 'MULTILINESTRING':
          icon = 'list';
          break;
        default:
          icon = 'x';
          break;
      }
      return icon;
    },
    // changedTabs (currentTabs, previousTabs) {
    //   // console.debug('changedTabs');
    //   this.countTabs = currentTabs.length;
    // },
    convertItemsGeofences (items) {
      return items.map((_item) => {
        const item = _item;
        return {
          Id: item.Id,
          Name: item.Name,
          Visible: this.visibleItems.includes(item.Id),
          Selected: false,
          SharedGroups: item.SharedGroups ? item.SharedGroups : [],
          PrivateGroups: item.PrivateGroups,
          Description: item.Description,
          type: item.GeometryType,
          Perimeter: item.Perimeter,
          Area: item.Area,
          OriginWKB: item.OriginWKB,
        };
      });
    },
    // setActiveGeofence (item) {
    //   const wkb = this.base64ToHex(item.OriginWKB);
    //   const intAry = [];
    //   wkb.match(/.{2}/g).forEach(i => intAry.push(parseInt(i, 16)));
    //   const geometry = wkx.Geometry.parse(buffer.Buffer.from(intAry));
    //   const layer = new this.$L.GeoJSON(geometry.toGeoJSON());
    //   global.mainMap.fitBounds(layer.getBounds());
    // },
  },
};
