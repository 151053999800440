<template lang="pug">
  .custom-control.custom-checkbox
    input.custom-control-input(type="checkbox", :checked="localChecked === true", :indeterminate.prop="(typeof localChecked) !== 'boolean'" @change="onChange" :id="id")
    label.custom-control-label(:for="id")
      slot
</template>

<script>
// eslint-disable-next-line no-underscore-dangle
let _id = 1;

export default {
  name: 'LightCheckBox',
  props: {
    checked: {
      type: Boolean,
      default: false,
      nullable: true,
    },
  },
  data () {
    return {
      localChecked: this.checked,
      id: null,
    };
  },
  watch: {
    checked (n) {
      if ((typeof n === 'boolean' && n !== this.localChecked) || (typeof n !== 'boolean' && typeof this.localChecked === 'boolean')) {
        this.localChecked = n;
      }
    },
  },
  created () {
    this.id = `_chk${_id}_`;
    _id = (_id % 100000000) + 1; // Сомневаюсь, что в системе будут повторно созданные ID
  },
  methods: {
    onChange () {
      this.localChecked = !this.localChecked;
      this.$emit('change', this.localChecked);
    },
  },
};
</script>

<style></style>
