<template lang="pug">
  .d-flex.mw-100.h-100.mh-100.flex-column.main-container
    .mb-2
      b-input-group
        b-form-input(v-model="searchModal.objectName", type="text", placeholder="Поиск по наименованию объекта", @input="searchObject(true)")
        b-input-group-append
          b-button(size="sm", no-outer-focus :variant="visibleAllSearch ? 'outline-secondary' : 'secondary'" @click="clickSearch" v-b-tooltip.hover title="Расширенный поиск")
            b-icon(icon="filter")
    div(v-if="visibleAllSearch")
      b-form-input.mb-2(v-model="searchModal.objectId", type="text", placeholder="Поиск по идентификационному номеру объекта", @input="searchObject")
      b-form-input.mb-2(v-model="searchModal.objectUin", type="text", placeholder="Поиск по уникальному идентификационному номеру объекта", @input="searchObject")
    b-form-group(v-if="isAdmin")
      b-form-tags(v-model='selectedAU' no-outer-focus).p-0.border-0
        template(v-slot='{ tags, disabled, addTag, removeTag }')
          b-row(no-gutters)
            b-col(md="auto")
              b-dropdown.mr-2(size='sm' variant='outline-secondary' left menu-class="dropdown-tag--container" boundary="window")
                template(#button-content)
                  | Учетные единицы
                b-dropdown-group
                  b-dropdown-form(@submit.stop.prevent='() => {}')
                    b-form-group.mb-0(label-for='tag-search-input' label='Поиск' label-cols-md='auto' label-size='sm' :disabled='disabled')
                      b-form-input#tag-search-input(v-model='searchAU' type='search' size='sm' autocomplete='off')
                b-dropdown-divider
                b-dropdown-group.au-select.ddOl
                  b-dropdown-item-button(v-for='option in availableOptions' :key='option' @click='onOptionClick({ option, addTag })')
                    | {{ option }}
                  b-dropdown-text(v-if='availableOptions.length === 0')
                    | По заданному критерию ничего нет
            b-col
              ul.list-inline.d-inline-block.mb-2(v-if='tags.length > 0')
                li.list-inline-item(v-for='tag in tags' :key='tag')
                  b-form-tag(@remove='removeTag(tag)' :title='tag' :disabled='disabled' variant='info') {{ tag }}

    b-overlay(:show="show || showSelected" rounded="sm").areaObjects
      .row.no-gutters.max-h
        .col.d-flex.flex-column.h-100
          recycle-scroller.areaObjects.list-group.list-group-flush(v-if="computedUnselected.length > 0", :items="computedUnselected", key-field="Id", v-slot="{ item }"
            :buffer="200", :min-item-size="itemSizeObjects")
            b-list-group-item.btn-sm.p-1(button, @click="oneClick(select, item)")
              .d-flex.h-100
                b-avatar(:src="item.image", size="sm", variant="light", :icon="item.image ? null : 'cursor-fill'")
                .objects-list--name.w-100.d-flex.px-2.align-items-center
                  span.objects-list--name(:title="item.Name") {{ item.Name }}
                  b-badge.ml-1(v-if="isAdmin && showAccountingUnits") {{item.AccountingUnit.Name}}
          .list.d-flex(v-else)
            .h-100
          .d-flex.pt-2 В списке: {{ computedUnselected.length }}
        .col-md-auto
          .d-flex.flex-column.justify-content-center.h-100.p-3
            b-button-group(vertical)
              b-button.p-1(@click="selectAll", variant="info")
                b-icon(icon="chevron-double-right", font-scale="1")
              b-button.p-1(@click="clearSelected", variant="info")
                b-icon(icon="chevron-double-left", font-scale="1")
        .col.d-flex.flex-column.h-100
          recycle-scroller.areaObjects.list-group.list-group-flush(v-if="objectsSelected.length > 0", :items="objectsSelected", key-field="Id", v-slot="{ item }"
            :buffer="200", :min-item-size="itemSizeObjects")
            b-list-group-item.btn-sm.p-1(button, @click="oneClick(unselect, item)", :variant="selectFiltered.includes(item.Id) ? 'info': ''")
              .d-flex.h-100
                b-avatar(:src="item.image", size="sm", variant="light", :icon="item.image ? null : 'cursor-fill'")
                .objects-list--name.w-100.d-flex.px-2.align-items-center
                  span.objects-list--name(:title="item.Name") {{ item.Name }}
                  b-badge.ml-1(v-if="isAdmin && showAccountingUnits") {{item.AccountingUnit.Name}}
          .list.d-flex(v-else)
            .h-100
          .d-flex.pt-2.justify-content-end Выбрано: {{ objectsSelected.length }}
</template>
<script>
import { mapGetters } from 'vuex';
import imageGenerator from '~/plugins/handlers/imageGenerator';

export default {
  name: 'ObjectsList',
  props: {
    selected: {
      type: Array,
      default () {
        return [];
      },
    },
    sensors: {
      type: Array,
      default () {
        return null;
      },
    },
    accountingUnitsSelected: {
      type: Array,
      default () {
        return null;
      },
    },
    searchSelected: {
      type: String,
      default () {
        return null;
      },
    },
  },
  data () {
    return {
      clicks: 0,
      timerDblclick: null,
      objects: [],
      objectsSelected: [],
      searchModalObject: '',
      searchModal: {
        objectId: '',
        objectName: '',
        objectUin: '',
      },
      transactionId: 0,
      show: true,
      showSelected: true,
      selectedAU: [],
      searchAU: '',
      throttleSearch: null,
      visibleAllSearch: false,
      itemSizeObjects: 32.9,
    };
  },
  computed: {
    ...mapGetters('aUnits', { accountingUnits: 'accountingUnits' }),
    ...mapGetters('preferences', ['showAccountingUnits']),
    computedSelected () {
      return this.objectsSelected.map(item => item.Id);
    },
    objectId () {
      return this.objects.map(item => item.Id);
    },
    selectFiltered () {
      return this.computedSelected.filter(item => this.objectId.includes(item));
    },
    computedUnselected () {
      return this.objects.filter(el => !this.computedSelected.includes(el.Id));
    },
    criteria () {
      return this.searchAU.trim().toLowerCase();
    },
    availableOptions () {
      const { criteria } = this;
      const options = this.accountingUnits.map(el => el.Name).filter(opt => !this.selectedAU.includes(opt));
      if (criteria) {
        return options.filter(opt => opt.toLowerCase().includes(criteria));
      }
      return options;
    },
    isAdmin () {
      return this.$store.state.user.data ? this.$store.state.user.data.isAdmin : false;
    },
  },
  watch: {
    objectsSelected (n) {
      if (this.objects.length > 0) {
        this.$emit('change', this.objectsSelected);
      }
      // this.searchObject();
    },
    selectedAU () {
      this.searchObject();
      this.$emit('selected', this.selectedAU);
    },
    selected (n, o) {
      if (o.length !== 0 && n.length === 0) {
        this.objectsSelected = [];
      }
    },
  },
  mounted () {
    const userAU = this.$store.state.user.data.AccountingUnitId;
    if (this.searchSelected !== null) {
      this.searchModal.objectName = this.searchSelected;
    }
    if (this.accountingUnitsSelected !== null) {
      this.selectedAU = this.accountingUnitsSelected;
    } else {
      const AU = this.accountingUnits.find(el => el.Id === +userAU);
      if (AU) {
        this.selectedAU.push(AU.Name);
      } else {
        this.getObjects({ AccountingUnitsId: [], sensors: this.sensors }).then((objects) => {
          if (objects) {
            this.objects = objects;
            this.show = false;
          }
        });
      }
    }
    if (this.selected.length > 0) {
      this.getObjects({ selected: this.selected, sensors: this.sensors }, true).then((objects) => {
        if (objects) {
          this.objectsSelected = objects;
        }
        this.showSelected = false;
      });
    } else {
      this.showSelected = false;
    }
  },
  beforeDestroy () {
    this.clearAllObjects();
  },
  methods: {
    oneClick (func, item) {
      this.clicks += 1;
      if (this.clicks === 1) {
        this.timerDblclick = setTimeout(() => {
          this.clicks = 0;
        }, 200);
      } else {
        clearTimeout(this.timerDblclick);
        func(item);
        this.clicks = 0;
      }
    },
    async imGen (_icon) {
      const file = `${process.env.API_ADDRESS}/v1/GetIconImage(Id='${_icon.IconFile}')`;
      const image = await imageGenerator({
        file,
        size: _icon.Size,
        offset: [_icon.X, _icon.Y],
      });
      return image;
    },
    onOptionClick ({ option, addTag }) {
      addTag(option);
      this.search = '';
    },
    searchObject (name = false) {
      this.show = true;
      clearTimeout(this.throttleSearch);
      this.throttleSearch = setTimeout(this.getObjectsPre, 1000);
      if (name) {
        this.$emit('filter', this.searchModal.objectName);
      }
    },
    select (item) {
      this.objectsSelected.unshift(item);
    },
    unselect (item) {
      this.objectsSelected = this.objectsSelected.filter(el => el !== item);
    },
    selectAll () {
      this.objectsSelected.push(...this.computedUnselected);
    },
    clearSelected () {
      this.objectsSelected = [];
    },
    getObjectsPre () {
      const AccountingUnitsId = this.accountingUnits.filter(el => this.selectedAU.includes(el.Name)).map(el => el.Id);
      this.getObjects({
        AccountingUnitsId,
        UniqueId: this.searchModal.objectUin,
        Id: this.searchModal.objectId,
        Name: this.searchModal.objectName,
        sensors: this.sensors,
      }).then((objects) => {
        if (objects) {
          this.objects = objects;
          this.show = false;
        }
      });
    },
    getObjects (params = {}, force = false) {
      if (!force) {
        this.transactionId += 1;
      }
      const currenttransaction = this.transactionId;

      return this.$store.dispatch('objects/fetchPagedItems', params).then(async (objects) => {
        if (!force && currenttransaction !== this.transactionId) {
          return null;
        }
        const newInfoObjects = [];
        const imagePromises = [];
        // eslint-disable-next-line no-underscore-dangle,no-restricted-syntax
        for (const _item of objects) {
          const item = _item;
          // item.image = null;
          item.dut = this.sensors && this.sensors[0] === 'дут';
          item.material = this.sensors && this.sensors[0] === 'материал';
          item.spread = this.sensors && this.sensors[1] === 'разбрасывание';
          item.volume = this.sensors && this.sensors[2] === 'объём';
          item.engine = this.sensors && this.sensors[0] === 'моточасы';
          item.width = this.sensors && this.sensors[1] === 'плотность';
          item.ibutton = this.sensors && this.sensors[0] === 'IButton';
          if (item.Icon) {
            imagePromises.push(this.imGen(item.Icon).then((result) => { item.image = result; }));
          }
          newInfoObjects.push(item);
        }
        await Promise.all(imagePromises);
        return newInfoObjects;
        // return objects.map((_item) => {
        //   const item = _item;
        //   // item.image = null;
        //   item.dut = this.sensors && this.sensors[0] === 'дут';
        //   item.material = this.sensors && this.sensors[0] === 'материал';
        //   item.spread = this.sensors && this.sensors[1] === 'разбрасывание';
        //   item.volume = this.sensors && this.sensors[2] === 'объём';
        //   item.engine = this.sensors && this.sensors[0] === 'моточасы';
        //   item.width = this.sensors && this.sensors[1] === 'плотность';
        //   item.ibutton = this.sensors && this.sensors[0] === 'IButton';
        //   if (item.Icon) {
        //     setImmediate(async () => {
        //       const img = await this.imGen(item.Icon);
        //       item.image = img;
        //     });
        //   }
        //   return item;
        // });
      });
    },
    clearAllObjects () {
      this.objects = [];
      this.searchModal.objectName = '';
      this.searchModal.objectId = '';
      this.searchModal.objectUin = '';
      this.visibleAllSearch = false;
    },
    clickSearch () {
      this.visibleAllSearch = !this.visibleAllSearch;
      this.searchModal.objectId = '';
      this.searchModal.objectUin = '';
    },
  },
};
</script>
<style lang="stylus">
.dropdown-tag--container {
  min-width: 320px;
}
</style>
<style lang="stylus" scoped>
.main-container {
  min-height: 350px;
}
.max-h {
  height: 100%;
}
.search input {
  width: 100%;
  border: 1px solid #cecece;
  margin: 4px 0;
  border-radius: 3px;
  padding: 6px 15px;
  color: #777;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  background-color: #fff;
}
.areaObjects {
  flex: 1;
  overflow: auto;
}
.au-select {
  max-height: 200px;
  max-width: 350px;
  overflow-y: auto;
}

.objects-list--name {
  overflow: hidden;
  text-overflow: ellipsis;
}
.objects {
  &-name {
    flex: 1;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}
.list {
  flex: 1;
  overflow-y: auto;
  //max-height: 100%;
  .list-group {
    //height: 100%;
  }

  &-objects {
    overflow-y: auto;
    max-height: 100%;
  }
}
.list-group-item {
  padding: 4px 7px;
  white-space: nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
}
</style>
