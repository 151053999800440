/* eslint-disable no-underscore-dangle */
import { getObjectPropertyByPath } from '~/helpers';

export const SEARCH_COMMAND_LABELS = {
  '#': 'reactive.color',
  'p:': 'data.CurrentProtocol',
};
class TrackedObject {
  static takeCommand (source = '', rawCommands = SEARCH_COMMAND_LABELS) {
    const commands = Object.keys(rawCommands);

    for (let i = commands.length - 1; i >= 0; i -= 1) {
      const cmdKey = commands[i];
      const regexp = new RegExp(`^${cmdKey}`, 'i');
      const match = source.match(regexp) || [];
      if (match.length) {
        return match[0];
      }
    }
    return null;
  }

  static takeProperty (source = '', commandKey) {
    if (!commandKey) {
      return { isValid: false };
    }

    const regexp = new RegExp(`^(${commandKey})(.+)`, 'i');
    const match = source.match(regexp) || [];
    // console.debug('TrackedObject.takeProperty', { commandKey, regexp, match });
    if (!match.length) {
      return { isValid: false };
    }

    return {
      isValid: true,
      path: SEARCH_COMMAND_LABELS[commandKey],
      value: match[2],
    };
  }

  static filterObjectsByName (expandSearchObjects, searchQuery, objects) {
    return objects.filter((obj) => {
      const { Name, Id, UniqueId } = obj.data;
      if (expandSearchObjects) {
        return Name.toLowerCase().includes(searchQuery) || Id.toString().includes(searchQuery) || UniqueId.toLowerCase().includes(searchQuery);
      }
      return Name.toLowerCase().includes(searchQuery);
    });
  }

  static filterObjectsByProperty (property, objects = []) {
    // console.debug('filterObjectsByProperty - args', { property, objects });

    if (!property.isValid) {
      console.warn('before error', { property });
      throw new Error('TrackedObject Error: invalid property');
    }

    return objects.filter((obj) => {
      const objValue = getObjectPropertyByPath(obj, property.path);
      if (!objValue) {
        return false;
      }
      return objValue.toString().toLowerCase().includes(property.value);
    });
  }
}

export default TrackedObject;
