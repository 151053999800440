import Vue from 'vue';
import Quasar from 'quasar/src/vue-plugin';
import {
  QBtn,
  QCard,
  QCardActions,
  QCardSection,
  QPopupProxy,
  QVirtualScroll,
  QDialog,
} from 'quasar/src/components';
import { ClosePopup } from 'quasar/src/directives';

import langRu from 'quasar/lang/ru';
import iconSet from 'quasar/icon-set/svg-fontawesome-v5';
import 'quasar/dist/quasar.min.css';

Vue.use(Quasar, {
  components: {
    QVirtualScroll,
    QPopupProxy,
    QDialog,
    QCard,
    QCardActions,
    QCardSection,
    QBtn,
  },
  directives: {
    ClosePopup,
  },
  lang: langRu,
  iconSet,
});
