import L from 'leaflet';

// eslint-disable-next-line
(function (L) {
    let classToExtend = 'Class';
    if (L.version.charAt(0) !== '0') {
        classToExtend = 'Layer';
    }

    // eslint-disable-next-line no-param-reassign
    L.EdgeMarker = L[classToExtend].extend({
        options: {
            distanceOpacity: false,
            distanceOpacityFactor: 4,
            layerGroup: null,
            rotateIcons: false,
            findEdge (map) {
                return L.bounds([0, 0], map.getSize());
            },
            icon: L.icon({
                iconUrl: `${L.Icon.Default.prototype.options.iconUrl}`,
                clickable: true,
                iconSize: L.Icon.Default.prototype.options.iconSize,
                iconAnchor: L.Icon.Default.prototype.options.iconAnchor,
            }),
        },

        initialize (options) {
            L.setOptions(this, options);
        },

        addTo (map) {
            // eslint-disable-next-line no-underscore-dangle
            this._map = map;

            // add a method to get applicable features
            // eslint-disable-next-line no-underscore-dangle
            if (typeof map._getFeatures !== 'function') {
                L.extend(map, {
                    // eslint-disable-next-line no-underscore-dangle
                    _getFeatures () {
                        const out = [];
                        // eslint-disable-next-line no-underscore-dangle,no-restricted-syntax
                        for (const l in this._layers) {
                            // eslint-disable-next-line no-underscore-dangle
                            if (typeof this._layers[l].getLatLng !== 'undefined' && ((this._layers[l]._iconObj?.options.iconId === 'icon-object' && this._layers[l]._iconObj?.options.iconUrl) || (this._layers[l].options.icon?.options.iconId === 'icon-object' && this._layers[l].options.icon?.options.iconUrl))) {
                                // eslint-disable-next-line no-underscore-dangle
                                out.push(this._layers[l]);
                            }
                        }
                        return out;
                    },
                });
            }

            // eslint-disable-next-line no-underscore-dangle
            map.on('move', this._addEdgeMarkers, this);
            // eslint-disable-next-line no-underscore-dangle
            map.on('viewreset', this._addEdgeMarkers, this);

            // eslint-disable-next-line no-underscore-dangle
            this._addEdgeMarkers();

            map.addLayer(this);

            return this;
        },

        destroy () {
            // eslint-disable-next-line no-underscore-dangle
            if (this._map && this._borderMarkerLayer) {
                // eslint-disable-next-line no-underscore-dangle
                this._map.off('move', this._addEdgeMarkers, this);
                // eslint-disable-next-line no-underscore-dangle
                this._map.off('viewreset', this._addEdgeMarkers, this);

                // eslint-disable-next-line no-underscore-dangle
                this._borderMarkerLayer.clearLayers();
                // eslint-disable-next-line no-underscore-dangle
                this._map.removeLayer(this._borderMarkerLayer);

                // eslint-disable-next-line no-underscore-dangle
                delete this._map._getFeatures;

                // eslint-disable-next-line no-underscore-dangle
                this._borderMarkerLayer = undefined;
            }
        },

        onClick (e) {
            // eslint-disable-next-line no-underscore-dangle
            this._map.setView(e.target.options.latlng, this._map.getZoom());
        },

        onAdd () {},

        _borderMarkerLayer: undefined,

        // eslint-disable-next-line no-underscore-dangle
        _addEdgeMarkers () {
            // eslint-disable-next-line no-underscore-dangle
            if (typeof this._borderMarkerLayer === 'undefined') {
                // eslint-disable-next-line no-underscore-dangle
                this._borderMarkerLayer = new L.LayerGroup();
            }
            // eslint-disable-next-line no-underscore-dangle
            this._borderMarkerLayer.clearLayers();

            let features = [];
            if (this.options.layerGroup != null) {
                features = this.options.layerGroup.getLayers();
            } else {
                // eslint-disable-next-line no-underscore-dangle
                features = this._map._getFeatures();
            }

            // eslint-disable-next-line no-underscore-dangle
            const mapPixelBounds = this.options.findEdge(this._map);
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < features.length; i++) {
                let newOptions = this.options;
                if (features[i].options.item) {
                    newOptions.icon = L.icon({
                        iconUrl: features[i].options.icon.options.iconUrl,
                        iconSize: features[i].options.icon.options.iconSize,
                        iconAnchor: features[i].options.icon.options.iconAnchor,
                    });
                } else if (!features[i].options.item) {
                    // eslint-disable-next-line no-underscore-dangle
                    if (features[i]._iconObj) {
                        // eslint-disable-next-line no-underscore-dangle
                        newOptions.icon = L.divIcon(features[i]._iconObj.options);
                    } else {
                        newOptions.icon = L.divIcon(features[i].options.icon.options);
                    }
                }
                const markerWidth = this.options.icon.options.iconSize[0];
                const markerHeight = this.options.icon.options.iconSize[1];
                // eslint-disable-next-line no-underscore-dangle
                const currentMarkerPosition = this._map.latLngToContainerPoint(features[i]._latlng);

                if (currentMarkerPosition.y < mapPixelBounds.min.y ||
                    currentMarkerPosition.y > mapPixelBounds.max.y ||
                    currentMarkerPosition.x > mapPixelBounds.max.x ||
                    currentMarkerPosition.x < mapPixelBounds.min.x
                ) {
                    // get pos of marker
                    let { x } = currentMarkerPosition;
                    let { y } = currentMarkerPosition;
                    let markerDistance;

                    // we want to place EdgeMarker on the line from center screen to target,
                    // and against the border of the screen
                    // we know angel and its x or y cordiante
                    // (depending if we want to place it against top/bottom edge or left right edge)
                    // fromthat we can calculate the other cordinate
                    const center = mapPixelBounds.getCenter();

                    const rad = Math.atan2(center.y - y, center.x - x);
                    const rad2TopLeftcorner = Math.atan2(center.y - mapPixelBounds.min.y, center.x - mapPixelBounds.min.x);

                    // target is in between diagonals window/ hourglass
                    // more out in y then in x
                    if (Math.abs(rad) > rad2TopLeftcorner && Math.abs(rad) < Math.PI - rad2TopLeftcorner) {
                        // bottom out
                        if (y < center.y) {
                            y = mapPixelBounds.min.y + markerHeight / 2;
                            x = center.x - (center.y - y) / Math.tan(Math.abs(rad));
                            markerDistance = currentMarkerPosition.y - mapPixelBounds.y;
                            // top out
                        } else {
                            y = mapPixelBounds.max.y - markerHeight / 2;
                            x = center.x - (y - center.y) / Math.tan(Math.abs(rad));
                            markerDistance = -currentMarkerPosition.y;
                        }
                    } else {
                        // left out
                        // eslint-disable-next-line no-lonely-if
                        if (x < center.x) {
                            x = mapPixelBounds.min.x + markerWidth / 2;
                            y = center.y - (center.x - x) * Math.tan(rad);
                            markerDistance = -currentMarkerPosition.x;
                            // right out
                        } else {
                            x = mapPixelBounds.max.x - markerWidth / 2;
                            y = center.y + (x - center.x) * Math.tan(rad);
                            markerDistance = currentMarkerPosition.x - mapPixelBounds.x;
                        }
                    }
                    // correction so that is always has same distance to edge

                    // top out (top has y=0)
                    if (y < mapPixelBounds.min.y + markerHeight / 2) {
                        y = mapPixelBounds.min.y + markerHeight / 2;
                        // bottom out
                    } else if (y > mapPixelBounds.max.y - markerHeight / 2) {
                        y = mapPixelBounds.max.y - markerHeight / 2;
                    }
                    // right out
                    if (x > mapPixelBounds.max.x - markerWidth / 2) {
                        x = mapPixelBounds.max.x - markerWidth / 2;
                        // left out
                    } else if (x < markerWidth / 2) {
                        x = mapPixelBounds.min.x + markerWidth / 2;
                    }

                    // change opacity on distance
                    if (this.options.distanceOpacity) {
                        newOptions.fillOpacity =
                            (100 - markerDistance / this.options.distanceOpacityFactor) / 100;
                    }

                    // rotate markers
                    if (this.options.rotateIcons) {
                        const angle = rad / Math.PI * 180;
                        newOptions.angle = angle;
                    }
                    // eslint-disable-next-line no-underscore-dangle
                    const ref = { latlng: features[i]._latlng };
                    newOptions = L.extend({}, newOptions, ref);
                    const marker = L.rotatedMarker(
                        // eslint-disable-next-line no-underscore-dangle
                        this._map.containerPointToLatLng([x, y]),
                        newOptions,
                        // eslint-disable-next-line no-underscore-dangle
                    ).addTo(this._borderMarkerLayer);

                    marker.on('click', this.onClick, marker);
                }
            }
            // eslint-disable-next-line no-underscore-dangle
            if (!this._map.hasLayer(this._borderMarkerLayer)) {
                // eslint-disable-next-line no-underscore-dangle
                this._borderMarkerLayer.addTo(this._map);
            }
        },
    });

    /*
     * L.rotatedMarker class is taken from https://github.com/bbecquet/Leaflet.PolylineDecorator.
     */
    // eslint-disable-next-line no-param-reassign
    L.RotatedMarker = L.Marker.extend({
        options: {
            angle: 0,
        },

        statics: {
            TRANSFORM_ORIGIN: L.DomUtil.testProp([
                'transformOrigin',
                'WebkitTransformOrigin',
                'OTransformOrigin',
                'MozTransformOrigin',
                'msTransformOrigin',
            ]),
        },
        // eslint-disable-next-line
        _initIcon () {
            // eslint-disable-next-line no-underscore-dangle
            L.Marker.prototype._initIcon.call(this);

            // eslint-disable-next-line no-underscore-dangle
            this._icon.style[L.RotatedMarker.TRANSFORM_ORIGIN] = '50% 50%';
        },
        // eslint-disable-next-line
        _setPos (pos) {
            // eslint-disable-next-line no-underscore-dangle
            L.Marker.prototype._setPos.call(this, pos);

            if (L.DomUtil.TRANSFORM) {
                // use the CSS transform rule if available
                // eslint-disable-next-line no-underscore-dangle
                this._icon.style[L.DomUtil.TRANSFORM] +=
                    ` rotate(${this.options.angle}deg)`;
            } else if (L.Browser.ie) {
                // fallback for IE6, IE7, IE8
                const rad = this.options.angle * (Math.PI / 180);
                const costheta = Math.cos(rad);
                const sintheta = Math.sin(rad);
                // eslint-disable-next-line no-underscore-dangle
                this._icon.style.filter +=
                    ` progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=${
                    costheta
                    }, M12=${
                    -sintheta
                    }, M21=${
                    sintheta
                    }, M22=${
                    costheta
                    })`;
            }
        },

        setAngle (ang) {
            this.options.angle = ang;
        },
    });

    // eslint-disable-next-line
    L.rotatedMarker = function (pos, options) {
        return new L.RotatedMarker(pos, options);
    };

    // eslint-disable-next-line
    L.edgeMarker = function (options) {
        return new L.EdgeMarker(options);
    };
})(L);
